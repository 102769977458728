<template>
  <div id="activeTrend" style="width: 100%; height: 100%">
    <div class="top" style="width: 100%; height: 10%">
      <p>
        <span style="color: #acaebf; margin-left: 15px">{{ this.tableTitle }}</span>
        <svg class="icon" aria-hidden="true" @click.stop="setUp" style="cursor: pointer; z-index: 2">
          <use xlink:href="#icon-shezhi-"></use>
        </svg>
      </p>
    </div>
    <div class="bottom" style="width: 100%; height: 80%; margin-left: 10px">
      <!-- 告警信息表格 -->
      <el-table v-if="oneTable" size="small" class="tableClass5 sort_table" :data="tableData"
        style="width: 98%; font-size: 12px">
        <el-table-column min-width="45%" prop="devicename" :label="$t('public.DeviceName')" class="a"
          :show-overflow-tooltip="true" style="margin-left: 10px">
          <template v-slot="{ row }">
            <template v-if="row.devicename">{{ row.devicename }} </template>
            <template v-if-else="row.deviceName">{{ row.deviceName }}
            </template>
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="actiontype" :label="$t('public.BehaviorType')"
          :show-overflow-tooltip="true">
          <template v-slot="{ row }">
            <template
              v-if="row.actiontype == 'S_CHECK_LOCATION_FRAUD'"><span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.LocationFraud') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_DOMAIN'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.DomainNameFraud') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_INJECT_STATUS'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.InjectionAttack') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_DEBUG_STATUS'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.DebuggingBehavior') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_MEMORY_CHANGE'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.MemoryTampering') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_PLUGIN'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.ProgramPlugIn') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_SIGNATURE'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.ApplicationCracking') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_ABNORMAL_CA'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.HttpsHijacking') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_WIFI_PROXY'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.HttpProxy') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_MULTI_APK'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.ApplicationMultiplex') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_FRAME_ATTACK'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.FrameAttack') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_FREQUENCY_ACCOUNT'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.HighFrequencyChangeAccount') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_FREQUENCY_IP'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.HighFrequencyChangeIP') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_FREQUENCY_LOCATION'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.HighFrequencyChangePosition') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_FREQUENCY_RESTART'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.HighFrequencyStartingApplication') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_DANGER_APPS'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.RiskApplication') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_ROOT_STATUS'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.ROOTJailbreak') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_FRAME_ATTACH'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.FrameSoftware') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_ADB_ENABLED'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.USBDebugging') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_VM_STATUS'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.simulator') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_ALLOW_MOCK_LOCATION'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.SimulatedPositionAllowed') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_SYS_USER_CA'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.RootCertificateException') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_CUSTOM_ROM'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.CustomROM') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_SYSTEM_TIME'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.SystemTimeException') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_SCREEN_PASSWORD'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.TheScreenLockPasswordPsNotEnabled') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_MACOS_STATUS'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.macOS') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_WINDOWS_STATUS'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.Windows') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_VPN_STATUS'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.VPN') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CLOUD_PHONE_STATUS'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.CloudPhone') }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_DANGER_IP'">
              <span>{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.RiskIP') }}</span>
            </template>
            <!-- <template v-else-if="row.actiontype == 'S_CHECK_SCREENSHOT_STATUS'">
              <span>截屏阻断解除</span>
            </template> -->
            <template v-else-if="row.actiontype">
              <span>{{ row.actiontype }}</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="level" :show-overflow-tooltip="true" :label="$t('public.Behaviorlevel')">
          <template slot-scope="scope">
            <el-button type="text" style="color: #d9001b; cursor: auto"
              v-if="scope.row.level == '3'">{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.high') }}
            </el-button>
            <el-button type="text" style="color: #f59a23; cursor: auto"
              v-if="scope.row.level == '2'">{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.medium') }}
            </el-button>
            <el-button type="text" style="color: #00cdcd; cursor: auto"
              v-if="scope.row.level == '1'">{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.low') }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="name" :label="$t('public.ApplicationName')" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column min-width="35%" prop="loginname" :label="$t('public.Username')" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column min-width="35%" prop="groupfullname" :label="$t('public.Section')" :show-overflow-tooltip="true">
          <template v-slot="{ row }">
            <span v-if="row.groupfullname">{{ row.groupfullname.substr(groupFullNameShowByIndex) }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="createtime" :label="$t('public.ReportingTime')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.createtime == ''"></span><span class="detaildesc" v-else>
              {{ scope.row.createtime | dateformat }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 违规行为表格 -->
      <el-table v-if="twoTable" size="small" class="tableClass5 sort_table" :data="tableData"
        style="width: 98%; font-size: 12px">
        <el-table-column min-width="35%" prop="devicename" :label="$t('public.DeviceName')" class="a"
          :show-overflow-tooltip="true" style="margin-left: 10px">
          <template slot-scope="scope">
            {{ scope.row.devicename }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="actiontype" :label="$t('public.BehaviorType')"
          :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.type }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="level" :label="$t('public.Behaviorlevel')">
          <template slot-scope="scope">
            <el-button type="text" style="color: #d9001b; cursor: auto"
              v-if="scope.row.level == '3'">{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.high') }}
            </el-button>
            <el-button type="text" style="color: #f59a23; cursor: auto"
              v-if="scope.row.level == '2'">{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.medium') }}
            </el-button>
            <el-button type="text" style="color: #00cdcd; cursor: auto"
              v-if="scope.row.level == '1'">{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.low') }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="name" :label="$t('public.Position')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.location }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="username" :label="$t('public.InternetMethod')"
          :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.netType }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="groupfullname" :label="$t('public.Username')"
          :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.loginname }}
          </template>
        </el-table-column>

        <el-table-column prop="createtime" :label="$t('public.ReportingTime')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.createtime == ''"></span><span class="detaildesc" v-else>
              {{ scope.row.createtime | dateformat }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 威胁行为表格 -->
      <el-table v-if="threeTable" size="small" class="tableClass5 sort_table" :data="tableData"
        style="width: 98%; font-size: 12px">
        <el-table-column min-width="35%" prop="devicename" :label="$t('public.DeviceName')" class="a"
          :show-overflow-tooltip="true" style="margin-left: 10px">
          <template slot-scope="scope">
            {{ scope.row.devicename }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="type" :label="$t('public.BehaviorType')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.type }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="level" :show-overflow-tooltip="true" :label="$t('public.Behaviorlevel')">
          <template slot-scope="scope">
            <el-button type="text" style="color: #d9001b; cursor: auto"
              v-if="scope.row.level == '3'">{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.high') }}
            </el-button>
            <el-button type="text" style="color: #f59a23; cursor: auto"
              v-if="scope.row.level == '2'">{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.medium') }}
            </el-button>
            <el-button type="text" style="color: #00cdcd; cursor: auto"
              v-if="scope.row.level == '1'">{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.low') }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="name" :label="$t('public.ApplicationName')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="loginname" :label="$t('public.Username')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.loginname }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="groupfullname" :label="$t('public.Section')" :show-overflow-tooltip="true">
          <template v-slot="{ row }">
            <span v-if="row.groupfullname">{{ row.groupfullname.substr(groupFullNameShowByIndex) }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="createtime" :label="$t('public.ReportingTime')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.createtime == ''"></span><span class="detaildesc" v-else>
              {{ scope.row.createtime | dateformat }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 敏感行为表格 -->
      <el-table v-if="fourTable" size="small" class="tableClass5 sort_table" :data="tableData"
        style="width: 98%; font-size: 12px">
        <el-table-column min-width="35%" prop="devicename" :label="$t('public.DeviceName')" class="a"
          :show-overflow-tooltip="true" style="margin-left: 10px">
          <template slot-scope="scope">
            {{ scope.row.devicename }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="infoType" :label="$t('public.BehaviorType')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.infoType }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="level" :label="$t('public.Behaviorlevel')">
          <template slot-scope="scope">
            <el-button type="text" style="color: #d9001b; cursor: auto"
              v-if="scope.row.level == '3'">{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.high') }}
            </el-button>
            <el-button type="text" style="color: #f59a23; cursor: auto"
              v-if="scope.row.level == '2'">{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.medium') }}
            </el-button>
            <el-button type="text" style="color: #00cdcd; cursor: auto"
              v-if="scope.row.level == '1'">{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.low') }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="name" :label="$t('public.ApplicationName')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="loginname" :label="$t('public.Username')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.loginname }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="groupfullname" :label="$t('public.Section')" :show-overflow-tooltip="true">
          <template v-slot="{ row }">
            <span v-if="row.groupfullname">{{ row.groupfullname.substr(groupFullNameShowByIndex) }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="createtime" :label="$t('public.ReportingTime')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.createtime == ''"></span><span class="detaildesc" v-else>
              {{ scope.row.createtime | dateformat }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 安全事件表格 -->
      <el-table v-if="fiveTable" size="small" class="tableClass5 sort_table" :data="tableData"
        style="width: 98%; font-size: 12px">
        <el-table-column min-width="35%" prop="deviceName" :label="$t('public.DeviceName')" class="a"
          :show-overflow-tooltip="true" style="margin-left: 10px">
          <template slot-scope="scope">
            {{ scope.row.deviceName }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="eventName"
          :label="this.$t('SecurityPosture.ConfigTableList.SecurityIncident.EventName')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.eventName }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="level" :show-overflow-tooltip="true" :label="$t('public.Behaviorlevel')">
          <template slot-scope="scope">
            <el-button type="text" style="color: #d9001b; cursor: auto"
              v-if="scope.row.level == '3'">{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.high') }}
            </el-button>
            <el-button type="text" style="color: #f59a23; cursor: auto"
              v-if="scope.row.level == '2'">{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.medium') }}
            </el-button>
            <el-button type="text" style="color: #00cdcd; cursor: auto"
              v-if="scope.row.level == '1'">{{ $t('SecurityPosture.ConfigTableList.AlarmInformationForm.low') }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="name" :label="$t('public.ApplicationName')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="loginname" :label="$t('public.Username')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.loginname }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="time" :label="$t('public.GenerationTime')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.time }}
          </template>
        </el-table-column>

        <el-table-column prop="createtime" :label="$t('public.ReportingTime')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.createtime == ''"></span><span class="detaildesc" v-else>
              {{ scope.row.createtime | dateformat }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- SDK行为表格 -->
      <el-table v-if="sixTable" size="small" class="tableClass5 sort_table " :data="tableData"
        style="width: 98%; font-size: 12px">
        <el-table-column min-width="35%" prop="devicename" :label="$t('public.DeviceName')" class="a"
          :show-overflow-tooltip="true" style="margin-left: 10px">
          <template slot-scope="scope">
            <!-- {{ scope.row.devicename }} -->
            {{ scope.row.devicename }}

          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="sdkname"
          :label="this.$t('SecurityPosture.ConfigTableList.SDKList.sdkName')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-button type="text" style="cursor:text">{{ scope.row.sdkname }}</el-button>
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="level" :show-overflow-tooltip="true" :label="$t('public.BehaviorType')">
          <template slot-scope="scope">
            {{ scope.row.type }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="name" :label="$t('public.ApplicationName')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="username" :label="$t('public.SystemVersion')"
          :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.osVersion }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="groupfullname" :label="$t('public.Username')"
          :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.loginname }}
          </template>
        </el-table-column>

        <el-table-column prop="createtime" :label="$t('public.ReportingTime')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.createtime == ''"></span><span class="detaildesc" v-else>
              {{ scope.row.createtime | dateformat }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 运行信息表格 -->
      <el-table v-if="sevenTable" size="small" class="tableClass5 sort_table" :data="tableData"
        style="width: 98%; font-size: 12px">
        <el-table-column min-width="35%" prop="devicename" :label="$t('public.ApplicationName')" class="a"
          :show-overflow-tooltip="true" style="margin-left: 10px">
          <template slot-scope="scope">
            {{ scope.row.appname }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="sdkname" :label="$t('public.ApplicationVersion')"
          :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <!-- {{ scope.row.versionname }} -->
            <el-button type="text" style="cursor:text"> {{ scope.row.versionname }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="name" :label="$t('public.DeviceName')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.devicename }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="username"
          :label="$t('SecurityPosture.ConfigTableList.RunningInformation.NumberOfStarts')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.runcount }}{{ $t('SecurityPosture.ConfigTableList.RunningInformation.time') }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="groupfullname"
          :label="$t('SecurityPosture.ConfigTableList.RunningInformation.DurationOfUse')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ $common.formatDuring(scope.row.runtime) }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="level" :show-overflow-tooltip="true"
          :label="$t('SecurityPosture.ConfigTableList.RunningInformation.trafficOfUse')">
          <template slot-scope="scope">
            {{ $common.formatFlow(scope.row.useflow) }}
          </template>
        </el-table-column>
        <el-table-column prop="createtime" :label="$t('public.ReportingTime')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.createtime == ''"></span><span class="detaildesc" v-else>
              {{ scope.row.createtime | dateformat }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--卡片配置弹框 -->
    <Eldialog :title="$t('SecurityPosture.bounced.title')" :width="'460px'"
      :btnTitle="$t('SecurityPosture.bounced.btnTitle')" :flagbtn="true" :cancelBtn="true" :dialogVisible="statusVisible"
      @handleClose="handleClose" :determineBtn="true" :num="'170px'" @determine="determine">
      <el-form ref="form" :model="form" label-width="120px" style="margin-top: 30px">
        <!-- 统计内容 -->
        <el-form-item :label="$t('SecurityPosture.bounced.StatisticalContent')">
          <el-select v-model="optionValue" @change="content($event)"
            :placeholder="$t('SecurityPosture.bounced.placeholder')">
            <el-option v-for="item in optionData" :key="item.value"
              :label="$t(`SecurityPosture.ConfigTableList.TableDropDownBox.${item.label}`)"
              :value="{ value: item.value, label: item.label }">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </Eldialog>
  </div>
</template>

<script>
import Eldialog from '@/components/elDialog' //详情弹框

import '../../../assets/iconfont/iconfont.js' //小齿轮设置的icon
export default {
  // 组件
  components: {
    Eldialog
  },
  props: {
    pageContent: {
      type: Object,
      default: () => { }
    },
    newTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      groupFullNameShowByIndex: '', //用户部门截取的下标
      oneTable: false,
      twoTable: false,
      threeTable: false,
      fourTable: false,
      fiveTable: false,
      sixTable: false,
      sevenTable: false,
      name: '我是子组件里面的数据', //我是子组件里面的数据
      tableTitle: '', //改变后title
      metaTableTitle: '', //下拉选选中的值描述
      metaDataValue: '', //下拉选选中的值
      dataValue: '', // 改变后的值
      statusVisible: false, //弹框默认关闭
      tableData: [], //表格接收数据
      // 违规行为 行为类型  关系映射
      typeOptions: [
        { type: '', label: this.$t('SecurityPosture.ConfigTableList.violationType.TypeOfBehavior') },
        { type: '0', label: this.$t('SecurityPosture.ConfigTableList.violationType.DeviceJailbreak') },
        { type: '8', label: this.$t('SecurityPosture.ConfigTableList.violationType.SIMCardChange') },
        { type: '13', label: this.$t('SecurityPosture.ConfigTableList.violationType.SDCardChange') },
        { type: '9', label: this.$t('SecurityPosture.ConfigTableList.violationType.IllegalOutreach') },
        { type: '5', label: this.$t('SecurityPosture.ConfigTableList.violationType.geofencing') },
        { type: '1', label: this.$t('SecurityPosture.ConfigTableList.violationType.SystemVersion') },
        { type: '7', label: this.$t('SecurityPosture.ConfigTableList.violationType.FlowAnomaly') },
        { type: '14', label: this.$t('SecurityPosture.ConfigTableList.violationType.FlowAnomaly') },
        { type: '15', label: this.$t('SecurityPosture.ConfigTableList.violationType.StorageSpace') },
        { type: '12', label: this.$t('SecurityPosture.ConfigTableList.violationType.StorageSpace') },
        { type: '2', label: this.$t('SecurityPosture.ConfigTableList.violationType.ApplicationBlacklistAndWhitelist') },
        { type: '33', label: this.$t('SecurityPosture.ConfigTableList.violationType.WIFIBlackAndWhiteList') },
        { type: '36', label: this.$t('SecurityPosture.ConfigTableList.violationType.SystemFunction') },
        { type: 'S_MACOS_STATUS', label: this.$t('SecurityPosture.ConfigTableList.violationType.macOS') },
        { type: 'S_WINDOWS_STATUS', label: this.$t('SecurityPosture.ConfigTableList.violationType.Windows') },
        { type: 'S_VPN_STATUS', label: this.$t('SecurityPosture.ConfigTableList.violationType.VPN') },
        { type: 'S_CLOUD_PHONE_STATUS', label: this.$t('SecurityPosture.ConfigTableList.violationType.CloudPhone') },
        // { type: 'S_CHECK_SCREENSHOT_STATUS', label: '截屏阻断解除' }
      ],
      // 威胁行为   行为类型  关系映射
      typeOptionsThree: [
        { type: '', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.BehaviorType') },
        { type: 'S_CHECK_INJECT_STATUS', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.InjectionAttack') },
        { type: 'S_CHECK_FRAME_ATTACK', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.FrameAttack') },
        { type: 'S_CHECK_DEBUG_STATUS', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.DebuggingBehavior') },
        { type: 'S_CHECK_LOCATION_FRAUD', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.LocationFraud') },
        { type: 'S_CHECK_DOMAIN', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.DomainNameFraud') },
        { type: 'S_CHECK_PLUGIN', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.ProgramPlugIn') },
        { type: 'S_CHECK_SIGNATURE', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.ApplicationCracking') },
        { type: 'S_CHECK_ABNORMAL_CA', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.HttpsHijacking') },
        { type: 'S_CHECK_WIFI_PROXY', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.HttpProxy') },
        { type: 'S_CHECK_MULTI_APK', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.ApplicationMultiplex') },
        { type: 'S_CHECK_FREQUENCY_ACCOUNT', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.HighFrequencyChangeAccount') },
        { type: 'S_CHECK_FREQUENCY_IP', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.HighFrequencyChangeIP') },
        { type: 'S_CHECK_FREQUENCY_LOCATION', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.HighFrequencyChangePosition') },
        { type: 'S_CHECK_FREQUENCY_RESTART', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.HighFrequencyStartingApplication') },
        { type: 'S_FRAME_ATTACH', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.FrameSoftware') },
        { type: 'S_CHECK_DANGER_APPS', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.RiskApplication') },
        { type: 'S_CHECK_SYS_USER_CA', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.RootCertificateException') },
        { type: 'S_ADB_ENABLED', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.USBDebugging') },
        { type: 'S_CHECK_ROOT_STATUS', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.ROOTJailbreak') },
        { type: 'S_ALLOW_MOCK_LOCATION', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.SimulatedPositionAllowed') },
        { type: 'S_CHECK_VM_STATUS', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.simulator') },
        { type: 'S_CHECK_CUSTOM_ROM', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.CustomROM') },
        { type: 'S_CHECK_SYSTEM_TIME', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.SystemTimeException') },
        { type: 'S_CHECK_SCREEN_PASSWORD', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.TheScreenLockPasswordPsNotEnabled') },
        { type: 'S_CHECK_MEMORY_CHANGE', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.MemoryTampering') },
        { type: 'S_MACOS_STATUS', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.macOS') },
        { type: 'S_WINDOWS_STATUS', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.Windows') },
        { type: 'S_VPN_STATUS', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.VPN') },
        { type: 'S_CLOUD_PHONE_STATUS', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.CloudPhone') },
        { type: 'S_CHECK_DANGER_IP', label: this.$t('SecurityPosture.ConfigTableList.ThreateningBehavior.CloudPhone') },
        // { type: 'S_CHECK_SCREENSHOT_STATUS', label: '截屏阻断解除' }
      ],
      // 敏感行为   行为类型  关系映射
      typeOptionsFour: [
        { infoType: '', label: this.$t('SecurityPosture.ConfigTableList.SensitiveBehavior.BehaviorType') },
        { infoType: 'sensitive', label: this.$t('SecurityPosture.ConfigTableList.SensitiveBehavior.ApplicationSensitiveBehavior') },
        { infoType: 'excessed', label: this.$t('SecurityPosture.ConfigTableList.SensitiveBehavior.ApplicationOfUltraVires') },
        { type: 'S_MACOS_STATUS', label: this.$t('SecurityPosture.ConfigTableList.SensitiveBehavior.macOS') },
        { type: 'S_WINDOWS_STATUS', label: this.$t('SecurityPosture.ConfigTableList.SensitiveBehavior.Windows') },
        { type: 'S_VPN_STATUS', label: this.$t('SecurityPosture.ConfigTableList.SensitiveBehavior.VPN') },
        { type: 'S_CLOUD_PHONE_STATUS', label: this.$t('SecurityPosture.ConfigTableList.SensitiveBehavior.CloudPhone') },
        // { type: 'S_CHECK_SCREENSHOT_STATUS', label: '截屏阻断解除' }
      ],
      //SDK行为
      behavType: [
        {
          selectValue: '',
          label1: this.$t('SecurityPosture.ConfigTableList.SDKList.BehaviorType')
        },
        {
          selectValue: 'S_SMS_ACCESS',
          label1: this.$t('SecurityPosture.ConfigTableList.SDKList.ReadSMSRecords')
        },
        {
          selectValue: 'S_CONTACTS_ACCESS',
          label1: this.$t('SecurityPosture.ConfigTableList.SDKList.ReadAddressBook')
        },
        {
          selectValue: 'S_LOCATION',
          label1: this.$t('SecurityPosture.ConfigTableList.SDKList.GetGeographicalLocation')
        },
        {
          selectValue: 'S_GET_SDCARD',
          label1: this.$t('SecurityPosture.ConfigTableList.SDKList.ReadStorage')
        },
        {
          selectValue: 'S_GET_DEVICE_INFO',
          label1: this.$t('SecurityPosture.ConfigTableList.SDKList.ObtainHardware')
        },
        {
          selectValue: 'S_GET_INSTALLED_APP',
          label1: this.$t('SecurityPosture.ConfigTableList.SDKList.ObtainSoftware')
        },
        {
          selectValue: 'S_CAMERA',
          label1: this.$t('SecurityPosture.ConfigTableList.SDKList.picturesVideo')
        },
        {
          selectValue: 'S_WIFI_SWITCH',
          label1: this.$t('SecurityPosture.ConfigTableList.SDKList.TurnOnWIFI')
        },
        {
          selectValue: 'S_BLUETOOTH_SWITCH',
          label1: this.$t('SecurityPosture.ConfigTableList.SDKList.EnableBluetooth')
        },
        { type: 'S_MACOS_STATUS', label1: this.$t('SecurityPosture.ConfigTableList.SDKList.macOS') },
        { type: 'S_WINDOWS_STATUS', label1: this.$t('SecurityPosture.ConfigTableList.SDKList.Windows') },
        { type: 'S_VPN_STATUS', label1: this.$t('SecurityPosture.ConfigTableList.SDKList.VPN') },
        { type: 'S_CLOUD_PHONE_STATUS', label1: this.$t('SecurityPosture.ConfigTableList.SDKList.CloudPhone') },
        // { type: 'S_CHECK_SCREENSHOT_STATUS', label: '截屏阻断解除' }
      ],
      form: {
        region: ''
      },
      optionData: [
        {
          //统计内容
          value: '1',
          label: '告警列表'
        },
        {
          value: '2',
          label: '违规行为'
        },
        {
          value: '3',
          label: '威胁行为'
        },
        {
          value: '4',
          label: '敏感行为'
        },
        {
          value: '5',
          label: '安全事件'
        },
        {
          value: '6',
          label: 'SDK行为'
        },
        {
          value: '7',
          label: '运行信息'
        }
      ],
      optionValue: {
        value: '',
        label: ''
      },
      // 全局的值
      allData: ''
    }
  },
  created() {
    this.optionValue.value = this.pageContent.showConf
    this.optionData.forEach((item, index) => {
      if (this.optionValue.value === item.value) {
        this.tableTitle = item.label
        this.optionValue.label = item.label
      }
    })
    this.sendUrl(this.optionValue.value)
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
  },
  methods: {
    child(v) {
      this.optionValue.value = v.tableInfo.showConf
      this.optionData.forEach((item, index) => {
        if (this.optionValue.value === item.value) {
          this.tableTitle = item.label
          this.optionValue.label = item.label
        }
      })
      this.sendUrl(this.optionValue.value)
    },
    async sendUrl(typeValue) {
      var querayObj = {
        pageSize: 5,
        currentPage: 1,
        orderColume: 'CREATETIME',
        orderRule: 'DESC'
        //reason: '',
        //level: '',
        //name: '',
        //selectedOptions: '',
        //total: 0 //总条数
      }

      if (typeValue === '1') {
        this.oneTable = true
        this.twoTable = false
        this.threeTable = false
        this.fourTable = false
        this.fiveTable = false
        this.sixTable = false
        this.sevenTable = false
        const res = await this.$axios.post(
          '/httpServe/securitySituation/getRealTimeAlarm',
          querayObj,
          true
        )
        this.tableData = res.data.splice(0, 5)
        return res.data
      } else if (typeValue === '2') {
        this.oneTable = false
        this.twoTable = true
        this.threeTable = false
        this.fourTable = false
        this.fiveTable = false
        this.sixTable = false
        this.sevenTable = false
        const res = await this.$axios.post(
          '/httpServe/violations/getDataInfo',
          querayObj,
          true
        )

        this.tableData = res.data.content
        for (var i = 0; i < this.tableData.length; i++) {
          for (var j = 0; j < this.typeOptions.length; j++) {
            if (this.tableData[i].type == this.typeOptions[j].type) {
              this.tableData[i].type = this.typeOptions[j].label
            }
          }
        }
        this.tableData = res.data.content.splice(0, 5)
        return res.data
      } else if (typeValue === '3') {
        this.oneTable = false
        this.twoTable = false
        this.threeTable = true
        this.fourTable = false
        this.fiveTable = false
        this.sixTable = false
        this.sevenTable = false
        const res = await this.$axios.post(
          '/httpServe/appDanger/getDataInfo',
          querayObj,
          true
        )
        this.tableData = res.data.content
        for (var i = 0; i < this.tableData.length; i++) {
          for (var j = 0; j < this.typeOptionsThree.length; j++) {
            if (this.tableData[i].type == this.typeOptionsThree[j].type) {
              this.tableData[i].type = this.typeOptionsThree[j].label
            }
          }
        }
        this.tableData = res.data.content.splice(0, 5)
        return res.data
      } else if (typeValue === '4') {
        this.oneTable = false
        this.twoTable = false
        this.threeTable = false
        this.fourTable = true
        this.fiveTable = false
        this.sixTable = false
        this.sevenTable = false
        const res = await this.$axios.post(
          '/httpServe/sensitive/getDataInfo',
          querayObj,
          true
        )
        this.tableData = res.data.content
        for (var i = 0; i < this.tableData.length; i++) {
          for (var j = 0; j < this.typeOptionsFour.length; j++) {
            if (
              this.tableData[i].infoType == this.typeOptionsFour[j].infoType
            ) {
              this.tableData[i].infoType = this.typeOptionsFour[j].label
            }
          }
        }
        this.tableData = res.data.content.splice(0, 5)
        return res.data
      } else if (typeValue === '5') {
        this.oneTable = false
        this.twoTable = false
        this.threeTable = false
        this.fourTable = false
        this.fiveTable = true
        this.sixTable = false
        this.sevenTable = false
        const res = await this.$axios.post(
          '/httpServe/appEvent/getDataInfo',
          querayObj,
          true
        )
        // this.tableData = res.data.content
        this.tableData = res.data.content.splice(0, 5)
        return res.data
      } else if (typeValue === '6') {
        this.oneTable = false
        this.twoTable = false
        this.threeTable = false
        this.fourTable = false
        this.fiveTable = false
        this.sixTable = true
        this.sevenTable = false
        const res = await this.$axios.post(
          '/httpServe/sensitiveSDK/getDataInfo',
          querayObj,
          true
        )
        this.tableData = res.data.content.content
        for (var i = 0; i < this.tableData.length; i++) {
          for (var j = 0; j < this.behavType.length; j++) {
            if (this.tableData[i].type == this.behavType[j].selectValue) {
              this.tableData[i].type = this.behavType[j].label1
            }
          }
        }
        this.tableData = res.data.splice(0, 5)
        return res.data
      } else if (typeValue === '7') {
        this.oneTable = false
        this.twoTable = false
        this.threeTable = false
        this.fourTable = false
        this.fiveTable = false
        this.sixTable = false
        this.sevenTable = true
        const res = await this.$axios.post(
          '/httpServe/appRun/getDataInfo',
          querayObj,
          true
        )
        // this.tableData = res.data.content
        this.tableData = res.data.content.splice(0, 5)
        return res.data
      }

      //return res.data;
    },

    // 弹框点击关闭事件
    handleClose() {
      this.statusVisible = false // 卡片设置弹框关闭
    },
    //确定的关闭
    determine() {
      if (this.metaDataValue == '' && this.metaTableTitle == '') {
        this.dataValue = this.pageContent.showConf
      } else {
        this.dataValue = this.metaDataValue
        this.tableTitle = this.metaTableTitle
      }
      this.pageContent.showConf = this.dataValue
      var returnData = {
        saveData: this.pageContent
      }
      this.$emit('tableTitle', returnData)
      this.tableData = this.sendUrl(this.optionValue.value)
      this.statusVisible = false //卡片设置弹框关闭
    },
    // 设置卡片弹框
    setUp() {
      this.statusVisible = true
    },
    content(event) {
      this.metaDataValue = event.value
      this.metaTableTitle = event.label
    }
  }
}
</script>

<style lang="scss" scoped>
//分辨率 1920
@media screen and (min-width: 1920px) and (max-width: 2040px) {

  // 表格
  .tableStyle {
    width: 100%;
    height: 39%;
    margin-top: 2%;
  }

  //  高中低级别字体
  .fontSizes {
    font-size: 14px;
  }

  //  回显 字体大小
  .tsize {
    font-size: 16px;
  }

  //  更多字体大小
  .more {
    font-size: 16px;
    margin-right: 4px;
  }
}

//分辨率 1680
@media screen and (min-width: 1680px) and (max-width: 1919px) {

  // 表格
  ::v-deep .tableStyle {
    width: 100%;
    height: 25%;
    margin-top: 2%;
    z-index: 9;
  }

  //  高中低级别字体
  .fontSizes {
    font-size: 14px;
  }

  // 表格 tr 高度
  ::v-deep .el-table .cell {
    line-height: 18px;
  }

  // 回显 字体大小
  .tsize {
    font-size: 16px;
  }

  //  更多  字体大小
  .more {
    font-size: 16px;
    margin-right: 4px;
  }
}

// 分辨率2560
@media screen and (min-width: 2048px) and (max-width: 2560px) {

  // 表格
  ::v-deep .tableStyle {
    width: 100%;
    height: 25%;
    margin-top: 2%;
    z-index: 9;
  }

  //  高中低级别字体
  .fontSizes {
    font-size: 18px;
  }

  // 回显  字体大小
  .tsize {
    font-size: 20px;
  }

  //  更多 字体大小
  .more {
    font-size: 20px;
    margin-right: 4px;
  }

  // 表格tr 高度、 表格每行字体 大小
  ::v-deep .el-table .cell {
    line-height: 55px;
    font-size: 20px;
  }
}

.tableClass5 {
  margin-top: 20px;
  height: 250px !important;
  //  border:red ;
  z-index: 10;
  // 只去除表格的最最底部的横线
}

// 去掉表格单元格边框
.el-table th {
  border: none;
}

.el-table td,
.el-table th.is-leaf {
  border: none;
}

// 表格最外边框
.el-table--border,
.el-table--group {
  border: none;
}

// 头部边框
.el-table thead tr th.is-leaf {
  // border: 1px solid #ebeef5;
  border-right: none;
}

.el-table thead tr th:nth-last-of-type(2) {
  border-right: 1px solid #ebeef5;
}

// 表格最外层边框-底部边框
.el-table--border::after,
.el-table--group::after {
  width: 0;
}

.el-table::before {
  width: 0;
}

.el-table .el-table__fixed-right::before,
.el-table__fixed::before {
  width: 0;
}

// 表格有滚动时表格头边框
// .el-table--border th.gutter:last-of-type {
//   border: 1px solid #ebeef5;
//   border-left: none;
// }

::v-deep .el-table th>.cell {
  /* font-size: 13px; */
  // color: pink !important;
  z-index: 22;
}

// 齿轮设置
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

// color: "#02B9CD";

::v-deep .el-table,
.el-table__expanded-cell {
  background-color: #061d60;
  color: '#02B9CD';
}

::v-deep .el-table tr {
  background-color: #061d60 !important;

}

::v-deep .el-table tbody tr:hover>td {
  background-color: #061d60 !important;

}

::v-deep .el-table–enable-row-transition .el-table__body td,
.el-table .cell {
  background-color: #061d60;
}

::v-deep .el-table__header th,
.el-table__header tr {
  background: #061d60 !important;
}

::v-deep .el-table__empty-text {
  color: #cec8c8;
}

::v-deep .el-table--small .el-table__cell {
  padding: 3px 0;
}

::v-deep .el-table__body-wrapper .el-table__row:nth-child(odd) {
  color: #FFFFFF !important;
  /* 奇数行的颜色 */
}

::v-deep .el-table__body-wrapper .el-table__row:nth-child(even) {
  color: #FFFFFF !important;
  /* 偶数行的颜色 */
}
::v-deep .el-button--text {
  border-color: transparent;
  color: #FFFFFF !important;
  background: 0 0;
  padding-left: 0;
  padding-right: 0;
}
</style>

