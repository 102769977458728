<template>
  <div style="width: 100%; height: 100%">
    <div style="width: 100%; height: 100%; position: relative">
      <svg
        class="icon"
        aria-hidden="true"
        @click="setUp"
        style="
          position: absolute;
          left: 95%;
          top: 0;
          cursor: pointer;
          z-index: 2;
        "
      >
        <use xlink:href="#icon-shezhi-"></use>
      </svg>
      <div
        v-show="mapFlag"
        id="map"
        ref="map"
        style="width: 100%; height: 100%"
      ></div>
      <div
        v-show="headerFlag"
        style="width: 100%; height: 100%; position: relative"
      >
        <div
          v-show="headerFlag"
          style="width: 100%; height: 100%; position: relative"
        >
          <div
            class="headerTu"
            style="
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: space-around;
            "
          >
            <div
              class="square1"
              style="
                width: 20%;
                display: flex;
                flex-direction: column;
                margin: auto;
              "
            >
              <div
                class="borderlef1"
                style="width: 150px; height: 80px; margin-top: -76%"
              >
                <!-- title="违规行为" -->
                <el-popover
                  style="color: aliceblue"
                  effect="Light"
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  popper-class="wrap"
                  :content="
                    '违规行为' +
                    '【' +
                    this.heGuiStatusIndex +
                    '】' +
                    '：' +
                    this.heGuiStatus +
                    '人'
                  "
                >
                  <dv-border-box-10
                    :color="['#123D8C', '#123D8C']"
                    slot="reference"
                    style="
                      width: 180px;
                      height: 60px;
                      border: white;
                      text-align: center;
                      line-height: 60px;
                    "
                    ><span style="color: aliceblue"
                      >合规状态</span
                    ></dv-border-box-10
                  >
                </el-popover>
              </div>
              <div
                class="borderlef2"
                style="width: 150px; height: 80px; margin-top: 35%"
              >
                <!-- title="用户活跃" -->
                <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  :content="
                    '用户活跃' +
                    '【' +
                    this.maxUserDataIndex +
                    '】' +'：'+
                    this.maxUserData +
                    '人'
                  "
                  popper-class="wrap"
                >
                  <dv-border-box-10
                    :color="['#123D8C', '#123D8C']"
                    slot="reference"
                    style="
                      width: 180px;
                      height: 60px;
                      border: white;
                      text-align: center;
                      line-height: 60px;
                    "
                    ><span style="color: aliceblue"
                      >作息偏好</span
                    ></dv-border-box-10
                  >
                </el-popover>
              </div>
            </div>
            <div
              style="
                width: 20%;
                display: flex;
                flex-direction: column;
                margin: auto;
              "
            ></div>
            <div
              class="square2"
              style="
                width: 20%;
                display: flex;
                flex-direction: column;
                margin: auto;
              "
            >
              <div
                class="borderlef3"
                style="width: 150px; height: 80px; margin-top: -76%"
              >
                <!-- title="敏感词" -->
                <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  popper-class="wrap"
                  :content="
                    '敏感词' +
                    '【' +
                    this.maxWordDataIndex +
                    '】' +
                    '：' +
                    this.maxWordData +
                    '人'
                  "
                >
                  <dv-border-box-10
                    :color="['#123D8C', '#123D8C']"
                    slot="reference"
                    style="
                      width: 180px;
                      height: 60px;
                      border: white;
                      text-align: center;
                      line-height: 60px;
                    "
                    ><span style="color: aliceblue"
                      >言论特征</span
                    ></dv-border-box-10
                  >
                </el-popover>
              </div>
              <div
                class="borderlef4"
                style="width: 150px; height: 80px; margin-top: 35%"
              >
                <!-- title="用户使用" -->
                <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  popper-class="wrap"
                  :content="
                    '应用名称' +
                    '【' +
                    this.maxUseDataIndex +
                    '】' +
                    '：' +
                    this.maxUseData +
                    '人'
                  "
                >
                  <dv-border-box-10
                    :color="['#123D8C', '#123D8C']"
                    slot="reference"
                    style="
                      width: 180px;
                      height: 60px;
                      border: white;
                      text-align: center;
                      line-height: 60px;
                    "
                    ><span style="color: aliceblue"
                      >上网偏好</span
                    ></dv-border-box-10
                  >
                </el-popover>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--卡片配置弹框 -->
    <Eldialog
      :title="'选择图表'"
      :width="'460px'"
      :btnTitle="'确定'"
      :flagbtn="true"
      :cancelBtn="true"
      :dialogVisible="statusVisible"
      @handleClose="handleClose"
      :determineBtn="true"
      :num="'270px'"
      @determine="determine"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        style="margin-top: 30px"
      >
        <el-form-item label="统计维度：">
          <el-select
            v-model="dimensionValue"
            @change="weiDu(dimensionValue)"
            placeholder="请选择"
          >
            <el-option
              v-for="item in dimension"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 默认应用 -->
        <el-form-item label="统计内容：">
          <el-select
            v-model="statisticalValue1"
            @change="content(statisticalValue1)"
            placeholder="请选择"
          >
            <el-option
              v-for="item in statistical"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图表类型：" v-if="selectFlag">
          <el-select
            v-model="chartsValue"
            @change="tuBiao(chartsValue)"
            placeholder="请选择"
          >
            <el-option
              v-for="item in chartsTu"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </Eldialog>
  </div>
</template>

<script>
import * as echarts from "echarts"; //echarts图表引用
import Eldialog from "@/components/elDialog"; //详情弹框
import "../../../assets/iconfont/iconfont.js"; //小齿轮设置的icon
import elementResizeDetectorMaker from "element-resize-detector"; //获取div宽高
import "echarts/map/js/china"; //中国地图引用js
// import cityData from '@/utils/city';//城市模拟数据
import geoCoordMap from "../../../utils/cityOption"; //城市的经纬度
export default {
  props: {
    pageContent: {
      type: Object,
      default: () => {},
    },
    //地图
    mapData: {
      type: Object,
      default: () => {},
    },
    //图表1
    sumData: {
      type: Object,
      default: () => {},
    },
  },
  // 组件
  components: {
    Eldialog,
  },
  data() {
    return {
      selectFlag: false, //下拉框用户 图表类型隐藏
      cityTitle: "", //悬浮框提示
      heGuiStatus: "", //合规状态最大值
      heGuiStatusIndex: "", //合规状态最大值名称
      maxUserData: "", //作息偏好最大值
      maxUserDataIndex: "", //作息偏好最大值名称
      maxWordData: "", //敏感词最大值
      maxWordDataIndex: "", //敏感词最大值名称
      maxUseData: "", //上网偏好
      maxUseDataIndex: "", //上网偏好最大值名称
      // sumData:{},
      mapFlag: false, //地图默认显示
      headerFlag: false, //人头默认隐藏
      //第一维度选择
      firstSelect: "",
      //第二维度选择
      secondSelect: "",
      //第三维度选择
      threeSelect: "",
      metaMapData: null,
      mapList: {},
      form: {
        region: "",
      },
      //city:[],//城市 模拟数据
      location: geoCoordMap, //城市经纬度
      statusVisible: false, //默认设置卡片不展示
      dimension: [
        {
          //统计维度
          value: "appInfo",
          label: "应用",
        },
        {
          value: "deviceInfo",
          label: "设备",
        },
        {
          value: "userInfo",
          label: "用户",
        },
      ],
      dimensionValue: "", //维度
      //-------------------------------------
      statistical: [], // 循环遍历 内容
      statisticalValue1: "", //内容
      //----------------------------------------内容
      //应用维度下拉选
      appInfo_statistical: [
        {
          //统计内容
          value: "alarmInfoConf",
          label: "应用威胁分布",
        },
      ],
      //设备维度下拉选
      deviceInfo_statistical: [
        {
          value: "violaInfoConf",
          label: "设备违规分布",
        },
      ],
      userInfo_statistical: [
        {
          value: "drawInfoConf",
          label: "画像分析",
        },
      ],

      chartsTu: [], //循环  图表的数组
      chartsValue: "", //图表
      // ----------------------------------图表
      appChart: [
        {
          value: "map",
          label: "全国地图",
        },
      ],
      userChart: [],
      strNumber:0,
      queryName:'',
      dataRes:[],
      flagCity:false,
      cityOption:[],
      newArr2:[],
      newArr:[],
      province_city:null,
      cityName:[],
    };
  },

  //获取数据
  created() {
    this.chartsData();
    // 维度
    var showConfStr = this.pageContent.showConf;
    this.dimensionValue = showConfStr;
    //具体维度下显示内容
    var metaData = this.pageContent[showConfStr];
    this.statisticalValue1 = metaData;
    if (showConfStr === "appInfo") {
      //下拉选列表
      (this.mapFlag = true),
        (this.headerFlag = false),
        (this.statistical = this.appInfo_statistical);
      this.chartsTu = this.appChart;
      this.cityTitle = "应用威胁次数";
      this.chartsValue = "map";
      this.metaMapData = this.mapData[showConfStr].alarmInfo;
    }
    if (showConfStr === "deviceInfo") {
      (this.mapFlag = true),
        (this.headerFlag = false),
        (this.statistical = this.deviceInfo_statistical);
      this.chartsTu = this.appChart;
      this.cityTitle = "设备违规数量";
      this.chartsValue = "map";
      this.metaMapData = this.mapData[showConfStr].violaInfo;
    }
    if (showConfStr === "userInfo") {
      (this.mapFlag = false),
        (this.headerFlag = true),
        (this.statistical = this.userInfo_statistical);
      this.chartsTu = this.userChart;
      this.chartsValue = "";
      this.metaMapData = this.mapData[showConfStr].complianceStatus;
    }
  },
  mounted() {
    var showConfStr = this.pageContent.showConf;
    if (showConfStr === "appInfo") {
      this.selectFlag = true;
      (this.mapFlag = true), (this.headerFlag = false), this.getMapChart();
    } else if (showConfStr === "deviceInfo") {
      this.selectFlag = true;
      (this.mapFlag = true), (this.headerFlag = false), this.getMapChart();
    } else if (showConfStr === "userInfo") {
      this.selectFlag = false;
      (this.mapFlag = false), (this.headerFlag = true);
    }
  },
  methods: {
    //图形数据接口
    async chartsData() {
      var param = {
        timeType: "3",
      };
      const res = await this.$axios.post(
        "/httpServe/securitySituation/getChartDataInfo",
        param,
        true
      );
      // 合规状态
      var arr = this.mapData.userInfo.num;
      var max = arr[0];
      var index = 0;
      for (var i = 1; i < arr.length; i++) {
        if (max < arr[i]) {
          max = arr[i];
          index = i;
        }
      }
      this.heGuiStatus = max; 
      this.heGuiStatusIndex = this.mapData.userInfo.name[index];
      if (this.heGuiStatusIndex == "0") {
        this.heGuiStatusIndex = "设备越狱";
      } else if (this.heGuiStatusIndex == "1") {
        this.heGuiStatusIndex = "系统版本";
      } else if (this.heGuiStatusIndex == "2") {
        this.heGuiStatusIndex = "应用黑名单";
      } else if (this.heGuiStatusIndex == "3") {
        this.heGuiStatusIndex = "应用白名单";
      } else if (this.heGuiStatusIndex == "4") {
        this.heGuiStatusIndex = "";
      } else if (this.heGuiStatusIndex == "5") {
        this.heGuiStatusIndex = "地理围栏";
      } else if (this.heGuiStatusIndex == "6") {
        this.heGuiStatusIndex = "";
      } else if (this.heGuiStatusIndex == "7") {
        this.heGuiStatusIndex = "流量异常";
      } else if (this.heGuiStatusIndex == "8") {
        this.heGuiStatusIndex = "SIM卡变更";
      } else if (this.heGuiStatusIndex == "9") {
        this.heGuiStatusIndex = "违规外联";
      } else if (this.heGuiStatusIndex == "10") {
        this.heGuiStatusIndex = "设备管理器未激活";
      } else if (this.heGuiStatusIndex == "11") {
        this.heGuiStatusIndex = "证书变更";
      } else if (this.heGuiStatusIndex == "12") {
        this.heGuiStatusIndex = "必装应用";
      } else if (this.heGuiStatusIndex == "13") {
        this.heGuiStatusIndex = "SD卡变更";
      } else if (this.heGuiStatusIndex == "14") {
        this.heGuiStatusIndex = "设备电量";
      } else if (this.heGuiStatusIndex == "15") {
        this.heGuiStatusIndex = "存储空间";
      } else if (this.heGuiStatusIndex == "16") {
        this.heGuiStatusIndex = "系统版本";
      } else if (this.heGuiStatusIndex == "22") {
        this.heGuiStatusIndex = "应用黑白名单";
      } else if (this.heGuiStatusIndex == "99") {
        this.heGuiStatusIndex = "其他违规";
      } else if (this.heGuiStatusIndex == "33") {
        this.heGuiStatusIndex = "WIFI黑白名单";
      } else if (this.heGuiStatusIndex == "36") {
        this.heGuiStatusIndex = "系统功能";
      }

      // 作息偏好
      var sleepArr = res.data.userInfo.userActiveTrend.num;
      var sleepMax = sleepArr[0];
      var sleepIndex = 0;
      for (var i = 1; i < sleepArr.length; i++) {
        if (sleepMax < sleepArr[i]) {
          sleepMax = sleepArr[i];
          sleepIndex = i;
        }
      }
      this.maxUserData = sleepMax;
      this.maxUserDataIndex =
        res.data.userInfo.userActiveTrend.name[sleepIndex]; 

      // 言论特征  敏感词
      var wordArr = res.data.userInfo.sensitiveWords.num;
      var wordMax = wordArr[0];
      var wordIndex = 0;
      for (var i = 1; i < wordArr.length; i++) {
        if (wordMax < wordArr[i]) {
          wordMax = wordArr[i];
          wordIndex = i;
        }
      }
      this.maxWordData = wordMax === undefined ? 0 : wordMax;
      this.maxWordDataIndex =
        res.data.userInfo.sensitiveWords.name[wordIndex] === undefined
          ? "无"
          : res.data.userInfo.sensitiveWords.name[wordIndex]; 

      // 上网偏好 
      var goodArr = res.data.userInfo.useAppTop.num;
      var goodMax = goodArr[0];
      var goodIndex = 0;
      for (var i = 1; i < goodArr.length; i++) {
        if (goodMax < goodArr[i]) {
          goodMax = goodArr[i];
          goodIndex = i;
        }
      }
      this.maxUseData = goodMax === undefined ? 0 : goodMax;
      this.maxUseDataIndex =
        res.data.userInfo.useAppTop.name[goodIndex] === undefined
          ? "无"
          : res.data.userInfo.useAppTop.name[goodIndex]; 
    },

    conversion(typeData) {
      //中文描述
      var describe;

      switch (typeData) {
        case "pieChart":
          describe = "饼图";
          break;
        case "ringMoreChart":
          describe = "环形图";
          break;
        case "rosesChart":
          describe = "玫瑰图";
          break;
        case "topChart":
          describe = "柱状图";
          break;
        case "lineChart":
          describe = "折线图";
          break;
        case "cloudChart":
          describe = "词云图";
          break;
        case "barTuChart":
          describe = "条形图";
          break;
        default:
      }

      return describe;
    },

    // 首页地图
    getMapChart() {
      this.newArr = []
        this.newArr2 = []
        var arr = this.metaMapData
      this.province_city = new Map()
      for (var k in arr) {
        this.newArr.push(
          arr[k].province,
        )
        this.newArr2.push(
          arr[k].count,
        )

        let array = { name :[],
                     num:[]}; 

        array.name.push (arr[k].name) ; 
        array.num.push (arr[k].num)

        this.province_city.set(arr[k].province,array);
       
      }
      var showData = [];
      var columnarX = this.newArr;
      var columnarY = this.newArr2;
      for (var i = 0; i < this.metaMapData.length; i++) {
        var columnar = {
          value: "",
          name: "",
        };
        //需要映射显示中文描述

        if ("北京市" === columnarX[i]) {
          columnar.name = "北京";
        } else if ("天津市" === columnarX[i]) {
          columnar.name = "天津";
        } else if ("上海市" === columnarX[i]) {
          columnar.name = "上海";
        } else if ("重庆市" === columnarX[i]) {
          columnar.name = "重庆";
        } else if ("河北省" === columnarX[i]) {
          columnar.name = "河北";
        } else if ("山西省" === columnarX[i]) {
          columnar.name = "山西";
        } else if ("辽宁省" === columnarX[i]) {
          columnar.name = "辽宁";
        } else if ("江苏省" === columnarX[i]) {
          columnar.name = "江苏";
        } else if ("浙江省" === columnarX[i]) {
          columnar.name = "浙江";
        } else if ("安徽省" === columnarX[i]) {
          columnar.name = "安徽";
        } else if ("福建省" === columnarX[i]) {
          columnar.name = "福建";
        } else if ("江西省" === columnarX[i]) {
          columnar.name = "江西";
        } else if ("山东省" === columnarX[i]) {
          columnar.name = "山东";
        } else if ("河南省" === columnarX[i]) {
          columnar.name = "河南";
        } else if ("湖北省" === columnarX[i]) {
          columnar.name = "湖北";
        } else if ("湖南省" === columnarX[i]) {
          columnar.name = "湖南";
        } else if ("海南省" === columnarX[i]) {
          columnar.name = "海南";
        } else if ("四川省" === columnarX[i]) {
          columnar.name = "四川";
        } else if ("贵州省" === columnarX[i]) {
          columnar.name = "贵州";
        } else if ("云南省" === columnarX[i]) {
          columnar.name = "云南";
        }
        else if ('陕西省' === columnarX[i]) {
          columnar.name = '陕西省'
        } else if ('黑龙江省' === columnarX[i]) {
          columnar.name = '黑龙江'
        } else if ("澳门特别行政区" === columnarX[i]) {
          columnar.name = '澳门'
        }
        else if ("香港特别行政区，香港特别行政区，中西区" === columnarX[i]) {
          columnar.name = '香港'
        }
        else if ("香港特别行政区" === columnarX[i]) {
          columnar.name = '香港'
        }
        else if ("新疆维吾尔自治区" === columnarX[i]) {
          columnar.name = "新疆";
        } else if ("西藏自治区" === columnarX[i]) {
          columnar.name = "西藏";
        } else if ("广西壮族自治区" === columnarX[i]) {
          columnar.name = "广西";
        } else if ("境外" === columnarX[i]) {
          columnar.name = "境外";
        } else if ("内蒙古自治区" === columnarX[i]) {
          columnar.name = "内蒙古";
        } else if ("宁夏回族自治区" === columnarX[i]) {
          columnar.name = "宁夏";
        } else if ("吉林省" === columnarX[i]) {
          columnar.name = "吉林";
        } else if ("广东省" === columnarX[i]) {
          columnar.name = "广东";
        }
        //  else if ("N/A" === columnarX[i]) {
        //   columnar.name = "";
        // } 

        else if ("甘肃省" === columnarX[i]) {
          columnar.name = "甘肃";
        } else if ("湖南省" === columnarX[i]) {
          columnar.name = "湖南";
        } else if ("青海省" === columnarX[i]) {
          columnar.name = "青海";
        } else if ("台湾省" === columnarX[i]) {
          columnar.name = "台湾";
        }
        // else{
        //    columnar.name =  columnar.name
        // }
        columnar.value = columnarY[i];
        showData.push(columnar);

      }
      
      showData.forEach((item,index)=>{
        if(item.name == ''){
          showData.splice(index,1)
        }
      })

      var myChart = echarts.init(this.$refs.map);
      var option;
      const data = showData ? showData.splice(0,10) : [];

      //var geoCoordMap = this.location;
      var geoCoordMap = {
        黑龙江: [126.63, 45.75],
        陕西省: [108.95, 34.27],
        山西省: [112.548879, 37.87059],
        海门: [121.15, 31.89],
        鄂尔多斯: [109.781327, 39.608266],
        招远: [120.38, 37.35],
        舟山: [122.207216, 29.985295],
        齐齐哈尔: [123.97, 47.33],
        上海: [121.4648, 31.2891],
        新疆: [87.9236, 43.5883],
        甘肃: [103.5901, 36.3043],
        北京: [116.4551, 40.2539],
        江苏: [118.8062, 31.9208],
        广西: [108.479, 23.1152],
        江西: [116.0046, 28.6633],
        安徽: [117.29, 32.0581],
        内蒙古: [111.4124, 40.4901],
        黑龙江: [127.9688, 45.368],
        天津: [117.4219, 39.4189],
        山西: [112.3352, 37.9413],
        广东: [113.5107, 23.2196],
        四川: [103.9526, 30.7617],
        西藏: [91.1865, 30.1465],
        云南: [102.9199, 25.4663],
        浙江: [119.5313, 29.8773],
        湖北: [114.3896, 30.6628],
        辽宁: [123.1238, 42.1216],
        山东: [117.1582, 36.8701],
        河北: [114.4995, 38.1006],
        福建: [119.4543, 25.9222],
        陕西: [109.1162, 34.2004],
        贵州: [106.6992, 26.7682],
        河南: [113.4668, 34.6234],
        重庆: [107.7539, 30.1904],
        吉林: [125.8154, 44.2584],
        湖南: [113.0823, 28.2568],
        宁夏: [106.3586, 38.1775],
        海南: [110.3893, 19.8516],
        香港: [114.2049, 22.2459],
        澳门: [113.5764, 22.1578],
        台湾: [121.5637, 25.0374],
        境外: [132.651, 35.8128],
        青海: [101.7798, 36.6296],
        // 境外: [139.665139,35.802429]
        盐城: [120.13, 33.38],
        赤峰: [118.87, 42.28],
        青岛: [120.33, 36.07],
        乳山: [121.52, 36.89],
        金昌: [102.188043, 38.520089],
        泉州: [118.58, 24.93],
        莱西: [120.53, 36.86],
        日照: [119.46, 35.42],
        胶南: [119.97, 35.88],
        南通: [121.05, 32.08],
        拉萨: [91.11, 29.97],
        云浮: [112.02, 22.93],
        梅州: [116.1, 24.55],
        文登: [122.05, 37.2],
        上海: [121.48, 31.22],
        攀枝花: [101.718637, 26.582347],
        威海: [122.1, 37.5],
        承德: [117.93, 40.97],
        厦门: [118.1, 24.46],
        汕尾: [115.375279, 22.786211],
        潮州: [116.63, 23.68],
        丹东: [124.37, 40.13],
        太仓: [121.1, 31.45],
        曲靖: [103.79, 25.51],
        烟台: [121.39, 37.52],
        福州: [119.3, 26.08],
        瓦房店: [121.979603, 39.627114],
        即墨: [120.45, 36.38],
        抚顺: [123.97, 41.97],
        玉溪: [102.52, 24.35],
        张家口: [114.87, 40.82],
        阳泉: [113.57, 37.85],
        莱州: [119.942327, 37.177017],
        湖州: [120.1, 30.86],
        汕头: [116.69, 23.39],
        昆山: [120.95, 31.39],
        宁波: [121.56, 29.86],
        湛江: [110.359377, 21.270708],
        揭阳: [116.35, 23.55],
        荣成: [122.41, 37.16],
        连云港: [119.16, 34.59],
        葫芦岛: [120.836932, 40.711052],
        常熟: [120.74, 31.64],
        东莞: [113.75, 23.04],
        河源: [114.68, 23.73],
        淮安: [119.15, 33.5],
        泰州: [119.9, 32.49],
        南宁: [108.33, 22.84],
        营口: [122.18, 40.65],
        惠州: [114.4, 23.09],
        江阴: [120.26, 31.91],
        蓬莱: [120.75, 37.8],
        韶关: [113.62, 24.84],
        嘉峪关: [98.289152, 39.77313],
        广州: [113.23, 23.16],
        延安: [109.47, 36.6],
        太原: [112.53, 37.87],
        清远: [113.01, 23.7],
        中山: [113.38, 22.52],
        昆明: [102.73, 25.04],
        寿光: [118.73, 36.86],
        盘锦: [122.070714, 41.119997],
        长治: [113.08, 36.18],
        深圳: [114.07, 22.62],
        珠海: [113.52, 22.3],
        宿迁: [118.3, 33.96],
        咸阳: [108.72, 34.36],
        铜川: [109.11, 35.09],
        平度: [119.97, 36.77],
        佛山: [113.11, 23.05],
        海口: [110.35, 20.02],
        江门: [113.06, 22.61],
        章丘: [117.53, 36.72],
        肇庆: [112.44, 23.05],
        大连: [121.62, 38.92],
        临汾: [111.5, 36.08],
        吴江: [120.63, 31.16],
        石嘴山: [106.39, 39.04],
        沈阳: [123.38, 41.8],
        苏州: [120.62, 31.32],
        茂名: [110.88, 21.68],
        嘉兴: [120.76, 30.77],
        长春: [125.35, 43.88],
        胶州: [120.03336, 36.264622],
        银川: [106.27, 38.47],
        张家港: [120.555821, 31.875428],
        三门峡: [111.19, 34.76],
        锦州: [121.15, 41.13],
        南昌: [115.89, 28.68],
        柳州: [109.4, 24.33],
        三亚: [109.511909, 18.252847],
        自贡: [104.778442, 29.33903],
        吉林: [126.57, 43.87],
        阳江: [111.95, 21.85],
        泸州: [105.39, 28.91],
        西宁: [101.74, 36.56],
        宜宾: [104.56, 29.77],
        呼和浩特: [111.65, 40.82],
        成都: [104.06, 30.67],
        大同: [113.3, 40.12],
        镇江: [119.44, 32.2],
        桂林: [110.28, 25.29],
        张家界: [110.479191, 29.117096],
        宜兴: [119.82, 31.36],
        北海: [109.12, 21.49],
        西安: [108.95, 34.27],
        金坛: [119.56, 31.74],
        东营: [118.49, 37.46],
        牡丹江: [129.58, 44.6],
        遵义: [106.9, 27.7],
        绍兴: [120.58, 30.01],
        扬州: [119.42, 32.39],
        常州: [119.95, 31.79],
        潍坊: [119.1, 36.62],
        重庆: [106.54, 29.59],
        台州: [121.420757, 28.656386],
        南京: [118.78, 32.04],
        滨州: [118.03, 37.36],
        贵阳: [106.71, 26.57],
        无锡: [120.29, 31.59],
        本溪: [123.73, 41.3],
        克拉玛依: [84.77, 45.59],
        渭南: [109.5, 34.52],
        马鞍山: [118.48, 31.56],
        宝鸡: [107.15, 34.38],
        焦作: [113.21, 35.24],
        句容: [119.16, 31.95],
        北京: [116.46, 39.92],
        徐州: [117.2, 34.26],
        衡水: [115.72, 37.72],
        包头: [110, 40.58],
        绵阳: [104.73, 31.48],
        乌鲁木齐: [87.68, 43.77],
        枣庄: [117.57, 34.86],
        杭州: [120.19, 30.26],
        淄博: [118.05, 36.78],
        鞍山: [122.85, 41.12],
        溧阳: [119.48, 31.43],
        库尔勒: [86.06, 41.68],
        安阳: [114.35, 36.1],
        开封: [114.35, 34.79],
        济南: [117, 36.65],
        德阳: [104.37, 31.13],
        温州: [120.65, 28.01],
        九江: [115.97, 29.71],
        邯郸: [114.47, 36.6],
        临安: [119.72, 30.23],
        兰州: [103.73, 36.03],
        沧州: [116.83, 38.33],
        临沂: [118.35, 35.05],
        南充: [106.110698, 30.837793],
        天津: [117.2, 39.13],
        富阳: [119.95, 30.07],
        泰安: [117.13, 36.18],
        诸暨: [120.23, 29.71],
        郑州: [113.65, 34.76],
        哈尔滨: [126.63, 45.75],
        聊城: [115.97, 36.45],
        芜湖: [118.38, 31.33],
        唐山: [118.02, 39.63],
        平顶山: [113.29, 33.75],
        邢台: [114.48, 37.05],
        德州: [116.29, 37.45],
        济宁: [116.59, 35.38],
        荆州: [112.239741, 30.335165],
        宜昌: [111.3, 30.7],
        义乌: [120.06, 29.32],
        丽水: [119.92, 28.45],
        洛阳: [112.44, 34.7],
        秦皇岛: [119.57, 39.95],
        株洲: [113.16, 27.83],
        石家庄: [114.48, 38.03],
        莱芜: [117.67, 36.19],
        常德: [111.69, 29.05],
        保定: [115.48, 38.85],
        湘潭: [112.91, 27.87],
        金华: [119.64, 29.12],
        岳阳: [113.09, 29.37],
        长沙: [113, 28.21],
        衢州: [118.88, 28.97],
        廊坊: [116.7, 39.53],
        菏泽: [115.480656, 35.23375],
        合肥: [117.27, 31.86],
        武汉: [114.31, 30.52],
        大庆: [125.03, 46.58],
      };

      var convertData1 = function (data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name];
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value),
            });
          }
        }
        return res;
      };

      //设置一些可选的参数
      option = {
        //设置背景颜色
        backgroundColor: "rgba(0, 0, 0, 0)", //rgba设置透明度0.1
        //设置图片标题、子标题、文本颜色等等
        title: {
          // text: "echarts使用1",
          // subtext: "移动数据监测态势",
          left: "center",
          textStyle: {
            color: "#000",
          },
        },
        // tooltip: {
        //   trigger: "item",
        //   formatter: function (params) {
        //     // console.log(params,'---')
        //     // console.log(params.data.name,params.data.value[2],'----弹框提示')
        //     return `${params.seriesName}</br> ${params.data.name}:${params.data.value[2]}`
        //   }
        // },
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            return `${params.seriesName}</br> ${params.data.name}:${params.data.value[2]}`;

            // console.log(params.data.name,params.data.value[2],'----弹框提示')
            //   return `${params.seriesName}</br> ${params.data.name}:${params.data.value[2]}`
          },
        },
        geo: {
          regions: [
            {
              name: "南海诸岛",
              itemStyle: {
                // 隐藏地图
                normal: {
                  opacity: 1, // 为 0 时不绘制该图形
                },
              },
              label: {
                show: false, // 隐藏文字
              },
            },
          ],
          map: "china",
          aspectScale: 0.8, //echarts地图的长宽比（就是胖瘦）
          center: [104.795619, 35.224029], //设置可见中心坐标，以此坐标来放大和缩小
          zoom: 1.2, //放大级别

          label: {
            emphasis: {
              show: true,
            },
          },

          itemStyle: {
            normal: {
              borderWidth: 1, //boreder的粗细
              areaColor: "#034689", //整个地图每一块地图的色
              borderColor: "#19BCFF", //整个地图的border颜色
              shadowColor: "rgba(1, 39, 44, 1)", //#1244A1 rgba(1, 39, 44, 1)设置阴影
              shadowOffsetX: 0,
              shadowOffsetY: 12,
            },
            emphasis: {
              borderWidth: 1, //滑过的border线 粗细
              borderColor: "#E6E3E0", //滑过的background 粗细
              areaColor: "#0466fb", //鼠标滑过 地图的颜色 
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          roam: false,
        },

        //series就是要绘制的地图的主体。是一个数组，也就是说可以有多个数据进行绘制。这里有两个，一个是两个城市的连线，一个是对两个城市进行高亮显示。其中的type是很重要的参数，主要有饼图、条形图、线、地图等等。具体的可以去参考官网上的配置手册。
        series: [
          {
            name: "rode",
            type: "lines", //lines  这个属性是城市  + 39.56
            // mapType: 'china',
            geoIndex: 0,
            coordinateSystem: "geo",
            //data: showData,
            effect: {
              show: true,
              period: 6,
              trailLength: 0,
            },
            itemStyle: {
              //每一项数据颜色
              normal: {
                color: "#6CFBFF",
              },
            },
            lineStyle: {
              normal: {
                color: "#389BB7",
                width: 1,
                opacity: 0.4,
                curveness: 0.2,
              },
            },
          },
          {
            name: this.cityTitle,
            type: "effectScatter",
            coordinateSystem: "geo",
            rippleEffect: {
              brushType: "stroke",
            },
            label: {
              normal: {
                show: true,
                position: "right",
                formatter: "{b}",
              },
            },
            symbolSize: 12, //涟漪大小设置
            itemStyle: {
              shadowBlur: 10,
              //每一项数据颜色
              normal: {
                color: ["#6CFBFF"],
              },
            },
            data: convertData1(data),
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      let _this = this;
      let erd = elementResizeDetectorMaker();
      erd.listenTo(this.$refs.map, () => {
        _this.$nextTick(() => {
          myChart.resize();
        });
      });
    },

    //维度选择
    weiDu(val) {
      this.firstSelect = val;
      if (val === "appInfo") {
        this.selectFlag = true;
        this.cityTitle = "应用威胁次数";
        this.statistical = this.appInfo_statistical;
        this.statisticalValue1 = this.statistical[0].value;
        this.secondSelect = this.statisticalValue1;
        //三级下拉选
        this.chartsTu = this.appChart;
        //选中
        this.chartsValue = "map";
        this.threeSelect = this.chartsValue;
      } else if (val === "deviceInfo") {
        this.selectFlag = true;
        this.cityTitle = "设备违规数量";
        this.statistical = this.deviceInfo_statistical;
        this.statisticalValue1 = this.statistical[0].value;
        this.secondSelect = this.statisticalValue1;
        //三级下拉选
        this.chartsTu = this.appChart;
        //选中
        this.chartsValue = "map";
        this.threeSelect = this.chartsValue;
      } else if (val === "userInfo") {
        this.selectFlag = false;
        this.statistical = this.userInfo_statistical;
        this.statisticalValue1 = this.statistical[0].value;
        this.secondSelect = this.statisticalValue1;
        //三级下拉选
        this.chartsTu = [];
        //选中
        this.chartsValue = "";
        this.threeSelect = this.chartsValue;
      }
    },
    //确定的关闭
    determine() {
      //当用户不做任何操作进行保存时数据来源
      if (this.firstSelect == "") {
        this.firstSelect = this.dimensionValue;
      }
      if (this.secondSelect == "") {
        this.secondSelect = this.statisticalValue1;
      }
      if (this.threeSelect == "") {
        this.threeSelect = this.chartsValue;
      }

      var mateData = this.pageContent;

      mateData.showConf = this.firstSelect;
      var mapDatas = JSON.stringify(mateData);
      var data = mapDatas.replace("true", "false");
      mateData = JSON.parse(data);
      mateData[this.firstSelect][this.secondSelect] = true;
      //刷图  rings()
      var returnData = {
        saveData: mateData,
      };
      this.$emit("chinaReturnData", returnData);

      if (this.firstSelect === "appInfo") {
        this.metaMapData = this.mapData[this.firstSelect].alarmInfo;
        this.mapFlag = true;
        this.headerFlag = false;
        this.getMapChart();
      } else if (this.firstSelect === "deviceInfo") {
        this.metaMapData = this.mapData[this.firstSelect].violaInfo;
        this.mapFlag = true;
        this.headerFlag = false;
        this.getMapChart();
      } else if (this.firstSelect === "userInfo") {
        this.mapFlag = false;
        this.headerFlag = true;
      }

      this.statusVisible = false; //卡片设置弹框关闭
    },

    // 设置卡片弹框
    setUp() {
      this.statusVisible = true;
    },
    // 弹框点击关闭事件
    handleClose() {
      this.statusVisible = false; // 卡片设置弹框关闭
    },
  },
};
</script>

<style lang="scss" scoped>
// 齿轮设置
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.headerTu {
  background: url("../../../assets/heard.png"),
    //背景图
    rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style> 