<template>
  <div class="taishi">
    <el-row :gutter="20" style="height: 100%">
      <el-col :span="24" style="height: 100%">
        <div style="width: 100%; height: 100%">
          <div
            class="header"
            style="
              width: 100%;
              height: 10%;
              display: flex;
              position: relative;
              justify-content: space-around;
            "
          >
            <img
              src="../assets/top.png"
              alt=""
              srcset=""
              style="width: 100%; height: 100%; position: absolute;top:0.left:0; position: relative;"
            />
            <div
              style="
                width: 100%;
                color: white;
                height: 100%;
                display: flex;
                justify-content: space-around;
                position: absolute;
                top: 20%;
                left: 0;
              "
            >
              <div class="zuo">
                <!-- <span style="">{{ this.wholeTime }}</span> -->
                <span style="font-size: 20px"
                  >{{ this.nowDate }}&nbsp;&nbsp; {{ this.nowTime }}&nbsp;&nbsp;
                  {{ this.nowWeek }}</span
                >
              </div>
              <div class="zhong">
                <!-- {{ titleName }} -->
                {{ pageContent.titleInfo.titleName }}
                <svg
                  class="icon"
                  aria-hidden="true"
                  @click="setUp"
                  style="cursor: pointer; z-index: 2"
                >
                  <use xlink:href="#icon-shezhi-"></use>
                </svg>
              </div>
              <!-- <div class="right" style="width: 25%; margin-top: 1.5%">
                <div style="display: flex; justify-content: space-around">
                  <div style="cursor: pointer">当天&nbsp;&nbsp;</div>
                  <div style="cursor: pointer">近7天&nbsp;&nbsp;</div>
                  <div style="cursor: pointer">近30天&nbsp;&nbsp;</div>
                  <div style="cursor: pointer">近90天&nbsp;&nbsp;</div>
                  <div>
                    <el-tooltip class="item" effect="light" content="完成" placement="bottom-start">
                      <svg class="icon" aria-hidden="true" style="z-index: 2; cursor: pointer" @click="complete">
                        <use xlink:href="#icon-yiwancheng1"></use>
                      </svg>
                    </el-tooltip>

                    &nbsp;&nbsp;
                  </div>
                  <div>
                    <el-tooltip class="item" effect="light" content="退出" placement="bottom-start">
                    <svg class="icon" aria-hidden="true" style="z-index: 2; cursor: pointer" @click="goBack">
                      <use xlink:href="#icon-tuichuquanping"></use>
                    </svg>
                    </el-tooltip>
                  </div>
                </div>
              </div> -->
              <div class="right">
                <div style="display: flex; justify-content: space-around">
                  <ul
                    v-for="item in threatSwitch"
                    :key="item"
                    class="aside-name-active"
                    :class="{ active: active == item }"
                    style="width: 100px; display: flex; height: 30px; font-size: 20px"
                  >
                    <li style="display: inline; width: 100px">
                      {{ item }}
                    </li>
                  </ul>
                  <div>
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="完成"
                      placement="bottom-start"
                    >
                      <span @click="complete">
                        <svg
                          class="icon"
                          aria-hidden="true"
                          style="z-index: 2; cursor: pointer"
                        >
                          <use xlink:href="#icon-yiwancheng1"></use>
                        </svg>
                      </span>
                    </el-tooltip>

                    &nbsp;&nbsp;
                  </div>
                  <div>
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="退出"
                      placement="bottom-start"
                    >
                      <span @click="goBack">
                        <svg
                        class="icon"
                        aria-hidden="true"
                        style="z-index: 2; cursor: pointer; margin-left: 26px"
                      >
                        <use xlink:href="#icon-tuichuquanping"></use>
                      </svg>
                      </span>
                      
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sevurbox">
            <!-- 左 1、2、3 -->
            <div class="left1">
              <div style="width: 100%; height: 100%">
                <dv-border-box-12
                  :color="['#004aba']"
                  class="leftTop1"
                  style="width: 100%; height: 20%; margin-top: 2%"
                >
                  <div style="width: 100%; height: 100%; display: flex">
                    <div style="width: 50%; height: 100%">
                      <cartLeft_1
                        v-if="cardDataSum"
                        :pageContent="pageContent.cardInfo.cardInfoLeft_1"
                        :cardDataSum="cardDataSum"
                        @card1ReturnData="card1ReturnData"
                      />
                    </div>
                    <div style="width: 50%; height: 100%">
                      <cartLeft_2
                        v-if="cardDataSum"
                        :pageContent="pageContent.cardInfo.cardInfoLeft_2"
                        :cardDataSum="cardDataSum"
                        @card2ReturnData="card2ReturnData"
                      />
                    </div>
                  </div>
                </dv-border-box-12>
                <!-- 应用活跃趋势 -->
                <dv-border-box-11
                  :color="['#43b1fe', '#004aba']"
                  :title="pageContent.chartInfo.chartInfoLeft_1.titleName"
                  class="leftTop2"
                  style="width: 100%; height: 35%; margin-top: 2%"
                >
                  <div style="width: 100%; height: 100%">
                    <!-- v-if="allPageContent" @appActives="appActives" :allPageContent="allPageContent" -->
                    <activeTrend
                      v-if="echartData"
                      :echartData="echartData"
                      :appInstalls="appInstalls"
                      @leftCharts1ReturnData="leftCharts1ReturnData"
                      :pageContent="pageContent.chartInfo.chartInfoLeft_1"
                    />
                  </div>
                </dv-border-box-11>
                <!-- 应用活跃Top 5 -->
                <dv-border-box-11
                  :color="['#43b1fe', '#004aba']"
                  :title="pageContent.chartInfo.chartInfoLeft_2.titleName"
                  class="leftTop3"
                  style="width: 100%; height: 35%; margin-top: 2%"
                >
                  <div style="width: 100%; height: 100%">
                    <!-- @appActiveMethods="appActiveMethods" -->
                    <activeTop5
                      v-if="echartData"
                      :echartData="echartData"
                      :appInstalls="appInstalls"
                      @leftCharts2ReturnData="leftCharts2ReturnData"
                      :pageContent="pageContent.chartInfo.chartInfoLeft_2"
                    />
                  </div>
                </dv-border-box-11>
              </div>
            </div>
            <!-- 中 1、2-->
            <div class="center1">
              <div style="width: 100%; height: 100%">
                <div class="leftTop1" style="width: 100%; height: 53%; margin-top: 2%">
                  <div
                    class="beijing"
                    style="box-sizing: border-box !important; width: 100%; height: 100%"
                  >
                    <china
                      ref="chinaRef"
                      v-if="mapData"
                      :mapData="mapData"
                      :echartData="echartData"
                      @chinaReturnData="chinaReturnData"
                      :pageContent="pageContent.mapInfo"
                    />
                  </div>
                </div>
                <div class="tableStyle">
                  <!-- <div style="width: 100%; height: 100%"> -->
                  <!-- @tableTitle="tableTitle"    @tableList="tableList" -->
                  <tables
                    style="width: 100%; height: 100%"
                    ref="tablesRef"
                    :pageContent="pageContent.tableInfo"
                    @tableList="tableList"
                  />
                  <!-- </div> -->
                </div>
              </div>
            </div>
            <!-- 右 1、2、3-->
            <div class="right1">
              <dv-border-box-12
                :color="['#004aba']"
                class="leftTop1"
                style="width: 100%; height: 20%; margin-top: 2%"
              >
                <div style="width: 100%; height: 100%; display: flex">
                  <div style="width: 50%; height: 100%">
                    <cartRight_1
                      v-if="cardDataSum"
                      :pageContent="pageContent.cardInfo.cardInfoRight_1"
                      :cardDataSum="cardDataSum"
                      @card3ReturnData="card3ReturnData"
                    />
                  </div>
                  <div style="width: 50%; height: 100%">
                    <cartRight_2
                      v-if="cardDataSum"
                      :pageContent="pageContent.cardInfo.cardInfoRight_2"
                      :cardDataSum="cardDataSum"
                      @card4ReturnData="card4ReturnData"
                    />
                  </div>
                </div>
              </dv-border-box-12>
              <!-- 应用威胁趋势 -->
              <dv-border-box-11
                :color="['#43b1fe', '#004aba']"
                :title="pageContent.chartInfo.chartInfoRight_1.titleName"
                class="leftTop2"
                style="width: 100%; height: 35%; margin-top: 2%"
              >
                <div style="width: 100%; height: 100%">
                  <!--  @appThreat="appThreat" -->
                  <threatTrend
                    v-if="echartData"
                    :echartData="echartData"
                    :appInstalls="appInstalls"
                    @rightCharts1ReturnData="rightCharts1ReturnData"
                    :pageContent="pageContent.chartInfo.chartInfoRight_1"
                  />
                </div>
              </dv-border-box-11>
              <!-- 应用威胁Top5 -->
              <dv-border-box-11
                :color="['#43b1fe', '#004aba']"
                :title="pageContent.chartInfo.chartInfoRight_2.titleName"
                class="leftTop3"
                style="width: 100%; height: 35%; margin-top: 2%"
              >
                <div style="width: 100%; height: 100%">
                  <!--  @appThreatMethods="appThreatMethods" -->
                  <threatTop5
                    v-if="echartData"
                    :echartData="echartData"
                    :appInstalls="appInstalls"
                    @rightCharts2ReturnData="rightCharts2ReturnData"
                    :pageContent="pageContent.chartInfo.chartInfoRight_2"
                  />
                </div>
              </dv-border-box-11>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!--卡片配置弹框 -->
    <Eldialog
      :title="'修改标题'"
      :width="'460px'"
      :btnTitle="'确定'"
      :flagbtn="true"
      :cancelBtn="true"
      :dialogVisible="statusVisible"
      v-if="statusVisible"
      @handleClose="handleClose"
      :determineBtn="true"
      :num="'100px'"
      @determine="determine('form')"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        style="margin-top: 30px"
      >
        <el-form-item label="标题名称：" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
    </Eldialog>
    <!-- 提示消息框 -->
    <dialogInfo
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>

<script>
import Eldialog from "@/components/elDialog"; //详情弹框
import elementResizeDetectorMaker from "element-resize-detector"; //获取div宽高
import "../assets/iconfont/iconfont.js"; //小齿轮设置的icon
import * as echarts from "echarts"; //echarts图标引用
import "echarts/map/js/china"; //中国地图引用js
import cartLeft_1 from "../views/situationalNeeds/compontes/cratLeft.vue"; //卡片配置  左边1
import cartLeft_2 from "./situationalNeeds/compontes/cartLeft_2.vue"; //卡片配置   左边2
import cartRight_1 from "../views/situationalNeeds/compontes/cartRight.vue"; //卡片配置  右边1
import cartRight_2 from "../views/situationalNeeds/compontes/cartRight_2.vue"; //卡片配置  右边2
import activeTrend from "../views/situationalNeeds/compontes/activeTrend.vue"; //应用活跃趋势 左边2
import activeTop5 from "../views/situationalNeeds/compontes/activeTop5.vue"; //应用活跃Top5 左边3
import china from "../views/situationalNeeds/compontes/china.vue"; //地图配置
import tables from "../views/situationalNeeds/compontes/table.vue"; //表格配置

import threatTrend from "../views/situationalNeeds/compontes/threatTrend.vue"; //应用威胁趋势 右边2
import threatTop5 from "../views/situationalNeeds/compontes/threatTop5.vue"; //应用威胁top5 右边3
import dialogInfo from "@/components/promptMessage/index"; //提示消息框
export default {
  // 组件
  components: {
    Eldialog, //弹框
    dialogInfo, //提示信息
    cartLeft_1, //卡片配置 左边1
    cartLeft_2, //卡片配置 左边2
    cartRight_1, //卡片配置 右1
    cartRight_2, //卡片配置  右2
    activeTrend, //应用活跃趋势 左2
    activeTop5, //应用活跃Top5 左边3
    threatTrend, //应用威胁趋势 右2
    threatTop5, //应用威胁Top5 右3
    china, //地图
    tables, //表格
  },
  data() {
    let validcodeName = (rule, value, callback) => {
      let reg = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/;
      if (!reg.test(value)) {
        callback(new Error("不支持空格及特殊字符"));
      } else {
        callback();
      }
    };
    return {
      threatSwitch: this.$t('SecurityPosture.dispositionPage.threatSwitch'),
      active: this.$t('SecurityPosture.dispositionPage.active'), //选中样式  当天
      sumData: null,
      //第一维度选择
      firstSelect: "",
      //第二维度选择
      secondSelect: "",
      //第三维度选择
      threeSelect: "",
      newTitle: "", //更新后的值
      statusVisible: false, //默认弹框不展示
      isibaelFlag: false, //提示弹框默认false
      visibaelFlag: false, //提示框默认false
      titleInfo: "", //提示消息的title
      wholeTime: "", //整体时间
      nowDate: "", // 当前日期
      nowTime: "", // 当前时间
      nowWeek: "", // 当前星期

      cardDataSum: null, //卡片数据
      mapData: null, //地图数据
      echartData: null, //图表数据
      appInstalls: { android: {}, ios: {} }, //应用安装Top5接收数据
      //默认配置数据，可被接口配置覆盖
      pageContent: {
        cardInfo: {
          cardInfoLeft_1: {
            showConf: "appInfo",
            titleName: this.$t('SecurityPosture.dispositionPage.card.LeftOne'),
            appInfo: {
              appRunCountConf: true,
              appDangerCountConf: false,
              appEventCountConf: false,
              sdkBehaviorCountConf: false,
            },
            deviceInfo: {
              deviceSumCountConf: false,
              onLineCountConf: false,
              offLineCountConf: false,
              violationsSumCountConf: false,
              lostCountConf:false,			 
						  scrapCountConf:false,
              onLineRateConf:false //613设备在线率
            },
            userInfo: {
              userSumCountConf: false,
              addUserCountConf: false,
              normalUserCountConf: false,
              lockUserCountConf: false,
              stopUsingUserCountConf: false,
            },
            gatewayInfo: {
              deviceConnectCountConf: false,
              accessAppCountConf: false,
              userConnectCountConf: false,
            },
          },
          cardInfoLeft_2: {
            showConf: "appInfo",
            titleName: this.$t('SecurityPosture.dispositionPage.card.LeftTwo'),
            appInfo: {
              appRunCountConf: false,
              appDangerCountConf: true,
              appEventCountConf: false,
              sdkBehaviorCountConf: false,
            },
            deviceInfo: {
              deviceSumCountConf: false,
              onLineCountConf: false,
              offLineCountConf: false,
              violationsSumCountConf: false,
              lostCountConf:false,			 
						  scrapCountConf:false,
              onLineRateConf:false //613设备在线率
            },
            userInfo: {
              userSumCountConf: false,
              addUserCountConf: false,
              normalUserCountConf: false,
              lockUserCountConf: false,
              stopUsingUserCountConf: false,
            },
            gatewayInfo: {
              deviceConnectCountConf: false,
              accessAppCountConf: false,
              userConnectCountConf: false,
            },
          },
          cardInfoRight_1: {
            showConf: "appInfo",
            titleName: this.$t('SecurityPosture.dispositionPage.card.RightOne'),
            appInfo: {
              appRunCountConf: false,
              appDangerCountConf: false,
              appEventCountConf: true,
              sdkBehaviorCountConf: false,
            },
            deviceInfo: {
              deviceSumCountConf: false,
              onLineCountConf: false,
              offLineCountConf: false,
              violationsSumCountConf: false,
              lostCountConf:false,			 
						  scrapCountConf:false,
              onLineRateConf:false //613设备在线率
            },
            userInfo: {
              userSumCountConf: false,
              addUserCountConf: false,
              normalUserCountConf: false,
              lockUserCountConf: false,
              stopUsingUserCountConf: false,
            },
            gatewayInfo: {
              deviceConnectCountConf: false,
              accessAppCountConf: false,
              userConnectCountConf: false,
            },
          },
          cardInfoRight_2: {
            showConf: "appInfo",
            titleName: this.$t('SecurityPosture.dispositionPage.card.RightTwo'),
            appInfo: {
              appRunCountConf: false,
              appDangerCountConf: false,
              appEventCountConf: false,
              sdkBehaviorCountConf: true,
            },
            deviceInfo: {
              deviceSumCountConf: false,
              onLineCountConf: false,
              offLineCountConf: false,
              violationsSumCountConf: false,
              lostCountConf:false,			 
						  scrapCountConf:false,
              onLineRateConf:false //613设备在线率
            },
            userInfo: {
              userSumCountConf: false,
              addUserCountConf: false,
              normalUserCountConf: false,
              lockUserCountConf: false,
              stopUsingUserCountConf: false,
            },
            gatewayInfo: {
              deviceConnectCountConf: false,
              accessAppCountConf: false,
              userConnectCountConf: false,
            },
          },
        },
        chartInfo: {
          chartInfoLeft_1: {
            showConf: "appInfo",
            titleName: this.$t('SecurityPosture.dispositionPage.TheChart.chartInfoLeft_1'),
            appInfo: {
              contentConf: "appActiveTrendConf",
              appActiveTopConf: {
                pieChart: false,
                ringMoreChart: false,
                rosesChart: false,
                topChart: false,
              },
              appDangerTopConf: {
                pieChart: false,
                ringMoreChart: false,
                rosesChart: false,
                topChart: false,
              },
              appActiveTrendConf: {
                lineChart: true,
                topChart: false,
              },
              appDangerTrendConf: {
                lineChart: false,
                topChart: false,
              },
              appUseTimeAvgConf: {
                topChart: false,
              },
              appInstallTopConf: {
                //应用安装top5
                topChart: false,
              },
            },
            deviceInfo: {
              contentConf: "deviceActiveTrendConf",
              deviceActiveTrendConf: {
                lineChart: false,
                topChart: false,
              },
              violaBehaviorTrendConf: {
                lineChart: false,
                topChart: false,
              },
              violaBehaviorDistributeConf: {
                pieChart: false,
                ringMoreChart: false,
              },
              newJoinDeviceConf: {
                lineChart: false,
              },
              systemVersionConf: {
                //系统版本
                topChart: false,
              },//613新设备占比
              newDeviceRateConf:{
                ringMoreChart: false, //环形图
                pieChart: false, //饼图
                rosesChart: false //玫瑰图
              },
              deviceActiveRateConf:{//613终端活跃度
                ringMoreChart: false, //环形图
                pieChart: false, //饼图
                rosesChart: false //玫瑰图
              }
            },
            userInfo: {
              contentConf: "useAppTopConf",
              userActiveTrendConf: {
                lineChart: false,
                topChart: false,
              },
              useAppTopConf: {
                pieChart: false,
                ringMoreChart: false,
                rosesChart: false,
                topChart: false,
              },
              sensitiveWordsConf: {
                cloudChart: false,
                ringMoreChart: false,
                barTuChart: false,
              },
              noNetworkAccessConf: {
                barTuChart: false,
              },
              userActiveRateConf:{//613用户活跃度
                ringMoreChart: false, //环形图
                pieChart: false, //饼图
                rosesChart: false //玫瑰图
              }
            },
            gatewayInfo: {
              userAccessTopConf: {
                topChart: false,
              },
              deviceAccessTopConf: {
                topChart: false,
              },
              appAccessTopConf: {
                topChart: false,
              },
              sourceAccessTopConf: {
                topChart: false,
              },
              accessResultConf: {
                lineChart: false,
                topChart: false,
              },
              gatewayThroughputConf: {
                lineChart: false,
                topChart: false,
              },
              appFlowTopConf: {					
                topChart: false
              },
              devFlowTopConf: {					
                topChart: false
              },
              appFlowRateTopConf: {				
                topChart: false
              },
              devFlowRateTopConf: {				
                topChart: false
              },
              netDelayTrendConf: {				
                lineChart: false,
                topChart: false
              },
            },
          },
          chartInfoLeft_2: {
            showConf: "appInfo",
            titleName: this.$t('SecurityPosture.dispositionPage.TheChart.chartInfoLeft_2'),
            appInfo: {
              contentConf: "appActiveTopConf",
              appActiveTopConf: {
                pieChart: true,
                ringMoreChart: false,
                rosesChart: false,
                topChart: false,
              },
              appDangerTopConf: {
                pieChart: false,
                ringMoreChart: false,
                rosesChart: false,
                topChart: false,
              },
              appActiveTrendConf: {
                lineChart: false,
                topChart: false,
              },
              appDangerTrendConf: {
                lineChart: false,
                topChart: false,
              },
              appUseTimeAvgConf: {
                topChart: false,
              },
              appInstallTopConf: {
                //应用安装top5
                topChart: false,
              },
            },
            deviceInfo: {
              contentConf: "deviceActiveTrendConf",
              deviceActiveTrendConf: {
                lineChart: false,
                topChart: false,
              },
              violaBehaviorTrendConf: {
                lineChart: false,
                topChart: false,
              },
              violaBehaviorDistributeConf: {
                pieChart: false,
                ringMoreChart: false,
              },
              newJoinDeviceConf: {
                lineChart: false,
              },
              systemVersionConf: {
                //系统版本
                topChart: false,
              },
              newDeviceRateConf:{
                ringMoreChart: false, //环形图
                pieChart: false, //饼图
                rosesChart: false //玫瑰图
              },
              deviceActiveRateConf:{//613终端活跃度
                ringMoreChart: false, //环形图
                pieChart: false, //饼图
                rosesChart: false //玫瑰图
              }
            },
            userInfo: {
              contentConf: "useAppTopConf",
              userActiveTrendConf: {
                lineChart: false,
                topChart: false,
              },
              useAppTopConf: {
                pieChart: false,
                ringMoreChart: false,
                rosesChart: false,
                topChart: false,
              },
              sensitiveWordsConf: {
                cloudChart: false,
                ringMoreChart: false,
                barTuChart: false,
              },
              noNetworkAccessConf: {
                barTuChart: false,
              },
              userActiveRateConf:{//613用户活跃度
                ringMoreChart: false, //环形图
                pieChart: false, //饼图
                rosesChart: false //玫瑰图
              }
            },
            gatewayInfo: {
              userAccessTopConf: {
                topChart: false,
              },
              deviceAccessTopConf: {
                topChart: false,
              },
              appAccessTopConf: {
                topChart: false,
              },
              sourceAccessTopConf: {
                topChart: false,
              },
              accessResultConf: {
                lineChart: false,
                topChart: false,
              },
              gatewayThroughputConf: {
                lineChart: false,
                topChart: false,
              },
              appFlowTopConf: {					
                topChart: false
              },
              devFlowTopConf: {					
                topChart: false
              },
              appFlowRateTopConf: {				
                topChart: false
              },
              devFlowRateTopConf: {				
                topChart: false
              },
              netDelayTrendConf: {				
                lineChart: false,
                topChart: false
              },
            },
          },
          chartInfoRight_1: {
            showConf: "appInfo",
            titleName: this.$t('SecurityPosture.dispositionPage.TheChart.chartInfoRight_1'),
            appInfo: {
              contentConf: "appDangerTrendConf",
              appActiveTopConf: {
                pieChart: false,
                ringMoreChart: false,
                rosesChart: false,
                topChart: false,
              },
              appDangerTopConf: {
                pieChart: false,
                ringMoreChart: false,
                rosesChart: false,
                topChart: false,
              },
              appActiveTrendConf: {
                lineChart: false,
                topChart: false,
              },
              appDangerTrendConf: {
                lineChart: false,
                topChart: true,
              },
              appUseTimeAvgConf: {
                topChart: false,
              },
              appInstallTopConf: {
                //应用安装top5
                topChart: false,
              },
            },
            deviceInfo: {
              contentConf: "deviceActiveTrendConf",
              deviceActiveTrendConf: {
                lineChart: false,
                topChart: false,
              },
              violaBehaviorTrendConf: {
                lineChart: false,
                topChart: false,
              },
              violaBehaviorDistributeConf: {
                pieChart: false,
                ringMoreChart: false,
              },
              newJoinDeviceConf: {
                lineChart: false,
              },
              systemVersionConf: {
                //系统版本
                topChart: false,
              },
              newDeviceRateConf:{
                ringMoreChart: false, //环形图
                pieChart: false, //饼图
                rosesChart: false //玫瑰图
              },
              deviceActiveRateConf:{//613终端活跃度
                ringMoreChart: false, //环形图
                pieChart: false, //饼图
                rosesChart: false //玫瑰图
              }
            },
            userInfo: {
              contentConf: "useAppTopConf",
              userActiveTrendConf: {
                lineChart: false,
                topChart: false,
              },
              useAppTopConf: {
                pieChart: false,
                ringMoreChart: false,
                rosesChart: false,
                topChart: false,
              },
              sensitiveWordsConf: {
                cloudChart: false,
                ringMoreChart: false,
                barTuChart: false,
              },
              noNetworkAccessConf: {
                barTuChart: false,
              },
              userActiveRateConf:{//613用户活跃度
                ringMoreChart: false, //环形图
                pieChart: false, //饼图
                rosesChart: false //玫瑰图
              }
            },
            gatewayInfo: {
              userAccessTopConf: {
                topChart: false,
              },
              deviceAccessTopConf: {
                topChart: false,
              },
              appAccessTopConf: {
                topChart: false,
              },
              sourceAccessTopConf: {
                topChart: false,
              },
              accessResultConf: {
                lineChart: false,
                topChart: false,
              },
              gatewayThroughputConf: {
                lineChart: false,
                topChart: false,
              },
              appFlowTopConf: {					
                topChart: false
              },
              devFlowTopConf: {					
                topChart: false
              },
              appFlowRateTopConf: {				
                topChart: false
              },
              devFlowRateTopConf: {				
                topChart: false
              },
              netDelayTrendConf: {				
                lineChart: false,
                topChart: false
              },
            },
          },
          chartInfoRight_2: {
            showConf: "appInfo",
            titleName: this.$t('SecurityPosture.dispositionPage.TheChart.chartInfoRight_2'),
            appInfo: {
              contentConf: "appDangerTopConf",
              appActiveTopConf: {
                pieChart: false,
                ringMoreChart: false,
                rosesChart: false,
                topChart: false,
              },
              appDangerTopConf: {
                pieChart: false,
                ringMoreChart: false,
                rosesChart: true,
                topChart: false,
              },
              appActiveTrendConf: {
                lineChart: false,
                topChart: false,
              },
              appDangerTrendConf: {
                lineChart: false,
                topChart: false,
              },
              appUseTimeAvgConf: {
                topChart: false,
              },
              appInstallTopConf: {
                //应用安装top5
                topChart: false,
              },
            },
            deviceInfo: {
              contentConf: "deviceActiveTrendConf",
              deviceActiveTrendConf: {
                lineChart: false,
                topChart: false,
              },
              violaBehaviorTrendConf: {
                lineChart: false,
                topChart: false,
              },
              violaBehaviorDistributeConf: {
                pieChart: false,
                ringMoreChart: false,
              },
              newJoinDeviceConf: {
                lineChart: false,
              },
              systemVersionConf: {
                //系统版本
                topChart: false,
              },
              newDeviceRateConf:{
                ringMoreChart: false, //环形图
                pieChart: false, //饼图
                rosesChart: false //玫瑰图
              },
              deviceActiveRateConf:{//613终端活跃度
                ringMoreChart: false, //环形图
                pieChart: false, //饼图
                rosesChart: false //玫瑰图
              }
            },
            userInfo: {
              contentConf: "useAppTopConf",
              userActiveTrendConf: {
                lineChart: false,
                topChart: false,
              },
              useAppTopConf: {
                pieChart: false,
                ringMoreChart: false,
                rosesChart: false,
                topChart: false,
              },
              sensitiveWordsConf: {
                cloudChart: false,
                ringMoreChart: false,
                barTuChart: false,
              },
              noNetworkAccessConf: {
                barTuChart: false,
              },
              userActiveRateConf:{//613用户活跃度
                ringMoreChart: false, //环形图
                pieChart: false, //饼图
                rosesChart: false //玫瑰图
              }
            },
            gatewayInfo: {
              userAccessTopConf: {
                topChart: false,
              },
              deviceAccessTopConf: {
                topChart: false,
              },
              appAccessTopConf: {
                topChart: false,
              },
              sourceAccessTopConf: {
                topChart: false,
              },
              accessResultConf: {
                lineChart: false,
                topChart: false,
              },
              gatewayThroughputConf: {
                lineChart: false,
                topChart: false,
              },
              appFlowTopConf: {					
                topChart: false
              },
              devFlowTopConf: {					
                topChart: false
              },
              appFlowRateTopConf: {				
                topChart: false
              },
              devFlowRateTopConf: {				
                topChart: false
              },
              netDelayTrendConf: {				
                lineChart: false,
                topChart: false
              },
            },
          },
        },
        tableInfo: {
          titleName: this.$t('SecurityPosture.dispositionPage.tableList.titleName'),
          showConf: "1",
        },
        mapInfo: {
          showConf: "appInfo",
          appInfo: {
            alarmInfoConf: true,
          },
          deviceInfo: {
            violaInfoConf: false,
          },
          userInfo: {
            drawInfoConf: false,
          },
        },
        titleInfo: {
          titleName: this.$t('SecurityPosture.dispositionPage.headerTitle.titleName'),
        },
      },
      form: {
        name: "",
      },
      rules: {
        name: [
          { required: true, message: "标题不能为空", trigger: "blur" },
          { validator: validcodeName, trigger: "blur" },
          {
            min: 1,
            max: 14,
            message: "支持输入14个字符，不能包含特殊字符及空格",
            trigger: "blur",
          },
          // {
          //   pattern: /^[0-9a-zA-Z_]{1,}$/,
          //   message: "用户名只支持英文（大小写）、数字或下划线组成",
          //   trigger: "blur",
          // },
        ],
      },

      //--------------------------------------------------------------------------------------------------------------------------------------
      // 切换选则项
      switchChoose: {}, //子页面数据接受 《应用活跃趋势》
      switchThreat: "", //子页面数据接受 《应用威胁趋势》
      switchActiveTop5: "", //子页面数据接受 《应用活跃TOP5》
      switchThreatTop5: "", //子页面数据接受 《应用威胁TOP5》
      // 查询后  默认的配置
      checkConfiguration: {
        allData: null,
      },
      appInfos: null, //当查询为null  的时候默认配置项
    };
  },

  created() {
    setTimeout(() => {
      this.tableList(); //列表查询
      this.CardInterface(); //卡片借口
      this.mapChartsData(); //地图接口
      this.chartsData(); //图表接口
      // this.installApp()//应用安装Top5接口
    }, 1000);
  },
  mounted() {
    const loading = this.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    setTimeout(() => {
      loading.close();
    }, 2000);
    // 页面加载完后显示当前时间
    this.dealWithTime(new Date());
    this.installApp(); //应用安装Top5接口
    // this.chartsData() //图表接口
    // 定时刷新时间
    this.timer = setInterval(() => {
      this.dealWithTime(new Date()); // 修改数据date
    }, 1000);
  },
  methods: {
    // 配置查询

    // 列表
    async tableList() {
      var param = {
        // json
      };
      const res = await this.$axios.post(
        "/httpServe/securityPageConfig/selectConfig",
        param,
        true
      );

      if (res.data != null) {
        if(this.$refs.tablesRef) {
          this.$refs.tablesRef.child(JSON.parse(res.data));
        }
        this.pageContent = JSON.parse(res.data);
        console.log(this.pageContent, "-----本地模板数据");
      }
    },
    // 编辑 配置项
    async edit() {
      var pageContent = this.pageContent;
      // console.log(JSON.stringify(pageContent), "===========");
      var param = {
        pageContent,
      };
      const res = await this.$axios.post(
        "/httpServe/securityPageConfig/update",
        param,
        true
      );
      console.log(JSON.stringify(res), "0000000000000");
    },
    //地图回传
    chinaReturnData(returnData) {
      this.pageContent.mapInfo = returnData.saveData;
    },
    // 卡片接口
    async CardInterface() {
      var param = {
        timeType: "3",
      };
      const res = await this.$axios.post(
        "/httpServe/securitySituation/getCardDataInfo",
        param,
        true
      );
      this.cardDataSum = res.data; //应用 卡片数据
    },
    async installApp() {
      var param = {
        timeType: "3", //0-当天 1-7天 2-30天 3-90天
        // reqType: "gatewayThroughput", //请求参数 gatewayThroughput-网关吞吐量数据 appInstall-应用安装数据 accessResult-网关业务访问趋势数据
      };
      const res = await this.$axios.post(
        "/httpServe/securitySituation/getOtherDataInfo",
        param,
        true
      );
      this.appInstalls = res.data;
   
    },
    //图形数据接口
    async chartsData() {
      var param = {
        timeType: "3",
      };
      const res = await this.$axios.post(
        "/httpServe/securitySituation/getChartDataInfo",
        param,
        true
      );
      this.echartData = res.data; //应用
    },

    //地图数据接口
    async mapChartsData() {
      var param = {
        timeType: "3",
      };
      const res = await this.$axios.post(
        "/httpServe/securitySituation/getMapDataInfo",
        param,
        true
      );
        if(res.data == null) {
        this.mapData = {
          appInfo: {
              alarmInfo: []
          },
          deviceInfo: {
              violaInfo:[]
          },
          userInfo: {
              name: [],
              num: []
          }
      }
      }else {
        this.mapData = res.data;
      }
       //图表  图表数据
    },
    // 完成
    complete() {
      this.$confirm("确定保存配置？", "提示信息", {
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        customClass: "persdsd",
        type: "warning",
      })
        .then(() => {
          this.edit();
          this.tableList();
          this.$router.push("/situational");

          // this.$router.go(0)
        })
        .catch(() => {
          this.$router.push("/situational");
          // this.$confirm('当前配置未保存，请确认后退出当前页面', '提示信息', {
          //   confirmButtonText: '确定',
          //   showCancelButton: false,
          //   type: 'warning'
          // }).then(() => {
          //   this.$router.go(-1)
          // })

          return false;
        });
    },
    //table 表格（）；
    tableTitle(returnData) {
      this.pageContent.tableInfo = returnData.saveData;
    },

    // 左1--（卡片）
    card1ReturnData(returnData) {
      // 记录当前回显的值
      this.pageContent.cardInfo.cardInfoLeft_1 = returnData.saveData;
    },
    // 左2--（卡片）
    card2ReturnData(returnData) {
      // 记录当前回显的值
      //this.chartTitleConfig.card_2 = returnData.titleName;
      // 覆盖 this.pageContent 数据
      this.pageContent.cardInfo.cardInfoLeft_2 = returnData.saveData;
    },
    // 右1--（卡片）
    card3ReturnData(returnData) {
      // 记录当前回显的值
      //this.chartTitleConfig.card_3 = returnData.titleName;
      // 覆盖 this.pageContent 数据
      this.pageContent.cardInfo.cardInfoRight_1 = returnData.saveData;
    },
    // 右2--（卡片）
    card4ReturnData(returnData) {
      // 记录当前回显的值
      // this.chartTitleConfig.card_4 = returnData.titleName;
      // 覆盖 this.pageContent 数据
      this.pageContent.cardInfo.cardInfoRight_2 = returnData.saveData;
    },
    // 左---1（图表）
    leftCharts1ReturnData(returnData) {
      this.pageContent.chartInfo.chartInfoLeft_1 = returnData.saveData;
    },
    // 左---2（图表）
    leftCharts2ReturnData(returnData) {
      this.pageContent.chartInfo.chartInfoLeft_2 = returnData.saveData;
    },
    // 右---1（图表）
    rightCharts1ReturnData(returnData) {
      this.pageContent.chartInfo.chartInfoRight_1 = returnData.saveData;
    },
    // 右---2（图表）
    rightCharts2ReturnData(returnData) {
      this.pageContent.chartInfo.chartInfoRight_2 = returnData.saveData;
    },

    //---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    goBack() {
      this.$confirm("当前配置未保存，请确认后退出当前页面", "提示信息", {
        confirmButtonText: "确定",
        showCancelButton: false,
        type: "warning",
      }).then(() => {
        // 强制刷新
        this.$router.go();
        // this.$router.push("/SourceHome");
      });
    },
    dealWithTime(data) {
      // 获取当前时间
      let formatDateTime;
      let Y = data.getFullYear();
      let M = data.getMonth() + 1;
      let D = data.getDate();
      let H = data.getHours();
      let Min = data.getMinutes();
      let S = data.getSeconds();
      let W = data.getDay();
      H = H < 10 ? "0" + H : H;
      Min = Min < 10 ? "0" + Min : Min;
      S = S < 10 ? "0" + S : S;
      switch (W) {
        case 0:
          W = "日";
          break;
        case 1:
          W = "一";
          break;
        case 2:
          W = "二";
          break;
        case 3:
          W = "三";
          break;
        case 4:
          W = "四";
          break;
        case 5:
          W = "五";
          break;
        case 6:
          W = "六";
          break;
        default:
          break;
      }
      this.nowDate = Y + "-" + M + "-" + D + "          ";
      this.nowWeek = "星期" + W;
      this.nowTime = H + ":" + Min + ":" + S + "          ";
      // this.wholeTime = this.nowDate + this.nowTime + this.nowWeek;
      // formatDateTime = Y + "年" + M + "月" + D + "日 " + " 周" +W + H + ":" + Min + ":" + S;
    },
    // 弹框点击关闭事件
    handleClose() {
      this.statusVisible = false; // 卡片设置弹框关闭
      this.form.name = "";
      this.isibaelFlag = false; //信息弹框关闭
    },
    //确定的关闭
    determine(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.pageContent.titleInfo.titleName = this.form.name;
          this.statusVisible = false; //卡片设置弹框关闭
          this.form.name = "";
        } else {
          return false;
        }
      });
    },
    // 设置卡片弹框
    setUp() {
      this.statusVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
//1920分辨率
@media screen and (min-width: 1920px) and (max-width: 2040px) {
  // 表格占比
  .tableStyle {
    width: 100%;
    height: 28%;
    margin-top: 2%;
  }
  //  标头  左边占比
  .zuo {
    width: 24%;
    margin-top: 1.5%;
    margin-left: 0%;
  }
  //  标头  中间占比
  .zhong {
    width: 35%;
    text-align: center;
    font-size: 32px;
  }
  //  标头  右边占比
  .right {
    width: 25%;
    margin-top: 1.5%;
  }
}
//1680分辨率
@media screen and (min-width: 1680px) and (max-width: 1919px) {
  // 表格占比
  ::v-deep .tableStyle {
    width: 100%;
    height: 28%;
    margin-top: 2%;
    z-index: 9;
  }
  //  标头  左边占比
  .zuo {
    width: 22%;
    margin-top: 1.5%;
    margin-left: 0%;
  }
  //  标头  中间占比
  .zhong {
    width: 43%;
    text-align: center;
    font-size: 32px;
  }
  //  标头  右边占比
  .right {
    width: 25%;
    margin-top: 1.5%;
  }
}
//2560分辨率
@media screen and (min-width: 2048px) and (max-width: 2560px) {
  // 表格占比
  ::v-deep .tableStyle {
    width: 100%;
    height: 28%;
    margin-top: 2%;
    z-index: 9;
  }
  //  标头  左边占比
  .zuo {
    width: 22%;
    margin-top: 1.5%;
    margin-left: 0%;
  }
  //  标头  中间占比
  .zhong {
    width: 35%;
    text-align: center;
    font-size: 42px;
  }
  //  标头  右边占比
  .right {
    width: 25%;
    margin-top: 1.5%;
  }
}
::v-deep .active {
  // background: #fd7522;
  // border-bottom: 3px solid rgb(30, 137, 229) !important;
  color: #08f2f0;
  // font-size: 20px;

  // font-weight:900;
}
::v-deep .el-row .el-col-24 .el-col {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
  box-sizing: border-box;
}

::v-deep .el-row {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

::v-deep .el-table th > .cell {
  color: #01b9cd;
}
.zhong {
  letter-spacing: 5px;
  font-family: "Microsoft YaHei", 微软雅黑, "Microsoft JhengHei", 华文细黑, STHeiti,
    MingLiu;

  // 左右渐变
  //  background-image: linear-gradient(to right, orange, purple);
  //     -webkit-background-clip: text;
  //     color: transparent;
  // 冲上倒下
  background-image: linear-gradient(#2de2ff, #8b67fb);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
.beijing {
  width: 100%;
  height: 700px;
  background: url("../assets/line.gif"),
    //背景图
    rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

// 齿轮设置
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.taishi {
  width: 100%;
  height: 100%; // calc(100vh - 70px)
  background: url("../assets/bg.png"),
    //背景图
    rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: auto;
  min-width: 100%;
}

.sevurbox {
  width: 100%;
  height: 88%;
  display: flex;
  justify-content: space-around;

  .left1 {
    width: 28%;
    height: 100%;
  }

  .center1 {
    width: 44%;
    height: 100%;
  }

  .right1 {
    width: 28%;
    height: 100%;
  }
}
</style>
