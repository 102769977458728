<template>
  <div style="width: 100%; height: 100%">
    <div style="width: 100%; height: 100%; position: relative">
      <svg
        class="icon"
        aria-hidden="true"
        @click="setUp"
        style="
          position: absolute;
          left: 94%;
          top: 13%;
          cursor: pointer;
          z-index: 2;
        "
      >
        <use xlink:href="#icon-shezhi-"></use>
      </svg>
      <div class="location" v-show="flag">
        <ul
          v-for="item in list"
          :key="item"
          @click="changeData(item)"
          class="aside-name-active"
          :class="{ active: active == item }"
          style="width: 100px; display: flex; height: 30px; cursor: pointer"
        >
          <li style="display: inline; width: 100px">
            <span style="font-size: 12px; font-weight: 900; color: #1cd1a1"
              ><b>一</b>&nbsp;</span
            >{{ item }}
          </li>
        </ul>
      </div>
      <div class="location2" v-show="According">
        <ul
          v-for="item in threatSwitch"
          :key="item"
          @click="changeData2(item)"
          class="aside-name-active"
          :class="{ active: theDefault == item }"
          style="width: 100px; display: flex; height: 30px; cursor: pointer"
        >
          <li style="display: inline; width: 100px">
            <span style="font-size: 12px; font-weight: 900; color: #1cd1a1"
              ><b>一</b>&nbsp;</span
            >{{ item }}
          </li>
        </ul>
      </div>
      <!-- 应用安装top5 -->
      <div
        class="appInstallBox"
        style="
          position: absolute;
          left: 15%;
          top: 20%;
          cursor: pointer;
          z-index: 2;
          color: aliceblue;
          display: flex;
        "
        v-if="appInstallFlag"
      >
        <el-select
          @change="changeData3(valueAppTop5)"
          style="width: 110px"
          v-model="valueAppTop5"
          :popper-append-to-body="false"
          placeholder="无数据"
        >
          <el-option
            v-for="item in appInstall"
            :key="item.valueAppTop5"
            :label="item.label"
            :value="item.valueAppTop5"
          >
          </el-option>
        </el-select>
      </div>
      <div
        style="
          position: absolute;
          left: 24%;
          top: 20%;
          cursor: pointer;
          z-index: 2;
          color: aliceblue;
          display: flex;
          text-align: center
        "
        v-show="sysVersionFlag"
      >
        <ul
          v-for="item in sysVersion"
          :key="item"
          @click="changeData4(item)"
          class="aside-name-active"
          :class="{ active: sysActive == item }"
          style="width: 100px; display: flex; height: 30px; cursor: pointer"
        >
          <li style="display: inline; width: 100px">
            <span style="font-size: 12px; font-weight: 900; color: #1cd1a1"
              ><b>一</b>&nbsp;</span
            >{{ item }}
          </li>
        </ul>
      </div>
      <!-- 业务访问趋势 -->
      <div
        style="
          position: absolute;
          left: 31%;
          top: 20%;
          cursor: pointer;
          z-index: 2;
          color: aliceblue;
          display: flex;
        "
        v-show="businessFlag"
      >
        <ul
          v-for="item in businessData"
          :key="item"
          @click="changeData5(item)"
          class="aside-name-active"
          :class="{ active: businessActive == item }"
          style="width: 100px; display: flex; height: 30px; cursor: pointer"
        >
          <li style="display: inline; width: 100px">
            <span style="font-size: 12px; font-weight: 900; color: #1cd1a1"
              ><b>一</b>&nbsp;</span
            >{{ item }}
          </li>
        </ul>
      </div>
      <!-- 应用安装top5下拉框 -->
      <div
        class="appInstallBox"
        style="
          position: absolute;
          left: 73%;
          top: 19%;
          cursor: pointer;
          z-index: 2;
          color: aliceblue;
          display: flex;
        "
        v-show="selectFlag"
      >
        <el-select
          @change="seleChangeAppInstallTop"
          style="width: 110px"
          v-model="value"
          :popper-append-to-body="false"
          placeholder="无数据"
        >
          <el-option
            v-for="item in selectInstallAppData"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 业务访问下拉选 -->
      <div
        class="appInstallBox"
        style="
          position: absolute;
          left: 73%;
          top: 19%;
          cursor: pointer;
          z-index: 2;
          color: aliceblue;
          display: flex;
        "
        v-show="businesSelect"
      >
        <el-select
          @change="search"
          style="width: 110px"
          v-model="valueBusiness"
          :popper-append-to-body="false"
          placeholder="无数据"
        >
          <el-option
            v-for="item in selectBusinessData"
            :key="item.valueBusiness"
            :label="item.label"
            :value="item.valueBusiness"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 网关吞吐量下拉选 -->
      <div
        class="appInstallBox"
        style="
          position: absolute;
          left: 73%;
          top: 19%;
          cursor: pointer;
          z-index: 2;
          color: aliceblue;
          display: flex;
        "
        v-show="gatewaySelect"
      >
        <el-select
          @change="SelectGatewayDataChange"
          style="width: 110px"
          v-model="valueGateway"
          :popper-append-to-body="false"
          placeholder="无数据"
        >
          <el-option
            v-for="item in selectGatewayData"
            :key="item.valueGateway"
            :label="item.label"
            :value="item.valueGateway"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 应用流量TOP5  上行流量/下行流量切换 -->
      <div class="location" v-show="appFlowTopFlag">
        <ul
          v-for="item in flowList"
          :key="item"
          @click="changeData7(item)"
          class="aside-name-active"
          :class="{ active: flowActive == item }"
          style="width: 100px; display: flex; height: 30px; cursor: pointer"
        >
          <li style="display: inline; width: 100px">
            <span style="font-size: 12px; font-weight: 900; color: #1cd1a1"
              ><b>一</b>&nbsp;</span
            >{{ item }}
          </li>
        </ul>
      </div>
      <!-- 应用流量TOP5下拉选 -->
      <div
        class="appInstallBox"
        style="
          position: absolute;
          left: 73%;
          top: 19%;
          cursor: pointer;
          z-index: 2;
          color: aliceblue;
          display: flex;
        "
        v-show="flowTopSelect"
      >
        <el-select
          @change="selectChangeData7"
          style="width: 110px"
          v-model="flowTopValue"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="item in selectFlowTopData"
            :key="item.flowTopValue"
            :label="item.label"
            :value="item.flowTopValue"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 设备流量TOP5  上行流量/下行流量切换 -->
      <div class="location" v-show="devFlowFlag">
        <ul
          v-for="item in flowList"
          :key="item"
          @click="changeData8(item)"
          class="aside-name-active"
          :class="{ active: devFlowActive == item }"
          style="width: 100px; display: flex; height: 30px; cursor: pointer"
        >
          <li style="display: inline; width: 100px">
            <span style="font-size: 12px; font-weight: 900; color: #1cd1a1"
              ><b>一</b>&nbsp;</span
            >{{ item }}
          </li>
        </ul>
      </div>
      <!-- 设备流量TOP5下拉选 -->
      <div
        class="appInstallBox"
        style="
          position: absolute;
          left: 73%;
          top: 19%;
          cursor: pointer;
          z-index: 2;
          color: aliceblue;
          display: flex;
        "
        v-show="devTopSelect"
      >
        <el-select
          @change="selectChangeData8"
          style="width: 110px"
          v-model="DevflowValue"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="item in selectDevFlowData"
            :key="item.DevflowValue"
            :label="item.label"
            :value="item.DevflowValue"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 应用流速TOP5  上行流速/下行流速切换  -->
      <div class="location" v-show="appFlowRateFlag">
        <ul
          v-for="item in flowRateList"
          :key="item"
          @click="changeData9(item)"
          class="aside-name-active"
          :class="{ active: appFlowRateActive == item }"
          style="width: 100px; display: flex; height: 30px; cursor: pointer"
        >
          <li style="display: inline; width: 100px">
            <span style="font-size: 12px; font-weight: 900; color: #1cd1a1"
              ><b>一</b>&nbsp;</span
            >{{ item }}
          </li>
        </ul>
      </div>
      <!-- 应用流速TOP5下拉选 -->
      <div
        class="appInstallBox"
        style="
          position: absolute;
          left: 73%;
          top: 19%;
          cursor: pointer;
          z-index: 2;
          color: aliceblue;
          display: flex;
        "
        v-show="appFlowRateSelect"
      >
        <el-select
          @change="selectChangeData9"
          style="width: 110px"
          v-model="appFlowRateValue"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="item in selectAppFlowRateData"
            :key="item.appFlowRateValue"
            :label="item.label"
            :value="item.appFlowRateValue"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 设备流速TOP5  上行流速/下行流速切换 -->
      <div class="location" v-show="devFlowRateFlag">
        <ul
          v-for="item in flowRateList"
          :key="item"
          @click="changeData10(item)"
          class="aside-name-active"
          :class="{ active: devFlowRateActive == item }"
          style="width: 100px; display: flex; height: 30px; cursor: pointer"
        >
          <li style="display: inline; width: 100px">
            <span style="font-size: 12px; font-weight: 900; color: #1cd1a1"
              ><b>一</b>&nbsp;</span
            >{{ item }}
          </li>
        </ul>
      </div>
      <!-- 设备流速TOP5下拉选 -->
      <div
        class="appInstallBox"
        style="
          position: absolute;
          left: 73%;
          top: 19%;
          cursor: pointer;
          z-index: 2;
          color: aliceblue;
          display: flex;
        "
        v-show="devFlowRateSelect"
      >
        <el-select
          @change="selectChangeData10"
          style="width: 110px"
          v-model="devFlowRateValue"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="item in selectDevFlowRateData"
            :key="item.devFlowRateValue"
            :label="item.label"
            :value="item.devFlowRateValue"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 网络延时趋势下拉选 -->
      <div
        class="appInstallBox"
        style="
          position: absolute;
          left: 73%;
          top: 19%;
          cursor: pointer;
          z-index: 2;
          color: aliceblue;
          display: flex;
        "
        v-show="netDelaySelect"
      >
        <el-select
          @change="selectChangeData11"
          style="width: 110px"
          v-model="netDelayValue"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="item in selectNetDelayData"
            :key="item.netDelayValue"
            :label="item.label"
            :value="item.netDelayValue"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 显示图形 -->
      <div
        id="showCharts"
        ref="showCharts"
        style="width: 100%; height: 100%"
      ></div>
    </div>
    <!--卡片配置弹框 -->
    <Eldialog
      :title="$t('SecurityPosture.bounced.title')"
      :width="'460px'"
      :btnTitle="$t('SecurityPosture.bounced.btnTitle')"
      :flagbtn="true"
      :cancelBtn="true"
      :dialogVisible="statusVisible"
      @handleClose="handleClose"
      :determineBtn="true"
      :num="'270px'"
      @determine="determine"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        style="margin-top: 30px"
      >
        <el-form-item
          :label="$t('SecurityPosture.bounced.StatisticalDimension')"
        >
          <el-select
            v-model="dimensionValue"
            @change="weiDu(dimensionValue)"
            :placeholder="$t('SecurityPosture.bounced.placeholder')"
          >
            <el-option
              v-for="item in dimension"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 默认应用 -->
        <el-form-item :label="$t('SecurityPosture.bounced.StatisticalContent')">
          <el-select
            v-model="statisticalValue1"
            @change="content(statisticalValue1)"
            :placeholder="$t('SecurityPosture.bounced.placeholder')"
          >
            <el-option
              v-for="item in statistical"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('SecurityPosture.bounced.ChartType')">
          <el-select
            v-model="chartsValue"
            @change="tuBiao(chartsValue)"
            :placeholder="$t('SecurityPosture.bounced.placeholder')"
          >
            <el-option
              v-for="item in chartsTu"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </Eldialog>
  </div>
</template>

<script>
import "echarts-wordcloud/dist/echarts-wordcloud";
import "echarts-wordcloud/dist/echarts-wordcloud.min";
import * as echarts from "echarts"; //echarts图表引用
import "../../../assets/iconfont/iconfont.js"; //小齿轮设置的icon
import elementResizeDetectorMaker from "element-resize-detector"; //获取div宽高
import Eldialog from "@/components/elDialog"; //详情弹框
import moment from "moment";
import locale from "../../../langs/locale";
export default {
  props: {
    pageContent: {
      type: Object,
      default: () => {},
    },
    //图表1
    echartData: {
      type: Object,
      default: () => {},
    },
    //应用安装Top5
    appInstalls: {
      type: Object,
      default: () => {},
    },
  },
  // 组件
  components: {
    Eldialog,
  },
  data() {
    return {
      CurLangMode:'zh',//当前语言模式
      radioValue: "", //其他违规弹框单选框默认选中
      determineBtn: true,
      flagbtn: true, //违规弹框是否显示确定按钮
      otherViolaShow: false, //其他违规弹框显示
      surplusBehaviorDistribut: [], // 其他违规行为top5以外
      //第一维度选择
      firstSelect: "",
      //第二维度选择
      secondSelect: "",
      //第三维度选择
      threeSelect: "",
      //图形显示元数据
      metaEchartData: null,
      installAppIosChart: {},
      installAppAndroidChart: {},
      installAppWindowsChart: {},//Windows
      installAppLinuxChart: {},//Linux
      busResourcesErrorObj: {},
      busResourcesNormalObj: {},
      //柱状图切换值
      eventFlag: "mobileApps",
      //柱状图切换值
      runTimeFlag: "useTime",
      appOsType: "Android",
      sysOsType: "20",
      // mockDate:['2022-07-03','2022-07-04','2022-07-06'],
      // mockData:[10,20,10],
      // mockHoursDate:['02','10','23'],
      //时间组
      dateGroup: [],
      zhewx: [],
      maptime: [],
      flag: false, //切换默认不展示（使用时长）
      According: false, ////切换默认不展示（移动应用
      appInstallFlag: false, //切换默认不展示（应用安装top5 ios）
      sysVersionFlag: false, ////切换默认不展示（系统版本 ios）
      selectFlag: false, //应用安装top5 选择框
      businessFlag: false, //业务访问趋势 选择框
      businesSelect: false, //网关-业务访问趋势 选择框
      gatewaySelect: false, //网关-网关吞吐量 选择框
      appFlowTopFlag: false, ////切换默认不展示（应用流量TOP5 上行流量）
      devFlowFlag: false, ////切换默认不展示（设备流量TOP5 上行流量）
      appFlowRateFlag: false, ////切换默认不展示（应用流速TOP5 上行）
      devFlowRateFlag: false, ////切换默认不展示（设备流速TOP5 上行）
      netDelayFlag: false, ////切换默认不展示（网络延时趋势）
      flowTopSelect: false, //应用流量TOP5 选择框
      devTopSelect: false, //设备流量TOP5 选择框
      appFlowRateSelect: false, //应用流速TOP5 选择框
      devFlowRateSelect: false, //设备流速TOP5 选择框
      netDelaySelect: false, //网络延时趋势 选择框
      flowList: ["上行流量", "下行流量"], //应用流量TOP5
      flowActive: "上行流量", //应用流量TOP5 选中样式
      devFlowActive: "上行流量", //选中样式
      flowRateList: ["上行流速", "下行流速"],
      appFlowRateActive: "上行流速", //选中样式
      devFlowRateActive: "上行流速", //选中样式
      selectFlowTopData: [],//应用流量TOP5  下拉框 --接收数据
      flowTopValue: this.$t("SecurityPosture.ConfigRightTwoCharts.value"),//默认选择值 -无数据
      selectDevFlowData: [],//设备流量TOP5  下拉框 --接收数据
      DevflowValue: this.$t("SecurityPosture.ConfigRightTwoCharts.value"),//默认选择值 -无数据
      selectAppFlowRateData: [],//应用流速TOP5  下拉框 --接收数据
      appFlowRateValue: this.$t("SecurityPosture.ConfigRightTwoCharts.value"),//默认选择值 -无数据
      selectDevFlowRateData: [],//设备流速TOP5  下拉框 --接收数据
      devFlowRateValue: this.$t("SecurityPosture.ConfigRightTwoCharts.value"),//默认选择值 -无数据
      selectNetDelayData: [],//网络延时趋势  下拉框 --接收数据
      netDelayValue: this.$t("SecurityPosture.ConfigRightTwoCharts.value"),//默认选择值 -无数据
      gatewayResult: {},//网关安全
      appUpFlowData: {},//应用流量TOP5 上行
      appDownFlowData: {},//应用流量TOP5 下行
      devUpFlowData: {},//设备流量TOP5 上行
      devDownFlowData: {},//设备流量TOP5 下行
      appRateUpFlowData: {},//应用流速TOP5 上行
      appRateDownFlowData: {},//应用流速TOP5 下行
      devRateUpFlowData: {},//设备流速TOP5 上行
      devRateDownFlowData: {},//设备流速TOP5 下行
      netDelayData: {},//网络延时趋势
      flowTopType: "up_flow",//应用流量TOP5
      list: this.$t("SecurityPosture.ConfigRightTwoCharts.list"),//应用活跃To5
      active: this.$t("SecurityPosture.ConfigRightTwoCharts.active"), //选中样式
      threatSwitch: this.$t("SecurityPosture.ConfigRightTwoCharts.threatSwitch"),//应用威胁Top5
      theDefault: this.$t("SecurityPosture.ConfigRightTwoCharts.theDefault"), //选中样式
      selectInstallAppData: [], //应用安装top5 下拉框 --接收数据
      value: this.$t("SecurityPosture.ConfigRightTwoCharts.value"),//默认选择值 -无数据
      // appInstall: this.$t("SecurityPosture.ConfigRightTwoCharts.appInstall"),//应用安装TOP5新增Window统计(v6.2.0)
      appInstall:[//应用安装TOP5新增Window统计(v6.2.0)、Linux统计(v6.3.0)
        {
          valueAppTop5: "Android",
          label: "Android"
        },
        {
          valueAppTop5: "iOS",
          label: "iOS"
        },
        {
          valueAppTop5: "Windows",
          label: "Windows"
        },
        {
          valueAppTop5: "Linux",
          label: "Linux"
        }
      ],
      valueAppTop5:'Android',
      installActie: this.$t("SecurityPosture.ConfigRightTwoCharts.installActie"), //选中样式
      sysVersion: this.$t("SecurityPosture.ConfigRightTwoCharts.sysVersion"),//系统版本新增Window统计(v6.2.0)
      sysActive: this.$t("SecurityPosture.ConfigRightTwoCharts.sysActive"), //选中样式
      selectBusinessData: [],//业务访问 下拉框 --接收数据
      valueBusiness: this.$t("SecurityPosture.ConfigRightTwoCharts.value"),//默认选择值 -无数据
      selectGatewayData: [],//网关吞吐量 下拉框 --接收数据
      GatewayData: {},//网关吞吐量筛选完数据
      valueGateway: this.$t("SecurityPosture.ConfigRightTwoCharts.value"),//默认选择值 -无数据
      businessData: this.$t("SecurityPosture.ConfigRightTwoCharts.businessData"),//业务访问去世
      businessActive: this.$t("SecurityPosture.ConfigRightTwoCharts.businessActive"),//默认选择值 -无数据
      appInfo: {}, //应用
      deviceInfo: {}, //设备
      userInfo: {}, //用户
      businessArray: {}, //业务访问资源
      gatewaythroughputObj: {}, //网关吞吐
      form: {
        region: "",
      },
      statusVisible: false, //弹框默认关闭
      dimensionContent: "1", //统计维度点击的当前选项
      statisticalContent: "1", //统计内容点击的当前选项
      //********************************************************************************************************************* */
      rightCharts_2: {}, //用来接收左边第二个的值
      leftAppIstall: null, //用来接受下拉框的值
      dimension: [
        //---循环遍历   维度
        {
          //统计维度
          value: "appInfo",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.dimension.appInfo"
          ),
        },
        {
          value: "deviceInfo",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.dimension.deviceInfo"
          ),
        },
        {
          value: "userInfo",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.dimension.userInfo"
          ),
        },
        {
          value: "gatewayInfo",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.dimension.gatewayInfo"
          ),
        },
      ],
      dimensionValue: "", //维度
      //-------------------------------------
      statistical: [], // 循环遍历 内容
      statisticalValue1: "", //内容
      //----------------------------------------内容

      //应用维度下拉选
      appInfo_statistical: [
        {
          //统计内容
          value: "appActiveTrendConf",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.appInfo_statistical.appActiveTrendConf"
          ),
        },
        {
          value: "appDangerTrendConf",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.appInfo_statistical.appDangerTrendConf"
          ),
        },
        {
          value: "appActiveTopConf",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.appInfo_statistical.appActiveTopConf"
          ),
        },
        {
          value: "appDangerTopConf",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.appInfo_statistical.appDangerTopConf"
          ),
        },
        {
          value: "appUseTimeAvgConf",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.appInfo_statistical.appUseTimeAvgConf"
          ),
        },
        {
          value: "appInstallTopConf",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.appInfo_statistical.appInstallTopConf"
          ),
        },
      ],
      //设备维度下拉选
      deviceInfo_statistical: [
        {
          value: "deviceActiveTrendConf",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.deviceInfo_statistical.deviceActiveTrendConf"
          ),
        },
        {
          value: "violaBehaviorTrendConf",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.deviceInfo_statistical.violaBehaviorTrendConf"
          ),
        },
        {
          value: "violaBehaviorDistributeConf",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.deviceInfo_statistical.violaBehaviorDistributeConf"
          ),
        },
        {
          value: "newJoinDeviceConf",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.deviceInfo_statistical.newJoinDeviceConf"
          ),
        },
        {
          value: "systemVersionConf",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.deviceInfo_statistical.systemVersionConf"
          ),
        },
        {
          //613新设备占比
          value: "newDeviceRateConf",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.deviceInfo_statistical.newDeviceRate"
          ),
        },
        {
          //613终端活跃度
          value: "deviceActiveRateConf",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.deviceInfo_statistical.deviceActiveRate"
          ),
        },
      ],
      //用户维度下拉选
      userInfo_statistical: [
        {
          value: "userActiveTrendConf",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.userInfo_statistical.userActiveTrendConf"
          ),
        },
        {
          value: "useAppTopConf",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.userInfo_statistical.useAppTopConf"
          ),
        },
        {
          value: "sensitiveWordsConf",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.userInfo_statistical.sensitiveWordsConf"
          ),
        },
        {
          value: "noNetworkAccessConf",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.userInfo_statistical.noNetworkAccessConf"
          ),
        },
        {
          value: "userActiveRateConf",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.userInfo_statistical.userActiveRateConf"
          ),
        },
      ],
      //网关维度下拉选
      gatewayInfo_statistical: [
        {
          value: "userAccessTopConf",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.gatewayInfo_statistical.userAccessTopConf"
          ),
        },
        {
          value: "deviceAccessTopConf",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.gatewayInfo_statistical.deviceAccessTopConf"
          ),
        },
        {
          value: "appAccessTopConf",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.gatewayInfo_statistical.appAccessTopConf"
          ),
        },
        {
          value: "sourceAccessTopConf",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.gatewayInfo_statistical.sourceAccessTopConf"
          ),
        },
        {
          value: "accessResultConf",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.gatewayInfo_statistical.accessResultConf"
          ),
        },
        {
          value: "gatewayThroughputConf",
          label: this.$t(
            "SecurityPosture.ConfigRightTwoCharts.gatewayInfo_statistical.gatewayThroughputConf"
          ),
        },
        {
          value: "appFlowTopConf",
          label: this.$t(
            "SecurityPosture.LeftOneCharts.gatewayInfo_statistical.appFlowTopConf"
          ),
        },
        {
          value: "devFlowTopConf",
          label: this.$t(
            "SecurityPosture.LeftOneCharts.gatewayInfo_statistical.devFlowTopConf"
          ),
        },
        {
          value: "appFlowRateTopConf",
          label: this.$t(
            "SecurityPosture.LeftOneCharts.gatewayInfo_statistical.appFlowRateTopConf"
          ),
        },
        {
          value: "devFlowRateTopConf",
          label: this.$t(
            "SecurityPosture.LeftOneCharts.gatewayInfo_statistical.devFlowRateTopConf"
          ),
        },
        {
          value: "netDelayTrendConf",
          label: this.$t(
            "SecurityPosture.LeftOneCharts.gatewayInfo_statistical.netDelayTrendConf"
          ),
        },
      ],
      chartsTu: [], //循环  图表的数组
      chartsValue: "", //图表
    };
  },
  watch: {
    pageContent: {
      handler: function (newVal, oldVal) {
        this.pageContent = newVal;
        this.allow();
        // this.showEchartsByData()
      },
      deep: true,
      // immediate: true,
    },
    appInstalls: {
      handler: function (newVal, oldVal) {
        this.appInstalls = newVal;
        this.allow();
        this.appInstallMeth();
      },
      deep: true,
      // immediate: true,
    },
    "$i18n.locale"(newVal,old) {
      console.log(newVal,old,'val,old');
      this.CurLangMode=newVal
      //监听语言的变化，每次变动都要重新渲染图表
      this.getViolaDis();
    },
  },
  created() {
    this.allow();
  },
  mounted() {
    this.businessArray = this.appInstalls.accessResult;
    this.gatewaythroughputObj = this.appInstalls.gatewayThroughput;
    this.gatewayResult = this.appInstalls.gatewayResult;
    if (this.metaEchartData) {
      if (this.metaEchartData.ios) {
        if (this.metaEchartData.ios.name.length > 0) {
          this.changeData3("Android");
        }
      }
      if (this.metaEchartData.name) {
        this.changeData5("正常访问");
        this.SelectGatewayDataChange("全部网关");
        this.selectChangeData7("全部网关");
        this.selectChangeData8("全部网关");
        this.selectChangeData9("全部网关");
        this.selectChangeData10("全部网关");
        this.selectChangeData11("全部网关");
      }
    }
    this.leftAppIstall = this.appInstalls.appInstallInfo;
    this.appInstallMeth();
    this.showEchartsByData(this.chartsValue);
  },
  methods: {
    appInstallMeth() {
      //应用安装top5
      if (this.installActie == "iOS") {
        if (this.metaEchartData) {
          if (
            JSON.stringify(this.leftAppIstall.ios) === "{}" ||
            JSON.stringify(this.leftAppIstall.ios) === undefined
          ) {
            this.installAppIosChart = { name: {}, num: {} };
          } else {
            if (this.metaEchartData.ios != undefined) {
              this.value =
                this.value == "无数据"
                  ? this.metaEchartData?.ios.name[0]
                  : this.value;
            }
            var array = this.leftAppIstall.ios;
            for (let key in array) {
              if (this.value === key) {
                this.installAppIosChart = array[key];
              }
            }
          }
        }
      } else if (this.installActie == "Android") {
        if (this.metaEchartData) {
          if (
            JSON.stringify(this.leftAppIstall.android) === "{}" ||
            JSON.stringify(this.leftAppIstall.android) === undefined
          ) {
            this.installAppAndroidChart = { name: {}, num: {} };
          } else {
            if (this.metaEchartData.android != undefined) {
              this.value =
                this.value == "无数据"
                  ? this.metaEchartData?.android.name[0]
                  : this.value;
            }
            var array = this.leftAppIstall.android;
            for (let key in array) {
              if (this.value === key) {
                this.installAppAndroidChart = array[key];
              }
            }
          }
        }
      } else if (this.appInstallFlag && this.installActie == "Windows") {
          if (
            JSON.stringify(this.leftAppIstall.windows) === "{}" ||
            JSON.stringify(this.leftAppIstall.windows) === undefined
          ) {
            this.installAppWindowsChart = { name: {}, num: {} };
          } else {
            let selectValue =
              this.value == "无数据"
                ? this.metaEchartData?.windows.name[0]
                : this.value;
            var array = this.leftAppIstall.windows;
            for (let key in array) {
              if (selectValue === key) {
                this.installAppWindowsChart = array[key];
              }
            }
            return false;
          }
        } else if (this.appInstallFlag && this.installActie == "Linux") {
          if (
            JSON.stringify(this.leftAppIstall.linux) === "{}" ||
            JSON.stringify(this.leftAppIstall.linux) === undefined
          ) {
            this.installAppLinuxChart = { name: {}, num: {} };
          } else {
            let selectValue =
              this.value == "无数据"
                ? this.metaEchartData?.linux.name[0]
                : this.value;
            var array = this.leftAppIstall.linux;
            for (let key in array) {
              if (selectValue === key) {
                this.installAppLinuxChart = array[key];
              }
            }
            return false;
          }
        }
      if (this.businessActive == "正常访问") {
        // console.log(this.businessArray, '业务访问资源111', this.valueBusiness)
        // console.log(this.businessFlag, 'businessFlag业务访问资源')
        // console.log(JSON.stringify(this.businessArray.normal) === undefined, "JSON.stringify(this.businessArray.normal) === '{}'")
        if (
          JSON.stringify(this.businessArray.normal) === "{}" ||
          JSON.stringify(this.businessArray.normal) === undefined
        ) {
          this.busResourcesNormalObj = { name: {}, num: {} };
        } else {
          let value = "";
          // console.log(this.valueBusiness, "this.valueBusiness")
          if (this.valueBusiness == "全部业务") {
            value = "all";
          } else {
            value = this.valueBusiness;
          }
          // var value = this.valueBusiness=="全部业务"?"all":this.valueBusiness
          var Object = this.businessArray.normal;
          for (let key in Object) {
            // console.log(value, key, "value === key")
            if (value === key) {
              // console.log(Object[key], "Object[key]")
              this.busResourcesNormalObj = Object[key];
            }
          }
          // console.log(this.busResourcesNormalObj, '------------正-this.busResourcesNormalObj---------------this.busResourcesNormalObj')
        }
      } else if (this.businessActive == "异常访问") {
        // console.log(this.businessArray, '业务访问异常资源2222222222222')
        // console.log(this.businessArray.error, "this.businessArray.error")
        // console.log(this.businessFlag, 'businessFlag业务访问资源')
        if (
          JSON.stringify(this.businessArray.error) === "{}" ||
          JSON.stringify(this.businessArray.error) === undefined
        ) {
          this.busResourcesErrorObj = { name: {}, num: {} };
        } else {
          var value =
            this.valueBusiness == "全部业务" ? "all" : this.valueBusiness;
          var Object = this.businessArray.error;
          for (let key in Object) {
            // console.log(key, "key", this.valueBusiness)
            if (value === key) {
              // console.log(Object[key], "Object.key")
              this.busResourcesErrorObj = Object[key];
            }
          }
          // console.log(this.busResourcesErrorObj, '-------------this.busResourcesErrorObj---------------this.busResourcesErrorObj')
        }
      }
      // 应用流量top5
      if (this.flowActive == "上行流量") {
        var value = this.flowTopValue == "全部网关" ? "all" : this.flowTopValue;
        // console.log(this.flowTopValue,"默认XiLinx",value);
        var Object = this.gatewayResult.flowApp.up_flow;
        // console.log(Object,"742&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&");
        for (let key in Object) {
          if (value === key) {
            // console.log(key,"Object[key]")
            this.appUpFlowData = Object[key];
          }
        }
      } else {
        var value = this.flowTopValue == "全部网关" ? "all" : this.flowTopValue;
        var Object = this.gatewayResult.flowApp.down_flow;
        for (let key in Object) {
          if (value === key) {
            // console.log(Object[key],"Object[key]")
            this.appDownFlowData = Object[key];
          }
        }
      }
      // // 设备流量top5
      if (this.devFlowActive == "上行流量") {
        var value = this.DevflowValue == "全部网关" ? "all" : this.DevflowValue;
        // console.log(this.DevflowValue,"默认XiLinx",value);
        var Object = this.gatewayResult.flowDev.up_flow;
        // console.log(Object,"742&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&");
        for (let key in Object) {
          if (value === key) {
            // console.log(key,"Object[key]")
            this.devUpFlowData = Object[key];
          }
        }
      } else {
        var value = this.DevflowValue == "全部网关" ? "all" : this.DevflowValue;
        var Object = this.gatewayResult.flowDev.down_flow;
        for (let key in Object) {
          if (value === key) {
            // console.log(Object[key],"Object[key]")
            this.devDownFlowData = Object[key];
          }
        }
      }
      // 应用流速top5
      if (this.appFlowRateActive == "上行流速") {
        var value =
          this.appFlowRateValue == "全部网关" ? "all" : this.appFlowRateValue;
        // console.log(this.appFlowRateValue,"默认XiLinx",value);
        var Object = this.gatewayResult.flowAppRate.up_flow;
        // console.log(Object,"742&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&");
        for (let key in Object) {
          if (value === key) {
            // console.log(key,"Object[key]")
            this.appRateUpFlowData = Object[key];
          }
        }
      } else {
        var value =
          this.appFlowRateValue == "全部网关" ? "all" : this.appFlowRateValue;
        var Object = this.gatewayResult.flowAppRate.down_flow;
        for (let key in Object) {
          if (value === key) {
            this.appRateDownFlowData = Object[key];
          }
        }
      }
      // // 设备流速top5
      if (this.devFlowRateActive == "上行流速") {
        var value =
          this.devFlowRateValue == "全部网关" ? "all" : this.devFlowRateValue;
        var Object = this.gatewayResult.flowDevRate.up_flow;
        for (let key in Object) {
          if (value === key) {
            this.devRateUpFlowData = Object[key];
          }
        }
      } else {
        var value =
          this.devFlowRateValue == "全部网关" ? "all" : this.devFlowRateValue;
        var Object = this.gatewayResult.flowDevRate.down_flow;
        for (let key in Object) {
          if (value === key) {
            this.devRateDownFlowData = Object[key];
          }
        }
      }
      //网关吞吐量
      if (
        this.dimensionValue == "gatewayInfo" &&
        this.statisticalValue1 == "gatewayThroughputConf"
      ) {
        var selectValue =
          this.valueGateway == "全部网关" ? "all" : this.valueGateway;
        var Object = this.gatewaythroughputObj;
        for (let key in Object) {
          if (selectValue === key) {
            this.GatewayData = Object[key];
          }
        }
        this.showEchartsByData(this.chartsValue);
      }
      //网络延时趋势
      if (
        this.dimensionValue == "gatewayInfo" &&
        this.statisticalValue1 == "netDelayTrendConf"
      ) {
        this.netDelayValue =
          this.metaEchartData.name[0] == "all"
            ? "全部网关"
            : this.metaEchartData.name[0];
        var selectValue =
          this.netDelayValue == "全部网关" ? "all" : this.netDelayValue;
        var Object = this.gatewayResult.netDelay;
        for (let key in Object) {
          if (selectValue === key) {
            this.netDelayData = Object[key];
          }
        }
      }
      this.showEchartsByData(this.chartsValue);
    },
    allow() {
      //左侧第一个图表
      this.rightCharts_2 = this.pageContent;
      this.leftAppIstall = this.appInstalls.appInstallInfo;
      this.businessArray = this.appInstalls.accessResult;
      this.gatewaythroughputObj = this.appInstalls.gatewayThroughput;
      this.gatewayResult = this.appInstalls.gatewayResult;
      // 维度
      var showConfStr = this.rightCharts_2.showConf;
      this.dimensionValue = showConfStr;
      //具体维度下显示内容
      var metaData = this.rightCharts_2[showConfStr];

      var metaData_chart = metaData.contentConf;

      //选中项
      this.statisticalValue1 = metaData_chart;
      this.According = false;
      this.flag = false;
      this.appInstallFlag = false;
      this.sysVersionFlag = false;
      this.selectFlag = false;
      this.businessFlag = false;
      this.businesSelect = false;
      this.gatewaySelect = false;
      this.appFlowTopFlag = false;
      this.devFlowFlag = false;
      this.appFlowRateFlag = false;
      this.devFlowRateFlag = false;
      this.flowTopSelect = false;
      this.devTopSelect = false;
      this.appFlowRateSelect = false;
      this.devFlowRateSelect = false;
      this.netDelaySelect = false;
      if (showConfStr === "appInfo") {
        //下拉选列表
        this.statistical = this.appInfo_statistical;
        if (this.statisticalValue1 === "appDangerTopConf") {
          this.According = true; ////切换默认不展示（移动应用）
        } else if (this.statisticalValue1 === "appActiveTopConf") {
          this.flag = true; //切换默认不展示（使用时长）
        } else if (this.statisticalValue1 === "appInstallTopConf") {
          this.appInstallFlag = true; //切换默认不展示（系统版本ios）
          this.selectFlag = true;
        }
      }
      if (showConfStr === "deviceInfo") {
        this.statistical = this.deviceInfo_statistical;
        if (this.statisticalValue1 === "systemVersionConf") {
          this.sysVersionFlag = true; ////切换默认不展示（移动应用）
        }
      }
      if (showConfStr === "userInfo") {
        this.statistical = this.userInfo_statistical;
      }
      if (showConfStr === "gatewayInfo") {
        this.statistical = this.gatewayInfo_statistical;
        if (this.statisticalValue1 === "accessResultConf") {
          this.businessFlag = true; ////切换默认不展示（移动应用）
          this.businesSelect = true;
        } else if (this.statisticalValue1 === "gatewayThroughputConf") {
          this.gatewaySelect = true;
        } else if (this.statisticalValue1 === "appFlowTopConf") {
          this.appFlowTopFlag = true;
          this.flowTopSelect = true;
        } else if (this.statisticalValue1 === "devFlowTopConf") {
          this.devFlowFlag = true;
          this.devTopSelect = true;
        } else if (this.statisticalValue1 === "appFlowRateTopConf") {
          this.appFlowRateFlag = true;
          this.appFlowRateSelect = true;
        } else if (this.statisticalValue1 === "devFlowRateTopConf") {
          this.devFlowRateFlag = true;
          this.devFlowRateSelect = true;
        } else if (this.statisticalValue1 === "netDelayTrendConf") {
          this.netDelaySelect = true;
        }
      }
      //具体维度下显示内容图表
      var finalData = metaData[metaData_chart];
      //接纳图表下拉选内容
      var chartList = [];
      for (const key in finalData) {
        if (finalData[key] == true) {
          //下拉选选中value。用来显示lable
          this.chartsValue = key;
        }
        var listMetaData = {
          value: "",
          label: "",
        };
        listMetaData.value = key;
        listMetaData.label = this.conversion(key);
        chartList.push(listMetaData);
      }
      this.chartsTu = chartList;
      //获取数据
      var showGroupData = this.echartData[showConfStr];

      this.metaEchartData =
        showGroupData[
          this.rightCharts_2[showConfStr].contentConf.split("Conf")[0]
        ];
    },
    // 悬浮框天、小时、分
    appTimeStampTop(second_time) {
      var days = second_time / 1000 / 60 / 60 / 24;
      var daysRound = Math.floor(days);
      var hours = second_time / 1000 / 60 / 60 - 24 * daysRound;
      var hoursRound = Math.floor(hours);
      var minutes =
        second_time / 1000 / 60 - 24 * 60 * daysRound - 60 * hoursRound;
      var minutesRound = Math.floor(minutes);
      var seconds = parseInt(
        second_time / 1000 -
          24 * 60 * 60 * daysRound -
          60 * 60 * hoursRound -
          60 * minutesRound
      );
      var times = daysRound + "天" + hoursRound + "小时" + minutesRound + "分";
      //  +
      // // ":" +
      // seconds +
      // '秒'
      // if (times.length > 4) {
      //             times = times.substring(0, 4) + '...' //字符串截取
      //  }
      return times;
    },
    // Y轴小时
    appTimeStampsTwoTop(second_time) {
      var days = second_time / 1000 / 60 / 60 / 24;
      var daysRound = Math.floor(days);
      var hours = second_time / 1000 / 60 / 60 - 24 * daysRound;
      var hoursRound = Math.floor(hours);
      var minutes =
        second_time / 1000 / 60 - 24 * 60 * daysRound - 60 * hoursRound;
      var minutesRound = Math.floor(minutes);
      var seconds = parseInt(
        second_time / 1000 -
          24 * 60 * 60 * daysRound -
          60 * 60 * hoursRound -
          60 * minutesRound
      );

      var times =
        // daysRound +
        // '天' +
        // ":" +
        daysRound * 24 + hoursRound + "小时";
      // +
      // // ":" +
      // minutesRound +
      // '分' +
      // // ":" +
      // seconds +
      // '秒'
      if (times.length > 4) {
        times = times.substring(0, 4) + "..."; //字符串截取
      }
      return times;
    },
    // timeStamp(120000);  120000秒，转为 ‘ 1天9小时20分0秒 ’
    // timeStamp(120000);  120000秒，转为 ‘ 1天9小时20分0秒 ’
    // timeStamp(second_time) {
    //   var days = second_time / 1000 / 60 / 60 / 24
    //   var daysRound = Math.floor(days)
    //   var hours = second_time / 1000 / 60 / 60 - 24 * daysRound
    //   var hoursRound = Math.floor(hours)
    //   var minutes =
    //     second_time / 1000 / 60 - 24 * 60 * daysRound - 60 * hoursRound
    //   var minutesRound = Math.floor(minutes)
    //   var seconds = parseInt(
    //     second_time / 1000 -
    //     24 * 60 * 60 * daysRound -
    //     60 * 60 * hoursRound -
    //     60 * minutesRound
    //   )
    //   var times =
    //     // daysRound +
    //     // '天' +
    //     // ":" +
    //     hoursRound + this.$t('SecurityPosture.hours') + minutesRound + this.$t('SecurityPosture.minutes')
    //     +
    //     // ":" +
    //     seconds +
    //     this.$t('SecurityPosture.seconds')
    //   // if (times.length > 4) {
    //   //             times = times.substring(0, 4) + '...' //字符串截取
    //   //  }
    //   return times
    // },
    timeStamp(value) {
      var result = "";
      var sencond = parseInt(value);
      //3600秒等于60分钟等于1小时
      if (sencond > 3600 * 24) {
        //表示大于一天
        var day = sencond / (3600 * 24);
        sencond = sencond % (3600 * 24); //求天数整除外剩余的秒数
        var hour = sencond / 3600; //1小时==60分钟==3600秒，所以剩余的秒数÷3600= 得到几个小时数
        result =
          parseInt(day) +
          this.$t("SecurityPosture.day") +
          parseInt(hour) +
          this.$t("SecurityPosture.hours");
      } else if (sencond > 3600) {
        //表示大于一个小时
        var hour = sencond / 3600;
        var minute = (sencond % 3600) / 60; //求小时数整除外剩余的秒数， 秒数÷60秒 = 得到几分钟
        result =
          parseInt(hour) +
          this.$t("SecurityPosture.hours") +
          parseInt(minute) +
          this.$t("SecurityPosture.minutes");
      } else if (sencond > 60) {
        //表示大于一分钟
        var minute = sencond / 60;
        var sec = sencond % 60; //求分钟数整除外剩余的秒数
        result =
          parseInt(minute) +
          this.$t("SecurityPosture.minutes") +
          parseInt(sec) +
          this.$t("SecurityPosture.seconds");
      } else {
        //不大于一天、不大于一个小时、也不大于一分钟，那就是秒数
        result = "" + parseInt(sencond) + this.$t("SecurityPosture.seconds");
      }
      return result;
    },

    timeStampsTwo(value) {
      // var days = second_time / 1000 / 60 / 60 / 24
      // var daysRound = Math.floor(days)
      // var hours = second_time / 1000 / 60 / 60 - 24 * daysRound
      // var hoursRound = Math.floor(hours)
      // var minutes =
      //   second_time / 1000 / 60 - 24 * 60 * daysRound - 60 * hoursRound
      // var minutesRound = Math.floor(minutes)
      // var seconds = parseInt(
      //   second_time / 1000 -
      //   24 * 60 * 60 * daysRound -
      //   60 * 60 * hoursRound -
      //   60 * minutesRound
      // )
      // var times =
      //   // daysRound +
      //   // '天' +
      //   // ":" +
      //   daysRound * 24 + daysRound * 24 + hoursRound + this.$t('SecurityPosture.hours')
      // // +
      // // // ":" +
      // // minutesRound +
      // // this.$t('SecurityPosture.minutes') +
      // // // ":" +
      // // seconds +
      // // this.$t('SecurityPosture.seconds')
      var result = "";
      var sencond = parseInt(value);
      //3600秒等于60分钟等于1小时
      if (sencond > 3600 * 24) {
        //表示大于一天
        var day = sencond / (3600 * 24);
        sencond = sencond % (3600 * 24); //求天数整除外剩余的秒数
        var hour = sencond / 3600; //1小时==60分钟==3600秒，所以剩余的秒数÷3600= 得到几个小时数
        result =
          parseInt(day) +
          this.$t("SecurityPosture.day") +
          parseInt(hour) +
          this.$t("SecurityPosture.hours");
      } else if (sencond > 3600) {
        //表示大于一个小时
        var hour = sencond / 3600;
        var minute = (sencond % 3600) / 60; //求小时数整除外剩余的秒数， 秒数÷60秒 = 得到几分钟
        result =
          parseInt(hour) +
          this.$t("SecurityPosture.hours") +
          parseInt(minute) +
          this.$t("SecurityPosture.minutes");
      } else if (sencond > 60) {
        //表示大于一分钟
        var minute = sencond / 60;
        var sec = sencond % 60; //求分钟数整除外剩余的秒数
        result =
          parseInt(minute) +
          this.$t("SecurityPosture.minutes") +
          parseInt(sec) +
          this.$t("SecurityPosture.seconds");
      } else {
        //不大于一天、不大于一个小时、也不大于一分钟，那就是秒数
        result = "" + parseInt(sencond) + this.$t("SecurityPosture.seconds");
      }
      if (result.length > 4) {
        result = result.substring(0, 4) + "..."; //字符串截取
      }
      return result;
    },
    //调用echarts图形显示
    showEchartsByData(chartType) {
      if (
        this.dimensionValue == "deviceInfo" &&
        this.statisticalValue1 === "deviceActiveTrendConf"
      ) {
        if (chartType === "lineChart") {
          this.twoFigure();
        } else if (chartType === "topChart") {
          this.twoTopCharts();
        }
      } else {
        if (chartType === "lineChart") {
          this.figure();
        } else if (chartType === "pieChart") {
          this.pieChartsMethods();
        } else if (chartType === "ringMoreChart") {
          this.ringMore();
        } else if (chartType === "rosesChart") {
          this.roses();
        } else if (chartType === "topChart") {
          this.tops();
        } else if (chartType === "cloudChart") {
          this.wordCloud();
        } else if (chartType === "barTuChart") {
          this.barChart();
        }
      }
    },
    conversion(typeData) {
      //中文描述
      var describe;

      switch (typeData) {
        case "pieChart":
          describe = this.$t("SecurityPosture.figure.PieChart");
          break;
        case "ringMoreChart":
          describe = this.$t("SecurityPosture.figure.RingGraph");
          break;
        case "rosesChart":
          describe = this.$t("SecurityPosture.figure.RoseDiagram");
          break;
        case "topChart":
          describe = this.$t("SecurityPosture.figure.BarChart");
          break;
        case "lineChart":
          describe = this.$t("SecurityPosture.figure.LineChart");
          break;
        case "cloudChart":
          describe = this.$t("SecurityPosture.figure.WordCloud");
          break;
        case "barTuChart":
          describe = this.$t("SecurityPosture.figure.TheBarChart");
          break;
        default:
      }

      return describe;
    },
    changeData(item) {
      if (item == "使用时长") {
        this.active = item;
        this.runTimeFlag = "useTime";
      } else if (item == "启动次数") {
        this.active = item;
        this.runTimeFlag = "runCount";
      }
      this.showEchartsByData(this.chartsValue);
    },
    changeData2(item) {
      if (item == "移动应用") {
        this.theDefault = item;
        this.eventFlag = "mobileApps";
      } else if (item == "威胁类型") {
        this.theDefault = item;
        this.eventFlag = "dangerType";
      }
      this.showEchartsByData(this.chartsValue);
    },
    //应用安装top5高亮切换
    changeData3(item) {
      if (this.metaEchartData) {
        if (item == "iOS") { 
          let option = [];
          let arr = [];
          option = this.metaEchartData.ios.name;
          option.forEach((item) => {
            arr.push({
              value: item,
              label: item,
            });
          });
          this.selectInstallAppData = [...arr];
          if (arr.length > 0) {
            this.value = arr[0].value;
          } else if (arr.length === 0) {
            this.value = "无数据";
          }
          this.installActie = item;
          this.appOsType = "iOS";
        } else if (item == "Android") {
          let option = [];
          let arr = [];
          option = this.metaEchartData.android.name;
          option.forEach((item) => {
            arr.push({
              value: item,
              label: item,
            });
          });
          this.selectInstallAppData = [...arr];
          if (arr.length > 0) {
            this.value = arr[0].value;
          } else if (arr.length === 0) {
            this.value = "无数据";
          }
          this.installActie = item;
          this.appOsType = "Android";
        } else if (item == "Windows") {//应用安装TOP5新增Window统计(v6.2.0)
          let option = [];
          let arr = [];
          option = this.metaEchartData.windows.name;
          option.forEach((item) => {
            arr.push({
              value: item,
              label: item,
            });
          });
          this.selectInstallAppData = [...arr];
          if (arr.length > 0) {
            this.value = arr[0].value;
          } else if (arr.length === 0) {
            this.value = "无数据";
          }
          this.installActie = item;
          this.appOsType = "Windows";
        } else if (item == "Linux") {//应用安装TOP5新增Linux统计(v6.3.0)
          let option = [];
          let arr = [];
          option = this.metaEchartData.linux.name;
          option.forEach((item) => {
            arr.push({
              value: item,
              label: item,
            });
          });
          this.selectInstallAppData = [...arr];
          if (arr.length > 0) {
            this.value = arr[0].value;
          } else if (arr.length === 0) {
            this.value = "无数据";
          }
          this.installActie = item;
          this.appOsType = "Linux";
        }
        this.appInstallMeth(); //下拉框
        this.showEchartsByData(this.chartsValue);
      }
    },
    //应用安装top5下拉框切换
    seleChangeAppInstallTop(item) {
      this.value = item;
      this.showEchartsByData(this.chartsValue);
    },
    changeData4(item) {
      if (item == 'iOS') {
        this.sysActive = item
        this.sysOsType = '10'
      } else if (item == 'Android') {
        this.sysActive = item
        this.sysOsType = '20'
      } else if (item == "Windows") {//系统版本新增Window统计(v6.2.0)
        this.sysActive = item;
        this.sysOsType = "30";
      } else if (item == "Linux") {
        //系统版本新增Linux统计(v6.3.0)
        this.sysActive = item;
        this.sysOsType = "40";
      }
      this.showEchartsByData(this.chartsValue);
    },
    changeData5(item) {
      if (item == "正常访问") {
        //下拉框默认展示返回数据的第一条
        this.valueBusiness =
          this.metaEchartData.name[0] == "all"
            ? "全部业务"
            : this.metaEchartData.name[0];
        this.businessActive = item;
        this.appInstallMeth(); //下拉框
      } else if (item == "异常访问") {
        this.valueBusiness =
          this.metaEchartData.name[0] == "all"
            ? "全部业务"
            : this.metaEchartData.name[0];
        this.businessActive = item;
        this.appInstallMeth(); //下拉框
      }
      this.showEchartsByData(this.chartsValue);
    },
    // 网关吞吐量下拉框
    SelectGatewayDataChange(item) {
      //下拉框默认展示返回数据的第一条
      this.valueGateway = item;
      var value = this.valueGateway == "全部网关" ? "all" : this.valueGateway;
      var Object = this.gatewaythroughputObj;
      for (let key in Object) {
        if (value === key) {
          this.GatewayData = Object[key];
        }
      }
      this.showEchartsByData(this.chartsValue);
    },
    changeData7(item) {
      //左右切换
      if (item == "上行流量") {
        this.flowTopValue =
          this.metaEchartData.name[0] == "all"
            ? "全部网关"
            : this.metaEchartData.name[0];
        this.flowActive = item;
        this.flowTopType = "up_flow";
        this.appInstallMeth(); //下拉框取值
      } else if (item == "下行流量") {
        this.flowTopValue =
          this.metaEchartData.name[0] == "all"
            ? "全部网关"
            : this.metaEchartData.name[0];
        this.flowActive = item;
        this.flowTopType = "down_flow";
        this.appInstallMeth(); //下拉框取值
      }
      this.showEchartsByData(this.chartsValue);
    },
    selectChangeData7(item) {
      //下拉框
      // this.flowTopValue = this.metaEchartData.name[0] == "all" ? "全部网关" : this.metaEchartData.name[0]
      this.flowTopValue = item;
      this.showEchartsByData(this.chartsValue);
    },
    changeData8(item) {
      this.DevflowValue =
        this.metaEchartData.name[0] == "all"
          ? "全部网关"
          : this.metaEchartData.name[0];
      if (item == "上行流量") {
        this.DevflowValue =
          this.metaEchartData.name[0] == "all"
            ? "全部网关"
            : this.metaEchartData.name[0];
        this.devFlowActive = item;
        this.flowTopType = "up_flow";
        this.appInstallMeth(); //下拉框取值
      } else if (item == "下行流量") {
        this.DevflowValue =
          this.metaEchartData.name[0] == "all"
            ? "全部网关"
            : this.metaEchartData.name[0];
        this.devFlowActive = item;
        this.flowTopType = "down_flow";
        this.appInstallMeth(); //下拉框取值
      }
      this.showEchartsByData(this.chartsValue);
    },
    selectChangeData8(item) {
      //下拉框
      // console.log(item,"item上行流量")
      // this.DevflowValue = this.metaEchartData.name[0] == "all" ? "全部网关" : this.metaEchartData.name[0]
      this.DevflowValue = item;
      this.showEchartsByData(this.chartsValue);
    },
    changeData9(item) {
      //左右切换
      if (item == "上行流速") {
        this.appFlowRateValue =
          this.metaEchartData.name[0] == "all"
            ? "全部网关"
            : this.metaEchartData.name[0];
        this.appFlowRateActive = item;
        this.flowTopType = "up_flow";
        this.appInstallMeth(); //下拉框取值
      } else if (item == "下行流速") {
        this.appFlowRateValue =
          this.metaEchartData.name[0] == "all"
            ? "全部网关"
            : this.metaEchartData.name[0];
        this.appFlowRateActive = item;
        this.flowTopType = "down_flow";
        this.appInstallMeth(); //下拉框取值
      }
      this.showEchartsByData(this.chartsValue);
    },
    selectChangeData9(item) {
      //下拉框
      // this.appFlowRateValue = this.metaEchartData.name[0] == "all" ? "全部网关" : this.metaEchartData.name[0]
      this.appFlowRateValue = item;
      this.showEchartsByData(this.chartsValue);
    },
    changeData10(item) {
      this.devFlowRateValue =
        this.metaEchartData.name[0] == "all"
          ? "全部网关"
          : this.metaEchartData.name[0];
      if (item == "上行流速") {
        this.devFlowRateValue =
          this.metaEchartData.name[0] == "all"
            ? "全部网关"
            : this.metaEchartData.name[0];
        this.devFlowRateActive = item;
        this.flowTopType = "up_flow";
        this.appInstallMeth(); //下拉框取值
      } else if (item == "下行流速") {
        this.devFlowRateValue =
          this.metaEchartData.name[0] == "all"
            ? "全部网关"
            : this.metaEchartData.name[0];
        this.devFlowRateActive = item;
        this.flowTopType = "down_flow";
        this.appInstallMeth(); //下拉框取值
      }
      this.showEchartsByData(this.chartsValue);
    },
    selectChangeData10(item) {
      //下拉框
      // this.devFlowRateValue = this.metaEchartData.name[0] == "all" ? "全部网关" : this.metaEchartData.name[0]
      this.devFlowRateValue = item;
      this.showEchartsByData(this.chartsValue);
    },
    selectChangeData11(item) {
      //下拉框
      // this.netDelayValue = this.metaEchartData.name[0] == "all" ? "全部网关" : this.metaEchartData.name[0];
      this.netDelayValue = item;
      var value = this.netDelayValue == "全部网关" ? "all" : this.netDelayValue;
      var Object = this.gatewayResult.netDelay;
      for (let key in Object) {
        if (value === key) {
          this.netDelayData = Object[key];
        }
      }
      this.showEchartsByData(this.chartsValue);
      // this.netDelayValue = item;
      // this.appInstallMeth()//下拉框取值
      // this.showEchartsByData(this.chartsValue)
    },
    search() {
      this.valueBusiness = a;
      this.appInstallMeth();
    },
    // 柱状图
    tops() {
      var columnarX = [];
      var columnarY = [];
      var changeColumnarX = []; //改变之后的值
      // 应用流量top5
      if (this.appFlowTopFlag === true) {
        if (this.flowActive === "上行流量") {
          let options = [];
          let arrs = [];
          options = this.metaEchartData.name;
          options.forEach((item) => {
            if (item == "all") {
              arrs.push({
                flowTopValue: "全部网关",
                label: "全部网关",
              });
            } else {
              arrs.push({
                flowTopValue: item,
                label: item,
              });
            }
          });
          this.selectFlowTopData = arrs;
          var selectValue =
            this.flowTopValue == "全部网关" ? "all" : this.flowTopValue;
          var Object = this.gatewayResult.flowApp.up_flow;
          for (let key in Object) {
            if (selectValue === key) {
              this.appUpFlowData = Object[key];
            }
          }
          columnarX = this.appUpFlowData.name;
          columnarY = this.appUpFlowData.num;
          if (columnarY == undefined) {
            columnarY = [];
          }
        }
        if (this.flowActive === "下行流量") {
          let options = [];
          let arrs = [];
          options = this.metaEchartData.name;
          options.forEach((item) => {
            if (item == "all") {
              arrs.push({
                flowTopValue: "全部网关",
                label: "全部网关",
              });
            } else {
              arrs.push({
                flowTopValue: item,
                label: item,
              });
            }
          });
          this.selectFlowTopData = arrs;
          var selectValue =
            this.flowTopValue == "全部网关" ? "all" : this.flowTopValue;
          var Object = this.gatewayResult.flowApp.down_flow;
          for (let key in Object) {
            if (selectValue === key) {
              this.appDownFlowData = Object[key];
            }
          }
          columnarX = this.appDownFlowData.name;
          columnarY = this.appDownFlowData.num;
        }
      }
      // 设备流量top5
      if (this.devFlowFlag === true) {
        if (this.devFlowActive === "上行流量") {
          let options = [];
          let arrs = [];
          options = this.metaEchartData.name;
          options.forEach((item) => {
            if (item == "all") {
              arrs.push({
                DevflowValue: "全部网关",
                label: "全部网关",
              });
            } else {
              arrs.push({
                DevflowValue: item,
                label: item,
              });
            }
          });
          this.selectDevFlowData = arrs;
          var selectValue =
            this.DevflowValue == "全部网关" ? "all" : this.DevflowValue;
          var Object = this.gatewayResult.flowDev.up_flow;
          for (let key in Object) {
            if (selectValue === key) {
              this.devUpFlowData = Object[key];
            }
          }
          columnarX = this.devUpFlowData.name;
          columnarY = this.devUpFlowData.num;
          if (columnarY == undefined) {
            columnarY = [];
          }
        }
        if (this.devFlowActive === "下行流量") {
          let options = [];
          let arrs = [];
          options = this.metaEchartData.name;
          options.forEach((item) => {
            if (item == "all") {
              arrs.push({
                DevflowValue: "全部网关",
                label: "全部网关",
              });
            } else {
              arrs.push({
                DevflowValue: item,
                label: item,
              });
            }
          });
          this.selectDevFlowData = arrs;
          var selectValue =
            this.DevflowValue == "全部网关" ? "all" : this.DevflowValue;
          var Object = this.gatewayResult.flowDev.down_flow;
          for (let key in Object) {
            if (selectValue === key) {
              this.devDownFlowData = Object[key];
            }
          }
          columnarX = this.devDownFlowData.name;
          columnarY = this.devDownFlowData.num;
        }
      }
      // 应用流速top5
      if (this.appFlowRateFlag === true) {
        if (this.appFlowRateActive === "上行流速") {
          let options = [];
          let arrs = [];
          options = this.metaEchartData.name;
          options.forEach((item) => {
            if (item == "all") {
              arrs.push({
                appFlowRateValue: "全部网关",
                label: "全部网关",
              });
            } else {
              arrs.push({
                appFlowRateValue: item,
                label: item,
              });
            }
          });
          this.selectAppFlowRateData = arrs;
          var selectValue =
            this.appFlowRateValue == "全部网关" ? "all" : this.appFlowRateValue;
          var Object = this.gatewayResult.flowAppRate.up_flow;
          for (let key in Object) {
            if (selectValue === key) {
              this.appRateUpFlowData = Object[key];
            }
          }
          columnarX = this.appRateUpFlowData.name;
          columnarY = this.appRateUpFlowData.num;
          if (columnarY == undefined) {
            columnarY = [];
          }
        }
        if (this.appFlowRateActive === "下行流速") {
          let options = [];
          let arrs = [];
          options = this.metaEchartData.name;
          options.forEach((item) => {
            if (item == "all") {
              arrs.push({
                appFlowRateValue: "全部网关",
                label: "全部网关",
              });
            } else {
              arrs.push({
                appFlowRateValue: item,
                label: item,
              });
            }
          });
          this.selectAppFlowRateData = arrs;
          var selectValue =
            this.appFlowRateValue == "全部网关" ? "all" : this.appFlowRateValue;
          var Object = this.gatewayResult.flowAppRate.down_flow;
          for (let key in Object) {
            if (selectValue === key) {
              this.appRateDownFlowData = Object[key];
            }
          }
          columnarX = this.appRateDownFlowData.name;
          columnarY = this.appRateDownFlowData.num;
        }
      }
      // 设备流速top5
      if (this.devFlowRateFlag === true) {
        if (this.devFlowRateActive === "上行流速") {
          let options = [];
          let arrs = [];
          options = this.metaEchartData.name;
          options.forEach((item) => {
            if (item == "all") {
              arrs.push({
                devFlowRateValue: "全部网关",
                label: "全部网关",
              });
            } else {
              arrs.push({
                devFlowRateValue: item,
                label: item,
              });
            }
          });
          this.selectDevFlowRateData = arrs;
          var selectValue =
            this.devFlowRateValue == "全部网关" ? "all" : this.devFlowRateValue;
          var Object = this.gatewayResult.flowDevRate.up_flow;
          for (let key in Object) {
            if (selectValue === key) {
              this.devRateUpFlowData = Object[key];
            }
          }
          columnarX = this.devRateUpFlowData.name;
          columnarY = this.devRateUpFlowData.num;
          if (columnarY == undefined) {
            columnarY = [];
          }
        }
        if (this.devFlowRateActive === "下行流速") {
          let options = [];
          let arrs = [];
          options = this.metaEchartData.name;
          options.forEach((item) => {
            if (item == "all") {
              arrs.push({
                devFlowRateValue: "全部网关",
                label: "全部网关",
              });
            } else {
              arrs.push({
                devFlowRateValue: item,
                label: item,
              });
            }
          });
          this.selectDevFlowRateData = arrs;
          var selectValue =
            this.devFlowRateValue == "全部网关" ? "all" : this.devFlowRateValue;
          var Object = this.gatewayResult.flowDevRate.down_flow;
          for (let key in Object) {
            if (selectValue === key) {
              this.devRateDownFlowData = Object[key];
            }
          }
          columnarX = this.devRateDownFlowData.name;
          columnarY = this.devRateDownFlowData.num;
        }
      }
      // 网络延时趋势
      if (this.netDelaySelect === true) {
        let options = [];
        let arrs = [];
        options = this.metaEchartData.name;
        options.forEach((item) => {
          if (item == "all") {
            arrs.push({
              netDelayValue: "全部网关",
              label: "全部网关",
            });
          } else {
            arrs.push({
              netDelayValue: item,
              label: item,
            });
          }
        });
        this.selectNetDelayData = arrs;
        var selectValue =
          this.netDelayValue == "全部网关" ? "all" : this.netDelayValue;
        var Object = this.gatewayResult.netDelay;
        for (let key in Object) {
          if (selectValue === key) {
            this.netDelayData = Object[key];
          }
        }
        columnarX = this.netDelayData.name;
        columnarY = this.netDelayData.num;
        if (columnarY == undefined) {
          columnarY = [];
        }
      }

      if (this.According === true) {
        if (this.eventFlag === "dangerType") {
          columnarX = this.metaEchartData.dangerType.name;
          columnarY = this.metaEchartData.dangerType.num;
        } else if (this.eventFlag === "mobileApps") {
          columnarX = this.metaEchartData.mobileApps.name;
          columnarY = this.metaEchartData.mobileApps.num;
        }
      }

      if (this.flag === true) {
        if (this.runTimeFlag === "useTime") {
          columnarX = this.metaEchartData.useTime.name;
          columnarY = this.metaEchartData.useTime.num;
        } else if (this.runTimeFlag === "runCount") {
          columnarX = this.metaEchartData.runCount.name;
          columnarY = this.metaEchartData.runCount.num;
        }
      }
      //应用安装top5
      if (this.appInstallFlag === true) {

        if (this.metaEchartData) {
          if (this.appOsType === "iOS") {
            let option = [];
            let arr = [];
            option = this.metaEchartData.ios.name;
            option.forEach((item) => {
              arr.push({
                value: item,
                label: item,
              });
            });
            this.selectInstallAppData = [...arr];
            if (arr.length > 0) {
              this.value = this.value == "无数据" ? arr[0].value : this.value;
            } else if (arr.length === 0) {
              this.value = "无数据";
            }
            var array = this.leftAppIstall.ios;
            for (let key in array) {
              if (this.value === key) {
                this.installAppIosChart = array[key];
              }
            }
            if (
              JSON.stringify(this.installAppIosChart.name) === "{}" ||
              JSON.stringify(this.installAppIosChart.name) === undefined
            ) {
              columnarX = [];
            } else {
              columnarX = this.installAppIosChart.name;
            }
            if (
              JSON.stringify(this.installAppIosChart.name) === "{}" ||
              JSON.stringify(this.installAppIosChart.name) === undefined
            ) {
              columnarY = [];
            } else if (
              JSON.stringify(this.installAppIosChart.name) === "{}" ||
              JSON.stringify(this.installAppIosChart.name) === undefined
            ) {
              columnarY = [];
            }
            {
              columnarY = this.installAppIosChart.num;
            }
            
          }

          if (this.appOsType === "Android") {
            let option = [];
            let arr = [];
            option = this.metaEchartData.android.name;
            option.forEach((item) => {
              arr.push({
                value: item,
                label: item,
              });
            });
            this.selectInstallAppData = [...arr];
            this.value = this.value == "无数据" ? arr[0].value : this.value;
            var array = this.leftAppIstall.android;
            for (let key in array) {
              if (this.value === key) {
                this.installAppAndroidChart = array[key];
              }
            }
            if (
              JSON.stringify(this.installAppAndroidChart.name) === "{}" ||
              JSON.stringify(this.installAppAndroidChart.name) === undefined
            ) {
              columnarX = [];
            } else {
              columnarX = this.installAppAndroidChart.name;
            }
            if (
              JSON.stringify(this.installAppAndroidChart.name) === "{}" ||
              JSON.stringify(this.installAppAndroidChart.name) === undefined
            ) {
              columnarY = [];
            } else {
              columnarY = this.installAppAndroidChart.num;
            }
          }
          if (this.appOsType === "Windows") {
            //应用安装TOP5新增Window统计(v6.2.0)
            let option = [];
            let arr = [];
            option = this.metaEchartData.windows.name;
            option.forEach((item) => {
              arr.push({
                value: item,
                label: item,
              });
            });
            this.selectInstallAppData = [...arr];
            if (arr.length > 0) {
              this.value = this.value == "无数据" ? arr[0].value : this.value;
            } else if (arr.length === 0) {
              this.value = "无数据";
            }

            var array = this.leftAppIstall.windows;
            for (let key in array) {
              if (this.value === key) {
                this.installAppWindowsChart = array[key];
              }
            }
            if (
              JSON.stringify(this.installAppWindowsChart.name) === "{}" ||
              JSON.stringify(this.installAppWindowsChart.name) === undefined
            ) {
              columnarX = [];
            } else {
              columnarX = this.installAppWindowsChart.name;
            }
            if (
              JSON.stringify(this.installAppWindowsChart.name) === "{}" ||
              JSON.stringify(this.installAppWindowsChart.name) === undefined
            ) {
              columnarY = [];
            } else if (
              JSON.stringify(this.installAppWindowsChart.name) === "{}" ||
              JSON.stringify(this.installAppWindowsChart.name) === undefined
            ) {
              columnarY = [];
            }
            {
              columnarY = this.installAppWindowsChart.num;
            }
          }
          if (this.appOsType === "Linux") {
            let option = [];
            let arr = [];
            option = this.metaEchartData.linux.name;
            option.forEach((item) => {
              arr.push({
                value: item,
                label: item,
              });
            });
            this.selectInstallAppData = [...arr];
            this.value = this.value == "无数据" ? arr[0].value : this.value;
            var array = this.leftAppIstall.linux;
            for (let key in array) {
              if (this.value === key) {
                this.installAppLinuxChart = array[key];
              }
            }
            if (
              JSON.stringify(this.installAppLinuxChart.name) === "{}" ||
              JSON.stringify(this.installAppLinuxChart.name) === undefined
            ) {
              columnarX = [];
            } else {
              columnarX = this.installAppLinuxChart.name;
            }
            if (
              JSON.stringify(this.installAppLinuxChart.name) === "{}" ||
              JSON.stringify(this.installAppLinuxChart.name) === undefined
            ) {
              columnarY = [];
            } else {
              columnarY = this.installAppLinuxChart.num;
            }
          }
        }
      }
      // 业务访问趋势
      if (this.businessFlag === true) {
        if (this.businessActive === "正常访问") {
          let options = [];
          let arrs = [];
          options = this.metaEchartData.name;
          options.forEach((item) => {
            if (item == "all") {
              arrs.push({
                valueBusiness: "全部业务",
                label: "全部业务",
              });
            } else {
              arrs.push({
                valueBusiness: item,
                label: item,
              });
            }
          });
          this.selectBusinessData = arrs;
          if (
            JSON.stringify(this.businessArray.normal) === "{}" ||
            JSON.stringify(this.businessArray.normal) === undefined
          ) {
            this.busResourcesNormalObj = { name: {}, num: {} };
          } else {
            let value = "";
            if (this.valueBusiness == "全部业务") {
              value = "all";
            } else {
              value = this.valueBusiness;
            }
            // var value = this.valueBusiness=="all"?"全部业务":this.valueBusiness
            var Object = this.businessArray.normal;
            for (let key in Object) {
              if (value === key) {
                this.busResourcesNormalObj = Object[key];
              }
            }
          }

          if (
            JSON.stringify(this.busResourcesNormalObj.all) === "{}" ||
            JSON.stringify(this.busResourcesNormalObj.all) === undefined
          ) {
            columnarX = [];
          } else {
            columnarX = this.busResourcesNormalObj.name;
          }
          if (
            JSON.stringify(this.busResourcesNormalObj.all) === "{}" ||
            JSON.stringify(this.busResourcesNormalObj.all) === undefined
          ) {
            columnarY = [];
          } else if (
            JSON.stringify(this.busResourcesNormalObj.all) === "{}" ||
            JSON.stringify(this.busResourcesNormalObj.all) === undefined
          ) {
            columnarY = [];
          }
          {
            columnarY = this.busResourcesNormalObj.num;
          }
          if (
            JSON.stringify(this.busResourcesNormalObj.name) === "{}" ||
            JSON.stringify(this.busResourcesNormalObj.name) === undefined
          ) {
            columnarX = [];
          } else {
            columnarX = this.busResourcesNormalObj.name;
          }
          if (
            JSON.stringify(this.busResourcesNormalObj.name) === "{}" ||
            JSON.stringify(this.busResourcesNormalObj.name) === undefined
          ) {
            columnarY = [];
          } else if (
            JSON.stringify(this.busResourcesNormalObj.name) === "{}" ||
            JSON.stringify(this.busResourcesNormalObj.name) === undefined
          ) {
            columnarY = [];
          }
          {
            columnarY = this.busResourcesNormalObj.num;
          }
        }
        if (this.businessActive === "异常访问") {
          let options = [];
          let arrs = [];
          options = this.metaEchartData.name;
          options.forEach((item) => {
            if (item == "all") {
              arrs.push({
                valueBusiness: "全部业务",
                label: "全部业务",
              });
            } else {
              arrs.push({
                valueBusiness: item,
                label: item,
              });
            }
          });

          this.selectBusinessData = arrs;
          // if(this.businessArray.error.all){
          //   columnarX = this.businessArray.error.all.name
          //   columnarY = this.businessArray.error.all.num
          // }
          if (
            JSON.stringify(this.businessArray.error) === "{}" ||
            JSON.stringify(this.businessArray.error) === undefined
          ) {
            this.busResourcesErrorObj = { name: {}, num: {} };
          } else {
            var value =
              this.valueBusiness == "all" ? "全部业务" : this.valueBusiness;
            var Object = this.businessArray.error;
            for (let key in Object) {
              if (value === key) {
                this.busResourcesErrorObj = Object[key];
              }
            }
          }
          if (
            JSON.stringify(this.busResourcesErrorObj.all) === "{}" ||
            JSON.stringify(this.busResourcesErrorObj.all) === undefined
          ) {
            columnarX = [];
          } else {
            columnarX = this.busResourcesErrorObj.name;
          }
          if (
            JSON.stringify(this.busResourcesErrorObj.all) === "{}" ||
            JSON.stringify(this.busResourcesErrorObj.all) === undefined
          ) {
            columnarY = [];
          } else if (
            JSON.stringify(this.busResourcesErrorObj.all) === "{}" ||
            JSON.stringify(this.busResourcesErrorObj.all) === undefined
          ) {
            columnarY = [];
          }
          {
            columnarY = this.busResourcesErrorObj.num;
          }
          if (
            JSON.stringify(this.busResourcesErrorObj.name) === "{}" ||
            JSON.stringify(this.busResourcesErrorObj.name) === undefined
          ) {
            columnarX = [];
          } else {
            columnarX = this.busResourcesErrorObj.name;
          }
          if (
            JSON.stringify(this.busResourcesErrorObj.name) === "{}" ||
            JSON.stringify(this.busResourcesErrorObj.name) === undefined
          ) {
            columnarY = [];
          } else if (
            JSON.stringify(this.busResourcesErrorObj.name) === "{}" ||
            JSON.stringify(this.busResourcesErrorObj.name) === undefined
          ) {
            columnarY = [];
          }
          {
            columnarY = this.busResourcesErrorObj.num;
          }
        }
      }
      //网关吞吐量
      if (this.gatewaySelect === true) {
        let options = [];
        let arrs = [];
        options = this.metaEchartData.name;
        options.forEach((item) => {
          if (item == "all") {
            arrs.push({
              valueBusiness: "全部网关",
              label: "全部网关",
            });
          } else {
            arrs.push({
              valueBusiness: item,
              label: item,
            });
          }
        });
        this.selectGatewayData = arrs;
        var selectValue =
          this.valueGateway == "全部网关" ? "all" : this.valueGateway;
        var Object = this.gatewaythroughputObj;
        for (let key in Object) {
          if (selectValue === key) {
            this.GatewayData = Object[key];
          }
        }
        columnarX = this.GatewayData.name;
        columnarY = this.GatewayData.num;
      }
      //系统版本
      if (this.sysVersionFlag === true) {
        if (this.sysOsType === "10") {
          if (
            JSON.stringify(this.metaEchartData.ios.name) === "{}" ||
            JSON.stringify(this.metaEchartData.ios.name) === undefined
          ) {
            columnarX = [];
          } else {
            columnarX = this.metaEchartData.ios.name;
          }
          if (
            JSON.stringify(this.metaEchartData.ios.name) === "{}" ||
            JSON.stringify(this.metaEchartData.ios.name) === undefined
          ) {
            columnarY = [];
          } else {
            columnarY = this.metaEchartData.ios.num;
          }
          // columnarX = this.metaEchartData.ios.name;
          // columnarY = this.metaEchartData.ios.num;
        } else if (this.sysOsType === "20") {
          if (
            JSON.stringify(this.metaEchartData.android.name) === "{}" ||
            JSON.stringify(this.metaEchartData.android.name) === undefined
          ) {
            columnarX = [];
          } else {
            columnarX = this.metaEchartData.android.name;
          }
          if (
            JSON.stringify(this.metaEchartData.android.name) === "{}" ||
            JSON.stringify(this.metaEchartData.android.name) === undefined
          ) {
            columnarY = [];
          } else {
            columnarY = this.metaEchartData.android.num;
          }
          // columnarX = this.metaEchartData.android.name;
          // columnarY = this.metaEchartData.android.num;
        } else if (this.sysOsType === "30") {//系统版本新增Window统计(v6.2.0)
          if (
            JSON.stringify(this.metaEchartData.windows.name) === "{}" ||
            JSON.stringify(this.metaEchartData.windows.name) === undefined
          ) {
            columnarX = [];
          } else {
            columnarX = this.metaEchartData.windows.name;
          }
          if (
            JSON.stringify(this.metaEchartData.windows.name) === "{}" ||
            JSON.stringify(this.metaEchartData.windows.name) === undefined
          ) {
            columnarY = [];
          } else {
            columnarY = this.metaEchartData.windows.num;
          }
        } else if (this.sysOsType === "40") {
          //系统版本新增Linux统计(v6.3.0)
          if (
            JSON.stringify(this.metaEchartData.linux.name) === "{}" ||
            JSON.stringify(this.metaEchartData.linux.name) === undefined
          ) {
            columnarX = [];
          } else {
            columnarX = this.metaEchartData.linux.name;
          }
          if (
            JSON.stringify(this.metaEchartData.linux.name) === "{}" ||
            JSON.stringify(this.metaEchartData.linux.name) === undefined
          ) {
            columnarY = [];
          } else {
            columnarY = this.metaEchartData.linux.num;
          }
        }
      }

      if (
        this.According === false &&
        this.flag === false &&
        this.appInstallFlag === false &&
        this.sysVersionFlag === false &&
        this.businessFlag === false &&
        this.gatewaySelect === false &&
        this.appFlowRateFlag === false &&
        this.devFlowRateFlag === false &&
        this.appFlowTopFlag === false &&
        this.devFlowFlag === false &&
        this.netDelaySelect === false
      ) {
        columnarX = this.metaEchartData.name;
        columnarY = this.metaEchartData.num;
      }

      if (columnarX != null && columnarX.length > 0) {
        columnarX.forEach((item) => {
          if (item == "S_CHECK_LOCATION_FRAUD") {
            changeColumnarX.push("位置欺诈");
          } else if (item == "S_CHECK_DOMAIN") {
            changeColumnarX.push("域名欺诈");
          } else if (item == "S_CHECK_ABNORMAL_CA") {
            changeColumnarX.push("HTTPS劫持");
          } else if (item == "S_CHECK_INJECT_STATUS") {
            changeColumnarX.push("注入攻击");
          } else if (item == "S_CHECK_DEBUG_STATUS") {
            changeColumnarX.push("调试行为");
          } else if (item == "S_CHECK_MEMORY_CHANGE") {
            changeColumnarX.push("内存篡改");
          } else if (item == "S_CHECK_PLUGIN") {
            changeColumnarX.push("程序外挂");
          } else if (item == "S_CHECK_SIGNATURE") {
            changeColumnarX.push("应用破解");
          } else if (item == "S_CHECK_WIFI_PROXY") {
            changeColumnarX.push("HTTP代理");
          } else if (item == "S_CHECK_FRAME_ATTACK") {
            changeColumnarX.push("框架攻击");
          } else if (item == "S_CHECK_FREQUENCY_ACCOUNT") {
            changeColumnarX.push("高频更换账号");
          } else if (item == "S_CHECK_FREQUENCY_IP") {
            changeColumnarX.push("高频更换IP");
          } else if (item == "S_CHECK_FREQUENCY_LOCATION") {
            changeColumnarX.push("高频更换位置");
          } else if (item == "S_CHECK_FREQUENCY_RESTART") {
            changeColumnarX.push("高频启动应用");
          } else if (item == "S_CHECK_DANGER_APPS") {
            changeColumnarX.push("风险应用");
          } else if (item == "S_FRAME_ATTACH") {
            changeColumnarX.push("框架软件");
          } else if (item == "S_ADB_ENABLED") {
            changeColumnarX.push("USB调试");
          } else if (item == "S_CHECK_VM_STATUS") {
            changeColumnarX.push("模拟器");
          } else if (item == "S_ALLOW_MOCK_LOCATION") {
            changeColumnarX.push("允许模拟位置");
          } else if (item == "S_CHECK_SYS_USER_CA") {
            changeColumnarX.push("根证书异常");
          } else if (item == "S_CHECK_CUSTOM_ROM") {
            changeColumnarX.push("定制ROM");
          } else if (item == "S_CHECK_ROOT_STATUS") {
            changeColumnarX.push("ROOT/越狱");
          } else if (item == "S_CHECK_MULTI_APK") {
            changeColumnarX.push("应用多开");
          } else if (item == "S_CHECK_SCREEN_PASSWORD") {
            changeColumnarX.push("锁屏密码未开启");
          } else if (item == "S_MACOS_STATUS") {
            changeColumnarX.push("macOS");
          } else if (item == "S_WINDOWS_STATUS") {
            changeColumnarX.push("Windows");
          } else if (item == "S_VPN_STATUS") {
            changeColumnarX.push("VPN");
          } else if (item == "S_CLOUD_PHONE_STATUS") {
            changeColumnarX.push("云手机");
          } else if (item == "S_CHECK_SCREENSHOT_STATUS") {
            changeColumnarX.push("截屏阻断解除");
          } else if (item == "S_CHECK_DANGER_IP") {
            changeColumnarX.push("风险IP");
          } else {
            changeColumnarX.push(item);
          }
        });
      } else {
        columnarX = [];
      }

      //如果等于应用威胁top5 截取五个
      if (
        this.dimensionValue == "appInfo" &&
        this.statisticalValue1 == "appDangerTopConf"
      ) {
        changeColumnarX = changeColumnarX ? changeColumnarX.splice(0, 5) : "";
      }
      //如果等于应用活跃top5 截取五个
      if (
        this.dimensionValue == "appInfo" &&
        this.statisticalValue1 == "appActiveTopConf"
      ) {
        changeColumnarX = changeColumnarX ? changeColumnarX.splice(0, 5) : "";
      }
      //如果等于用户使用应用top5 截取五个
      if (
        this.dimensionValue == "userInfo" &&
        this.statisticalValue1 == "useAppTopConf"
      ) {
        changeColumnarX = changeColumnarX ? changeColumnarX.splice(0, 5) : "";
      }

      //如果等于 应用安装top5 截取五个
      if (
        this.dimensionValue == "appInfo" &&
        this.statisticalValue1 == "appInstallTopConf"
      ) {
        changeColumnarX = changeColumnarX ? changeColumnarX.splice(0, 5) : "";
      }

      //如果等于 应用平均使用时长 截取五个
      if (
        this.dimensionValue === "appInfo" &&
        this.statisticalValue1 === "appUseTimeAvgConf"
      ) {
        changeColumnarX = changeColumnarX ? changeColumnarX.splice(0, 5) : "";
      }

      //如果等于 系统版本 截取五个
      if (
        this.dimensionValue == "deviceInfo" &&
        this.statisticalValue1 == "systemVersionConf"
      ) {
        changeColumnarX = changeColumnarX ? changeColumnarX.splice(0, 5) : "";
      }

      //如果等于 访问用户Top5 截取五个
      if (
        this.dimensionValue == "gatewayInfo" &&
        this.statisticalValue1 == "userAccessTopConf"
      ) {
        changeColumnarX = changeColumnarX ? changeColumnarX.splice(0, 5) : "";
      } else if (
        this.dimensionValue == "gatewayInfo" &&
        this.statisticalValue1 == "deviceAccessTopConf"
      ) {
        //如果等于 访问设备top5 截取五个
        changeColumnarX = changeColumnarX ? changeColumnarX.splice(0, 5) : "";
      } else if (
        this.dimensionValue == "gatewayInfo" &&
        this.statisticalValue1 == "appAccessTopConf"
      ) {
        //如果等于 访问应用top5 截取五个
        changeColumnarX = changeColumnarX ? changeColumnarX.splice(0, 5) : "";
      } else if (
        this.dimensionValue == "gatewayInfo" &&
        this.statisticalValue1 == "sourceAccessTopConf"
      ) {
        //如果等于 访问资源top5 截取五个
        changeColumnarX = changeColumnarX ? changeColumnarX.splice(0, 5) : "";
      }

      var myChart = echarts.init(this.$refs.showCharts);
      var option;
      option = {
        tooltip: {
          trigger: "item", //axis
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            // 应用流量top5
            if (
              this.dimensionValue == "gatewayInfo" &&
              this.statisticalValue1 == "appFlowTopConf"
            ) {
              return params.name + "：" + this.$common.formatBytes(params.data);
            }
            // 设备流量top5
            if (
              this.dimensionValue == "gatewayInfo" &&
              this.statisticalValue1 == "devFlowTopConf"
            ) {
              return params.name + "：" + this.$common.formatBytes(params.data);
            }
            // 应用流速top5
            if (
              this.dimensionValue == "gatewayInfo" &&
              this.statisticalValue1 == "appFlowRateTopConf"
            ) {
              return params.name + "：" + this.$common.formatKbs(params.data);
            }
            // 设备流速top5
            if (
              this.dimensionValue == "gatewayInfo" &&
              this.statisticalValue1 == "devFlowRateTopConf"
            ) {
              return params.name + "：" + this.$common.formatKbs(params.data);
            }
            // 网络延时趋势
            if (
              this.dimensionValue == "gatewayInfo" &&
              this.statisticalValue1 == "netDelayTrendConf"
            ) {
              params.name + "：" + params.data + "ms";
            }
            if (
              this.dimensionValue == "appInfo" &&
              this.statisticalValue1 == "appActiveTopConf"
            ) {
              if (this.active == "使用时长") {
                var time = this.appTimeStampTop(params.value);
                return params.name + "：" + time;
              } else {
                return params.name + "：" + params.value + "次";
              }
            } else if (
              this.dimensionValue == "appInfo" &&
              this.statisticalValue1 == "appUseTimeAvgConf"
            ) {
              var time = this.timeStamp(params.value);
              return params.name + ": " + time;
            } else if (
              this.dimensionValue == "appInfo" &&
              this.statisticalValue1 == "appInstallTopConf"
            ) {
              return params.value + this.$t("SecurityPosture.Taiwan");
            } else if (
              this.dimensionValue == "deviceInfo" &&
              this.statisticalValue1 == "systemVersionConf"
            ) {
              return params.name+ ": " + params.value + this.$t("SecurityPosture.Taiwan");
            } else {
              return params.name + ": " + params.value;
            }
          },
          color: ["#1E89E5"], //这里不重要可以删除
        },
        grid: {
          //设置柱状图位置
          show: false,
          top: "32%", // 一下数值可为百分比也可为具体像素值
          // right:'5%',
          bottom: "12%",
          left: "12%",
        },
        xAxis: [
          {
            type: "category",
            show: changeColumnarX.length > 0,
            data: changeColumnarX,
            axisTick: {
              alignWithLabel: false,
              show: false,
            },
            axisLine: {
              //x轴线的颜色以及宽度
              show: true,
              lineStyle: {
                color: "#cccccc",
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              interval: "auto", // 自动隐藏显示不下的
              //x轴文字的配置
              show: true,
              textStyle: {
                color: "#cccccc",
              },
              formatter: (params) => {
                if (
                  (this.dimensionValue == "gatewayInfo" &&
                    this.statisticalValue1 == "deviceAccessTopConf") ||
                  (this.dimensionValue == "gatewayInfo" &&
                    this.statisticalValue1 == "appFlowTopConf") ||
                  (this.dimensionValue == "gatewayInfo" &&
                    this.statisticalValue1 == "devFlowTopConf") ||
                  (this.dimensionValue == "gatewayInfo" &&
                    this.statisticalValue1 == "appFlowRateTopConf") ||
                  (this.dimensionValue == "gatewayInfo" &&
                    this.statisticalValue1 == "devFlowRateTopConf") ||
                  this.statisticalValue1 == "sourceAccessTopConf" ||
                  (this.dimensionValue == "gatewayInfo" &&
                    this.statisticalValue1 == "userAccessTopConf") ||
                  (this.dimensionValue == "gatewayInfo" &&
                    this.statisticalValue1 == "deviceAccessTopConf") ||
                  (this.dimensionValue == "gatewayInfo" &&
                    this.statisticalValue1 == "appAccessTopConf") ||
                  (this.dimensionValue == "gatewayInfo" &&
                    this.statisticalValue1 == "sourceAccessTopConf") ||
                  (this.dimensionValue == "userInfo" &&
                    this.statisticalValue1 == "useAppTopConf") ||
                  (this.dimensionValue == "appInfo" &&
                    this.statisticalValue1 == "appActiveTopConf") ||
                  (this.dimensionValue == "appInfo" &&
                    this.statisticalValue1 == "appDangerTopConf") ||
                  (this.dimensionValue == "appInfo" &&
                    this.statisticalValue1 == "appUseTimeAvgConf") ||
                    (this.dimensionValue == "appInfo" &&
                    this.statisticalValue1 == "appInstallTopConf") ||
                    (this.dimensionValue == "deviceInfo" &&
                    this.statisticalValue1 == "systemVersionConf")
                ) {
                  var val = "";
                  if (params.length > 4) {
                    val = params.substr(0, 4) + "...";
                    return val;
                  } else {
                    return params;
                  }
                } else {
                  return params;
                }
              },
            },
            splitLine: {
              //分割线配置
              show: false,
              lineStyle: {
                color: "#fff",
              },
            },
          },
        ],
        yAxis: {
          type: "value",
          minInterval: 1,
          show: columnarY.length > 0,
          axisLabel: {
            //y轴文字的配置
            textStyle: {
              color: "#cccccc",
              margin: 15,
            },
            formatter: (params) => {
              // 应用流量top5
              if (
                this.dimensionValue == "gatewayInfo" &&
                this.statisticalValue1 == "appFlowTopConf"
              ) {
                let newVal = this.$common.formatBytes(params);
                // 文字超出4字隐藏
                if (!newVal) return "";
                //超出几个字符隐藏，鼠标悬浮时显示
                if (newVal.length > 4) {
                  newVal = newVal.slice(0, 4) + "...";
                }
                return newVal;
              }
              // 设备流量top5
              if (
                this.dimensionValue == "gatewayInfo" &&
                this.statisticalValue1 == "devFlowTopConf"
              ) {
                // console.log(this.$common.formatBytes(params,2),'2170');
                let newVal = this.$common.formatBytes(params);
                // 文字超出4字隐藏
                if (!newVal) return "";
                //超出几个字符隐藏，鼠标悬浮时显示
                if (newVal.length > 4) {
                  newVal = newVal.slice(0, 4) + "...";
                }
                return newVal;
              }
              // 应用流速top5
              if (
                this.dimensionValue == "gatewayInfo" &&
                this.statisticalValue1 == "appFlowRateTopConf"
              ) {
                // console.log(this.$common.formatBytes(params,2),'2170');
                let newVal = this.$common.formatKbs(params);
                // 文字超出4字隐藏
                if (!newVal) return "";
                //超出几个字符隐藏，鼠标悬浮时显示
                if (newVal.length > 4) {
                  newVal = newVal.slice(0, 4) + "...";
                }
                return newVal;
              }
              // 设备流速top5
              if (
                this.dimensionValue == "gatewayInfo" &&
                this.statisticalValue1 == "devFlowRateTopConf"
              ) {
                // console.log(this.$common.formatKbs(params),'2170');
                let newVal = this.$common.formatKbs(params);
                // 文字超出4字隐藏
                if (!newVal) return "";
                //超出几个字符隐藏，鼠标悬浮时显示
                if (newVal.length > 4) {
                  newVal = newVal.slice(0, 4) + "...";
                }
                return newVal;
              }
              // 网络延时趋势
              if (
                this.dimensionValue == "gatewayInfo" &&
                this.statisticalValue1 == "netDelayTrendConf"
              ) {
                // console.log(params=params?params+'ms':'','2170params');
                params = params ? params + "ms" : "0";
                // console.log(params,"params");
                if (params == "0") {
                  return "0";
                } else if (params.length > 4) {
                  var str = params.slice(0, 4) + "...";
                  return str;
                }
              }
              if (
                this.dimensionValue == "appInfo" &&
                this.statisticalValue1 == "appActiveTopConf"
              ) {
                if (this.active == "使用时长") {
                  return this.appTimeStampsTwoTop(params);
                } else if (this.active == "启动次数") {
                  return params + this.$t("SecurityPosture.times");
                }
              } else if (
                this.dimensionValue == "appInfo" &&
                this.statisticalValue1 == "appInstallTopConf"
              ) {
                return params + this.$t("SecurityPosture.Taiwan");
              } else if (
                this.dimensionValue == "appInfo" &&
                this.statisticalValue1 == "appUseTimeAvgConf"
              ) {
                return this.timeStampsTwo(params);
              } else if (
                this.dimensionValue == "deviceInfo" &&
                this.statisticalValue1 == "systemVersionConf"
              ) {
                return params + this.$t("SecurityPosture.Taiwan");
              } else {
                return params;
              }
            },
            // formatter: '{value} %'//y轴的每一个刻度值后面加上‘%’号
          },
          axisTick: {
            //y轴刻度线
            show: false,
          },
          axisLine: {
            //y轴线的颜色以及宽度
            // show: false,
            lineStyle: {
              color: "#cccccc",
              width: 1,
              type: "solid",
            },
          },
          splitLine: {
            //分割线配置
            show: false,
            lineStyle: {
              color: "#fff",
            },
          },
          // 背景图虚线
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed", //shadow..dashed---
              color: "#16285D",
            },
          },
        },
        series: [
          {
            // name: "Direct",
            type: "bar",
            barWidth: "40%",
            data: columnarY,
            itemStyle: {
              //---图形形状
              color: "#1E89E5",
              // barBorderRadius:[18,18,0,0],
            },
          },
        ],
        graphic: {
          type: "text", // 类型：文本
          left: "center",
          top: "middle",
          silent: true, // 不响应事件
          invisible: columnarY.length > 0, // 有数据就隐藏
          style: {
            fill: "#909399",
            fontWeight: "bold",
            text: this.$t("SecurityPosture.noData"),
            fontFamily: "Microsoft YaHei",
            fontSize: "24",
          },
        },
      };
      myChart.clear();
      option && myChart.setOption(option);
      let _this = this;
      let erd = elementResizeDetectorMaker();
      erd.listenTo(this.$refs.showCharts, () => {
        _this.$nextTick(() => {
          myChart.resize();
        });
      });
    },
    // 折线图
    figure() {
      var columnarX = [];
      var columnarY = [];

      if (this.According === true) {
        if (this.eventFlag === "dangerType") {
          columnarX = this.metaEchartData.dangerType.name;
          columnarY = this.metaEchartData.dangerType.num;
        } else if (this.eventFlag === "mobileApps") {
          columnarX = this.metaEchartData.mobileApps.name;
          columnarY = this.metaEchartData.mobileApps.num;
        }
      }

      if (this.flag === true) {
        if (this.runTimeFlag === "useTime") {
          columnarX = this.metaEchartData.useTime.name;
          columnarY = this.metaEchartData.useTime.num;
        } else if (this.runTimeFlag === "runCount") {
          columnarX = this.metaEchartData.runCount.name;
          columnarY = this.metaEchartData.runCount.num;
        }
      }

      // 业务访问趋势
      if (this.businessFlag === true) {
        // console.log(this.metaEchartData, '业务访问资源Tops')
        if (this.businessActive === "正常访问") {
          // console.log(this.valueBusiness, "this.valueBusiness");
          let options = [];
          let arrs = [];
          options = this.metaEchartData.name;
          // console.log(options, '下拉框---');
          options.forEach((item) => {
            // console.log(item, '----')
            if (item == "all") {
              arrs.push({
                valueBusiness: "全部业务",
                label: "全部业务",
              });
            } else {
              arrs.push({
                valueBusiness: item,
                label: item,
              });
            }
          });

          this.selectBusinessData = arrs;

          // console.log(this.selectBusinessData, this.valueBusiness, "this.21333333333")
          if (
            JSON.stringify(this.businessArray.normal) === "{}" ||
            JSON.stringify(this.businessArray.normal) === undefined
          ) {
            this.busResourcesNormalObj = { name: {}, num: {} };
          } else {
            let value = "";
            // console.log(this.valueBusiness, "this.valueBusiness")
            if (this.valueBusiness == "全部业务") {
              value = "all";
            } else {
              value = this.valueBusiness;
            }
            // var value = this.valueBusiness=="all"?"全部业务":this.valueBusiness
            var Object = this.businessArray.normal;
            for (let key in Object) {
              // console.log(value, key, "value === key111111")
              if (value === key) {
                // console.log(Object[key], "Object[key]")
                this.busResourcesNormalObj = Object[key];
              }
            }
            // console.log(this.busResourcesNormalObj, '------------正-this.busResourcesNormalObj---------------this.busResourcesNormalObj')
          }

          if (
            JSON.stringify(this.busResourcesNormalObj.all) === "{}" ||
            JSON.stringify(this.busResourcesNormalObj.all) === undefined
          ) {
            columnarX = [];
          } else {
            columnarX = this.busResourcesNormalObj.name;
          }
          if (
            JSON.stringify(this.busResourcesNormalObj.all) === "{}" ||
            JSON.stringify(this.busResourcesNormalObj.all) === undefined
          ) {
            columnarY = [];
          } else if (
            JSON.stringify(this.busResourcesNormalObj.all) === "{}" ||
            JSON.stringify(this.busResourcesNormalObj.all) === undefined
          ) {
            columnarY = [];
          }
          {
            columnarY = this.busResourcesNormalObj.num;
          }
          if (
            JSON.stringify(this.busResourcesNormalObj.name) === "{}" ||
            JSON.stringify(this.busResourcesNormalObj.name) === undefined
          ) {
            columnarX = [];
          } else {
            columnarX = this.busResourcesNormalObj.name;
          }
          if (
            JSON.stringify(this.busResourcesNormalObj.name) === "{}" ||
            JSON.stringify(this.busResourcesNormalObj.name) === undefined
          ) {
            columnarY = [];
          } else if (
            JSON.stringify(this.busResourcesNormalObj.name) === "{}" ||
            JSON.stringify(this.busResourcesNormalObj.name) === undefined
          ) {
            columnarY = [];
          }
          {
            columnarY = this.busResourcesNormalObj.num;
          }
        }
        if (this.businessActive === "异常访问") {
          // console.log(this.busResourcesErrorObj, "this.busResourcesErrorObj")
          let options = [];
          let arrs = [];
          options = this.metaEchartData.name;
          // console.log(options, '下拉框---');
          options.forEach((item) => {
            // console.log(item, '----')
            if (item == "all") {
              arrs.push({
                valueBusiness: "全部业务",
                label: "全部业务",
              });
            } else {
              arrs.push({
                valueBusiness: item,
                label: item,
              });
            }
          });

          this.selectBusinessData = arrs;
          // if(this.businessArray.error.all){
          //   columnarX = this.businessArray.error.all.name
          //   columnarY = this.businessArray.error.all.num
          // }
          if (
            JSON.stringify(this.businessArray.error) === "{}" ||
            JSON.stringify(this.businessArray.error) === undefined
          ) {
            this.busResourcesErrorObj = { name: {}, num: {} };
          } else {
            var value =
              this.valueBusiness == "all" ? "全部业务" : this.valueBusiness;
            var Object = this.businessArray.error;
            for (let key in Object) {
              // console.log(key, "key", this.valueBusiness)
              if (value === key) {
                // console.log(Object[key], "Object.key")
                this.busResourcesErrorObj = Object[key];
              }
            }
            // console.log(this.busResourcesErrorObj, '-------------this.busResourcesErrorObj---------------this.busResourcesErrorObj')
          }
          // console.log(this.busResourcesErrorObj.num, "columnarY==========this.busResourcesErrorObj.num")
          if (
            JSON.stringify(this.busResourcesErrorObj.all) === "{}" ||
            JSON.stringify(this.busResourcesErrorObj.all) === undefined
          ) {
            columnarX = [];
          } else {
            columnarX = this.busResourcesErrorObj.name;
          }
          if (
            JSON.stringify(this.busResourcesErrorObj.all) === "{}" ||
            JSON.stringify(this.busResourcesErrorObj.all) === undefined
          ) {
            columnarY = [];
          } else if (
            JSON.stringify(this.busResourcesErrorObj.all) === "{}" ||
            JSON.stringify(this.busResourcesErrorObj.all) === undefined
          ) {
            columnarY = [];
          }
          {
            columnarY = this.busResourcesErrorObj.num;
          }
          if (
            JSON.stringify(this.busResourcesErrorObj.name) === "{}" ||
            JSON.stringify(this.busResourcesErrorObj.name) === undefined
          ) {
            columnarX = [];
          } else {
            columnarX = this.busResourcesErrorObj.name;
          }
          if (
            JSON.stringify(this.busResourcesErrorObj.name) === "{}" ||
            JSON.stringify(this.busResourcesErrorObj.name) === undefined
          ) {
            columnarY = [];
          } else if (
            JSON.stringify(this.busResourcesErrorObj.name) === "{}" ||
            JSON.stringify(this.busResourcesErrorObj.name) === undefined
          ) {
            columnarY = [];
          }
          {
            columnarY = this.busResourcesErrorObj.num;
          }
        }
      }

      // 网络延时趋势
      if (this.netDelaySelect === true) {
        let options = [];
        let arrs = [];
        options = this.metaEchartData.name;
        options.forEach((item) => {
          if (item == "all") {
            arrs.push({
              netDelayValue: "全部网关",
              label: "全部网关",
            });
          } else {
            arrs.push({
              netDelayValue: item,
              label: item,
            });
          }
        });
        this.selectNetDelayData = arrs;
        var selectValue =
          this.netDelayValue == "全部网关" ? "all" : this.netDelayValue;
        var Object = this.gatewayResult.netDelay;
        for (let key in Object) {
          if (selectValue === key) {
            this.netDelayData = Object[key];
          }
        }
        columnarX = this.netDelayData.name;
        columnarY = this.netDelayData.num;
      }
      //网关吞吐量
      if (this.gatewaySelect === true) {
        let options = [];
        let arrs = [];
        options = this.metaEchartData.name;
        options.forEach((item) => {
          if (item == "all") {
            arrs.push({
              valueBusiness: "全部网关",
              label: "全部网关",
            });
          } else {
            arrs.push({
              valueBusiness: item,
              label: item,
            });
          }
        });
        this.selectGatewayData = arrs;
        var selectValue =
          this.valueGateway == "全部网关" ? "all" : this.valueGateway;
        var Object = this.gatewaythroughputObj;
        for (let key in Object) {
          if (selectValue === key) {
            this.GatewayData = Object[key];
          }
        }
        columnarX = this.GatewayData.name;
        columnarY = this.GatewayData.num;
      }
      if (
        this.According === false &&
        this.flag === false &&
        this.businessFlag === false &&
        this.gatewaySelect === false &&
        this.netDelaySelect === false
      ) {
        columnarX = this.metaEchartData.name;
        columnarY = this.metaEchartData.num;
      }

      var myChart = echarts.init(this.$refs.showCharts);
      var option;

      option = {
        tooltip: {
          trigger: "axis",
        },
        formatter: (params) => {
          // console.log(params[0].data,"折线图添加ms");
          // 网络延时趋势
          if (
            this.dimensionValue == "gatewayInfo" &&
            this.statisticalValue1 == "netDelayTrendConf"
          ) {
            return params[0].name + "：" + params[0].data + "ms";
          } else {
            return params[0].name + "：" + params[0].data;
          }
        },
        color: ["#1D8F84", "#3D8BC8"], //关键加上这句话，legend的颜⾊和折线的⾃定义颜⾊就⼀致了
        grid: {
          show: false,
          top: "32%",
          left: "12%",
          right: "12%",
          bottom: "12%",
          // containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: columnarX,
          axisTick: {
            alignWithLabel: false,
            show: false,
          },
          axisLine: {
            //x轴线的颜色以及宽度
            show: true,
            lineStyle: {
              color: "#cccccc",
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            //x轴文字的配置
            show: true,
            textStyle: {
              color: "#cccccc",
            },
          },
          splitLine: {
            //分割线配置
            show: false,
            lineStyle: {
              color: "#fff",
            },
          },
        },
        yAxis: {
          type: "value",
          minInterval: 1,
          axisTick: {
            //y轴刻度线
            show: false,
          },
          axisLabel: {
            //y轴文字的配置
            textStyle: {
              color: "#cccccc",
              margin: 15,
            },
            // formatter: '{value} %'//y轴的每一个刻度值后面加上‘%’号
            formatter: (params) => {
              // console.log(params,"网络延时趋势yAxis");
              // 网络延时趋势
              if (
                this.dimensionValue == "gatewayInfo" &&
                this.statisticalValue1 == "netDelayTrendConf"
              ) {
                // console.log(this.$common.formatBytes(params,2),'2170');
                params = params ? params + "ms" : "0";
                // console.log(params,"params");
                if (params == "0") {
                  return "0";
                } else if (params.length > 4) {
                  var str = params.slice(0, 4) + "...";
                  return str;
                } else {
                  // console.log(params,"strparams");
                  return params;
                }
              } else {
                return params;
              }
            },
          },
          axisLine: {
            //y轴线的颜色以及宽度
            // show: false,
            lineStyle: {
              color: "#cccccc",
              width: 1,
              type: "solid",
            },
          },
          splitLine: {
            //分割线配置
            show: false,
            lineStyle: {
              color: "#fff",
            },
          },
          // 背景图虚线
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed", //shadow..dashed---
              color: "#16285D",
            },
          },
        },
        series: [
          {
            // name: "Email",
            type: "line",
            stack: "Total",
            data: columnarY,
            type: "line",
            areaStyle: {},
          },
          // {
          //     name: 'Union Ads',
          //     type: 'line',
          //     stack: 'Total',
          //     data: [220, 182, 191, 234, 290, 330, 310],
          //     type: 'line',
          //     areaStyle: {}
          // },
        ],
      };
      myChart.clear();
      option && myChart.setOption(option);
      let _this = this;
      let erd = elementResizeDetectorMaker();
      erd.listenTo(this.$refs.showCharts, () => {
        _this.$nextTick(() => {
          myChart.resize();
        });
      });
    },
    // 饼图
    pieChartsMethods() {
      var columnarX = [];
      var columnarY = [];
      var showData = [];

      if (this.According === true) {
        if (this.eventFlag === "dangerType") {
          columnarX = this.metaEchartData.dangerType.name;
          columnarY = this.metaEchartData.dangerType.num;
        } else if (this.eventFlag === "mobileApps") {
          columnarX = this.metaEchartData.mobileApps.name;
          columnarY = this.metaEchartData.mobileApps.num;
        }
      }

      if (this.flag === true) {
        if (this.runTimeFlag === "useTime") {
          columnarX = this.metaEchartData.useTime.name;
          columnarY = this.metaEchartData.useTime.num;
        } else if (this.runTimeFlag === "runCount") {
          columnarX = this.metaEchartData.runCount.name;
          columnarY = this.metaEchartData.runCount.num;
        }
      }

      if (this.According === false && this.flag === false) {
        columnarX = this.metaEchartData.name;
        columnarY = this.metaEchartData.num;
      }

      //设备----- 违规行为分布

      if (
        this.dimensionValue == "deviceInfo" &&
        this.statisticalValue1 == "violaBehaviorDistributeConf"
      ) {
        var total = 0;
        for (var i = 0; i < columnarX.length; i++) {
          var columnar = {
            value: "",
            name: "",
            type: "",
          };
          var columnarNmae = "";
          columnarNmae = locale.locale("violation.reason_" + columnarX[i],this.CurLangMode);
          //需要映射显示中文描述
          columnar.name = columnarNmae;
          columnar.value = columnarY[i];
          columnar.type = columnarX[i];
          total += columnarY[i];
          showData.push(columnar);
        }
        var newBehaviorDistribut = [];
        var newBehaviorDistributType = [];
        var surplusBehaviorDistribut1 = [];
        for (var i = 0; i < showData?.length; i++) {
          if (i >= 5) {
            surplusBehaviorDistribut1.push({
              name: showData[i].name,
              value: showData[i].value,
              type: showData[i].type,
              proportion: ((showData[i].value / total) * 100).toFixed(2),
            });
            this.surplusBehaviorDistribut = surplusBehaviorDistribut1;
          }
          if (i < 5) {
            newBehaviorDistribut.push({
              name: showData[i].name,
              value: showData[i].value,
              type: showData[i].type,
            });
            newBehaviorDistributType.push(showData[i].type);
          } else {
            if (i === 5) {
              newBehaviorDistribut.push({
                name: locale.locale("violation.reason_99",this.CurLangMode),
                value: 0,
              });
              newBehaviorDistributType.push(
                locale.locale("violation.reason_99",this.CurLangMode)
              );
            }
            //其他违规的value等于剩余所有的总和
            newBehaviorDistribut[5].value += Number(showData[i].value || "0");
          }
        }
        showData = newBehaviorDistribut;
      } else {
        for (var i = 0; i < columnarX.length; i++) {
          var columnar = {
            value: "",
            name: "",
          };
          var columnarNmae = "";

          if (columnarX[i] == "S_CHECK_LOCATION_FRAUD") {
            columnarNmae = "位置欺诈";
          } else if (columnarX[i] == "S_CHECK_DOMAIN") {
            columnarNmae = "域名欺诈";
          } else if (columnarX[i] == "S_CHECK_ABNORMAL_CA") {
            columnarNmae = "HTTPS劫持";
          } else if (columnarX[i] == "S_CHECK_INJECT_STATUS") {
            columnarNmae = "注入攻击";
          } else if (columnarX[i] == "S_CHECK_DEBUG_STATUS") {
            columnarNmae = "调试行为";
          } else if (columnarX[i] == "S_CHECK_MEMORY_CHANGE") {
            columnarNmae = "内存篡改";
          } else if (columnarX[i] == "S_CHECK_PLUGIN") {
            columnarNmae = "程序外挂";
          } else if (columnarX[i] == "S_CHECK_SIGNATURE") {
            columnarNmae = "应用破解";
          } else if (columnarX[i] == "S_CHECK_WIFI_PROXY") {
            columnarNmae = "HTTP代理";
          } else if (columnarX[i] == "S_CHECK_FRAME_ATTACK") {
            columnarNmae = "框架攻击";
          } else if (columnarX[i] == "S_CHECK_FREQUENCY_ACCOUNT") {
            columnarNmae = "高频更换账号";
          } else if (columnarX[i] == "S_CHECK_FREQUENCY_IP") {
            columnarNmae = "高频更换IP";
          } else if (columnarX[i] == "S_CHECK_FREQUENCY_LOCATION") {
            columnarNmae = "高频更换位置";
          } else if (columnarX[i] == "S_CHECK_FREQUENCY_RESTART") {
            columnarNmae = "高频启动应用";
          } else if (columnarX[i] == "S_CHECK_DANGER_APPS") {
            columnarNmae = "风险应用";
          } else if (columnarX[i] == "S_FRAME_ATTACH") {
            columnarNmae = "框架软件";
          } else if (columnarX[i] == "S_ADB_ENABLED") {
            columnarNmae = "USB调试";
          } else if (columnarX[i] == "S_CHECK_VM_STATUS") {
            columnarNmae = "模拟器";
          } else if (columnarX[i] == "S_ALLOW_MOCK_LOCATION") {
            columnarNmae = "允许模拟位置";
          } else if (columnarX[i] == "S_CHECK_SYS_USER_CA") {
            columnarNmae = "根证书异常";
          } else if (columnarX[i] == "S_CHECK_CUSTOM_ROM") {
            columnarNmae = "定制ROM";
          } else if (columnarX[i] == "S_CHECK_ROOT_STATUS") {
            columnarNmae = "ROOT/越狱";
          } else if (columnarX[i] == "S_CHECK_MULTI_APK") {
            columnarNmae = "应用多开";
          } else if (columnarX[i] == "S_CHECK_SCREEN_PASSWORD") {
            columnarNmae = "锁屏密码未开启";
          } else if (columnarX[i] == "S_MACOS_STATUS") {
            columnarNmae = "macOS";
          } else if (columnarX[i] == "S_WINDOWS_STATUS") {
            columnarNmae = "Windows";
          } else if (columnarX[i] == "S_VPN_STATUS") {
            columnarNmae = "VPN";
          } else if (columnarX[i] == "S_CLOUD_PHONE_STATUS") {
            columnarNmae = "云手机";
          } else if (columnarX[i] == "S_CHECK_SCREENSHOT_STATUS") {
            columnarNmae = "截屏阻断解除";
          } else if (columnarX[i] == "S_CHECK_DANGER_IP") {
            columnarNmae = "风险IP";
          }
          // // 设备违规
          // else if (columnarX[i] == "0") {
          //   columnarNmae = "设备越狱";
          // } else if (columnarX[i] == "1") {
          //   columnarNmae = "系统版本";
          // }
          //设备占比
          else if (columnarX[i] == "activeNum") {
            columnarNmae = "活跃设备";
          } else if (columnarX[i] == "restNum") {
            columnarNmae = "其他设备";
          } else if (columnarX[i] == "newJoinNum") {
            columnarNmae = "新接入设备";
          } else if (columnarX[i] == "activeUserNum") {
            columnarNmae = "活跃用户";
          } else if (columnarX[i] == "restUserNum") {
            columnarNmae = "其他用户";
          } else {
            columnarNmae = columnarX[i];
          }
          //需要映射显示中文描述
          columnar.name = columnarNmae;
          columnar.value = columnarY[i];
          showData.push(columnar);
        }
      }

      //如果等于应用威胁top5 截取五个
      if (
        this.dimensionValue == "appInfo" &&
        this.statisticalValue1 == "appDangerTopConf"
      ) {
        showData = showData ? showData.splice(0, 5) : "";
      }
      //如果等于应用活跃top5 截取五个
      if (
        this.dimensionValue == "appInfo" &&
        this.statisticalValue1 == "appActiveTopConf"
      ) {
        showData = showData ? showData.splice(0, 5) : "";
      }

      //如果等于用户使用应用top5 截取五个
      if (
        this.dimensionValue == "userInfo" &&
        this.statisticalValue1 == "useAppTopConf"
      ) {
        showData = showData ? showData.splice(0, 5) : "";
      }

      var myChart = echarts.init(this.$refs.showCharts);
      var option;

      option = {
        tooltip: {
          trigger: "item", // 重写legend显示样式
          formatter: (params) => {
            if (
              this.dimensionValue == "appInfo" &&
              this.statisticalValue1 == "appActiveTopConf"
            ) {
              if (this.active == "使用时长") {
                var time = this.appTimeStampTop(params.value);
                return params.name + "：" + time;
              } else {
                return params.name + "：" + params.value + "次";
              }
            } else {
              return params.name + " " + params.value;
            }
          }, // formatter: '{b} : {c} ({d}%)' //{a} <br/>{b} : {c} ({d}%)
        },
        color: ["#01B4D2", "#FFB508", "#3272FB", "#00BD8D", "#FF6A3C"], //关键加上这句话，legend的颜⾊和折线的⾃定义颜⾊就⼀致了
        legend: {
          //   top: "bottom",
          itemWidth: 12, //宽
          itemHeight: 12, //高
          // icon  =  circle（圆型）rect（矩形）roundRect（圆角矩形）triangle（三角形）diamond（菱形）pin（水滴）arrow（箭头）none（不显示图标）
          icon: "rect",
          top: "30%",
          // left: 10,
          orient: "vertical",
          right: 15,
          // top: 60, //y轴向下偏移
          bottom: 20,
          textStyle: {
            color: "#ffffff", //legend  的颜色
            fontSize: 11, //文字的字体大小
          },
          // 重写legend显示样式
          formatter: (name) => {
            if (
              this.dimensionValue == "appInfo" &&
              this.statisticalValue1 == "appActiveTopConf"
            ) {
              let data = option.series[0].data;
              let total = 0;
              let tarValue = 0;
              for (let i = 0, l = data.length; i < l; i++) {
                total += data[i].value;
                if (data[i].name == name) {
                  tarValue = data[i].value;
                }
              }
              if (this.active == "使用时长") {
                // return this.timeStamp(tarValue)
                var time = this.appTimeStampTop(tarValue);
                return name.slice(0, 4) + "..." + " " + time;
              } else {
                return (
                  name.slice(0, 4) +
                  "..." +
                  " " +
                  tarValue +
                  this.$t("SecurityPosture.times")
                );
              }
            } else {
              // 获取legend显示内容
              let data = option.series[0].data;
              let total = 0;
              let tarValue = 0;
              for (let i = 0, l = data.length; i < l; i++) {
                total += data[i].value;
                if (data[i].name == name) {
                  tarValue = data[i].value;
                }
              }
              // let p = ((tarValue / total) * 100).toFixed(2);
              // 文字超出4字隐藏
              if (!name) return "";
              //超出几个字符隐藏，鼠标悬浮时显示
              if (name.length > 4) {
                name = name.slice(0, 4) + "...";
              }
              var nameTu = name.replace(/\n/, " ");

              return nameTu + " " + tarValue;
            }
          },
        },
        toolbox: {
          show: true,
          //   feature: {
          //     mark: { show: true },
          //     dataView: { show: true, readOnly: false },
          //     restore: { show: true },
          //     saveAsImage: { show: true },
          //   },
        },
        series: [
          {
            // name: "Nightingale Chart",
            type: "pie",
            radius: "40%",
            // radius: ["15", "45"],
            center: ["45%", "65%"],
            // roseType: "area",
            // itemStyle: {
            //   borderRadius: 8,
            // },

            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "14",
                fontWeight: "bold",
              },
            },

            areaStyle: {
              normal: {
                // color: "#EAEAF0", //改变区域颜色
                position: "center",
                formatter: "{c}", // {b}:数据名； {c}：数据值； {d}：百分比，可以自定义显示内容
              },
            },
            //视觉引导线
            labelLine: {
              show: true,
            },
            //饼图图形上的文本标签
            label: {
              show: true,
              formatter: "{b}\n{d}% ", //{b}:{d}%
            },
            data: showData,
          },
        ],
        graphic: {
          type: "text", // 类型：文本
          left: "center",
          top: "middle",
          silent: true, // 不响应事件
          invisible: showData.length > 0, // 有数据就隐藏
          style: {
            fill: "#909399",
            fontWeight: "bold",
            text: this.$t("SecurityPosture.noData"),
            fontFamily: "Microsoft YaHei",
            fontSize: "24",
          },
        },
      };
      myChart.clear();
      option && myChart.setOption(option);
      let _this = this;
      let erd = elementResizeDetectorMaker();
      erd.listenTo(this.$refs.showCharts, () => {
        _this.$nextTick(() => {
          myChart.resize();
        });
      });
    },
    // 圆环
    ringMore() {
      var columnarX = [];
      var columnarY = [];
      var showData = [];
      if (this.metaEchartData) {
        if (this.According === true) {
          if (this.eventFlag === "dangerType") {
            columnarX = this.metaEchartData.dangerType.name;
            columnarY = this.metaEchartData.dangerType.num;
          } else if (this.eventFlag === "mobileApps") {
            columnarX = this.metaEchartData.mobileApps.name;
            columnarY = this.metaEchartData.mobileApps.num;
          }
        }

        if (this.flag === true) {
          if (this.runTimeFlag === "useTime") {
            columnarX = this.metaEchartData.useTime.name;
            columnarY = this.metaEchartData.useTime.num;
          } else if (this.runTimeFlag === "runCount") {
            columnarX = this.metaEchartData.runCount.name;
            columnarY = this.metaEchartData.runCount.num;
          }
        }

        if (this.According === false && this.flag === false) {
          columnarX = this.metaEchartData.name;
          columnarY = this.metaEchartData.num;
        }

        //设备----- 违规行为分布

        if (
          this.dimensionValue == "deviceInfo" &&
          this.statisticalValue1 == "violaBehaviorDistributeConf"
        ) {
          var total = 0;
          for (var i = 0; i < columnarX.length; i++) {
            var columnar = {
              value: "",
              name: "",
              type: "",
            };
            var columnarNmae = "";
            columnarNmae = locale.locale("violation.reason_" + columnarX[i],this.CurLangMode);
            //需要映射显示中文描述
            columnar.name = columnarNmae;
            columnar.value = columnarY[i];
            columnar.type = columnarX[i];
            total += columnarY[i];
            showData.push(columnar);
          }
          var newBehaviorDistribut = [];
          var newBehaviorDistributType = [];
          var surplusBehaviorDistribut1 = [];
          for (var i = 0; i < showData?.length; i++) {
            if (i >= 5) {
              surplusBehaviorDistribut1.push({
                name: showData[i].name,
                value: showData[i].value,
                type: showData[i].type,
                proportion: ((showData[i].value / total) * 100).toFixed(2),
              });
              this.surplusBehaviorDistribut = surplusBehaviorDistribut1;
            }
            if (i < 5) {
              newBehaviorDistribut.push({
                name: showData[i].name,
                value: showData[i].value,
                type: showData[i].type,
              });
              newBehaviorDistributType.push(showData[i].type);
            } else {
              if (i === 5) {
                newBehaviorDistribut.push({
                  name: locale.locale("violation.reason_99",this.CurLangMode),
                  value: 0,
                });
                newBehaviorDistributType.push(
                  locale.locale("violation.reason_99",this.CurLangMode)
                );
              }
              //其他违规的value等于剩余所有的总和
              newBehaviorDistribut[5].value += Number(showData[i].value || "0");
            }
          }
          showData = newBehaviorDistribut;
        } else {
          for (var i = 0; i < columnarX.length; i++) {
            var columnar = {
              value: "",
              name: "",
            };
            var columnarNmae = "";

            if (columnarX[i] == "S_CHECK_LOCATION_FRAUD") {
              columnarNmae = "位置欺诈";
            } else if (columnarX[i] == "S_CHECK_DOMAIN") {
              columnarNmae = "域名欺诈";
            } else if (columnarX[i] == "S_CHECK_ABNORMAL_CA") {
              columnarNmae = "HTTPS劫持";
            } else if (columnarX[i] == "S_CHECK_INJECT_STATUS") {
              columnarNmae = "注入攻击";
            } else if (columnarX[i] == "S_CHECK_DEBUG_STATUS") {
              columnarNmae = "调试行为";
            } else if (columnarX[i] == "S_CHECK_MEMORY_CHANGE") {
              columnarNmae = "内存篡改";
            } else if (columnarX[i] == "S_CHECK_PLUGIN") {
              columnarNmae = "程序外挂";
            } else if (columnarX[i] == "S_CHECK_SIGNATURE") {
              columnarNmae = "应用破解";
            } else if (columnarX[i] == "S_CHECK_WIFI_PROXY") {
              columnarNmae = "HTTP代理";
            } else if (columnarX[i] == "S_CHECK_FRAME_ATTACK") {
              columnarNmae = "框架攻击";
            } else if (columnarX[i] == "S_CHECK_FREQUENCY_ACCOUNT") {
              columnarNmae = "高频更换账号";
            } else if (columnarX[i] == "S_CHECK_FREQUENCY_IP") {
              columnarNmae = "高频更换IP";
            } else if (columnarX[i] == "S_CHECK_FREQUENCY_LOCATION") {
              columnarNmae = "高频更换位置";
            } else if (columnarX[i] == "S_CHECK_FREQUENCY_RESTART") {
              columnarNmae = "高频启动应用";
            } else if (columnarX[i] == "S_CHECK_DANGER_APPS") {
              columnarNmae = "风险应用";
            } else if (columnarX[i] == "S_FRAME_ATTACH") {
              columnarNmae = "框架软件";
            } else if (columnarX[i] == "S_ADB_ENABLED") {
              columnarNmae = "USB调试";
            } else if (columnarX[i] == "S_CHECK_VM_STATUS") {
              columnarNmae = "模拟器";
            } else if (columnarX[i] == "S_ALLOW_MOCK_LOCATION") {
              columnarNmae = "允许模拟位置";
            } else if (columnarX[i] == "S_CHECK_SYS_USER_CA") {
              columnarNmae = "根证书异常";
            } else if (columnarX[i] == "S_CHECK_CUSTOM_ROM") {
              columnarNmae = "定制ROM";
            } else if (columnarX[i] == "S_CHECK_ROOT_STATUS") {
              columnarNmae = "ROOT/越狱";
            } else if (columnarX[i] == "S_CHECK_MULTI_APK") {
              columnarNmae = "应用多开";
            } else if (columnarX[i] == "S_CHECK_SCREEN_PASSWORD") {
              columnarNmae = "锁屏密码未开启";
            } else if (columnarX[i] == "S_MACOS_STATUS") {
              columnarNmae = "macOS";
            } else if (columnarX[i] == "S_WINDOWS_STATUS") {
              columnarNmae = "Windows";
            } else if (columnarX[i] == "S_VPN_STATUS") {
              columnarNmae = "VPN";
            } else if (columnarX[i] == "S_CLOUD_PHONE_STATUS") {
              columnarNmae = "云手机";
            } else if (columnarX[i] == "S_CHECK_SCREENSHOT_STATUS") {
              columnarNmae = "截屏阻断解除";
            } else if (columnarX[i] == "S_CHECK_DANGER_IP") {
              columnarNmae = "风险IP";
            }
            //设备占比
            else if (columnarX[i] == "activeNum") {
              columnarNmae = "活跃设备";
            } else if (columnarX[i] == "restNum") {
              columnarNmae = "其他设备";
            } else if (columnarX[i] == "newJoinNum") {
              columnarNmae = "新接入设备";
            } else if (columnarX[i] == "activeUserNum") {
              columnarNmae = "活跃用户";
            } else if (columnarX[i] == "restUserNum") {
              columnarNmae = "其他用户";
            }
            // // 设备违规
            // else if (columnarX[i] == "0") {
            //   columnarNmae = "设备越狱";
            // } else if (columnarX[i] == "1") {
            //   columnarNmae = "系统版本";
            // }
            else {
              columnarNmae = columnarX[i];
            }
            //需要映射显示中文描述
            columnar.name = columnarNmae;
            columnar.value = columnarY[i];
            showData.push(columnar);
          }
        }

        //如果等于应用威胁top5 截取五个
        if (
          this.dimensionValue == "appInfo" &&
          this.statisticalValue1 == "appDangerTopConf"
        ) {
          showData = showData ? showData.splice(0, 5) : "";
        }
        //如果等于应用活跃top5 截取五个
        if (
          this.dimensionValue == "appInfo" &&
          this.statisticalValue1 == "appActiveTopConf"
        ) {
          showData = showData ? showData.splice(0, 5) : "";
        }

        //如果等于 敏感词 截取7个
        if (
          this.dimensionValue == "userInfo" &&
          this.statisticalValue1 == "sensitiveWordsConf"
        ) {
          showData = showData ? showData.splice(0, 7) : "";
        }
        //如果等于用户使用应用top5 截取五个
        if (
          this.dimensionValue == "userInfo" &&
          this.statisticalValue1 == "useAppTopConf"
        ) {
          showData = showData ? showData.splice(0, 5) : "";
        }
      } else {
        columnarX = [];
        columnarY = [];
        showData = [];
      }
      var myChart = echarts.init(this.$refs.showCharts);
      var option;

      option = {
        tooltip: {
          trigger: "item", // 重写legend显示样式
          formatter: (params) => {
            if (
              this.dimensionValue == "appInfo" &&
              this.statisticalValue1 == "appActiveTopConf"
            ) {
              if (this.active == "使用时长") {
                var time = this.appTimeStampTop(params.value);
                return params.name + "：" + time;
              } else {
                return params.name + "：" + params.value + "次";
              }
            } else {
              return params.name + " " + params.value;
            }
          }, // formatter: '{b} : {c} ({d}%)' //{a} <br/>{b} : {c} ({d}%)
        },
        color: ["#01B4D2", "#FFB508", "#3272FB", "#00BD8D", "#FF6A3C"], //关键加上这句话，legend的颜⾊和折线的⾃定义颜⾊就⼀致了
        label: {
          normal: {
            show: true,
          },
        },
        labelLine: {
          normal: {
            show: true,
          },
        },
        legend: {
          //圆形
          itemWidth: 12, //宽
          itemHeight: 12, //高
          icon: "circle",
          top: "30%",
          // left: 10,
          orient: "vertical", //标题纵向
          right: 15,
          // top: 60, //y轴向下偏移
          bottom: 20,
          textStyle: {
            color: "#ffffff", //legend  的颜色
            fontSize: 11, //文字的字体大小
          },
          // 重写legend显示样式
          formatter: (name) => {
            if (
              this.dimensionValue == "appInfo" &&
              this.statisticalValue1 == "appActiveTopConf"
            ) {
              let data = option.series[0].data;
              let total = 0;
              let tarValue = 0;
              for (let i = 0, l = data.length; i < l; i++) {
                total += data[i].value;
                if (data[i].name == name) {
                  tarValue = data[i].value;
                }
              }
              if (this.active == "使用时长") {
                // return this.timeStamp(tarValue)
                var time = this.appTimeStampTop(tarValue);
                return name.slice(0, 4) + "..." + " " + time;
              } else {
                return name.slice(0, 4) + "..." + " " + tarValue + "次";
              }
            } else {
              // 获取legend显示内容
              let data = option.series[0].data;
              let total = 0;
              let tarValue = 0;
              for (let i = 0, l = data.length; i < l; i++) {
                total += data[i].value;
                if (data[i].name == name) {
                  tarValue = data[i].value;
                }
              }
              // let p = ((tarValue / total) * 100).toFixed(2);
              // 文字超出4字隐藏
              if (!name) return "";
              //超出几个字符隐藏，鼠标悬浮时显示
              if (name.length > 4) {
                name = name.slice(0, 4) + "...";
              }
              var nameTu = name.replace(/\n/, " ");

              return nameTu + " " + tarValue;
            }
          },
        },
        series: [
          {
            // name: "Access From",
            type: "pie",
            radius: ["25%", "45%"], //图的宽外围宽和内围宽
            center: ["45%", "65%"], //饼状图位置
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "14",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            areaStyle: {
              normal: {
                // color: "#EAEAF0", //改变区域颜色
                position: "center",
                formatter: "{b}\n{c}", // {b}:数据名； {c}：数据值； {d}：百分比，可以自定义显示内容
              },
            },
            //视觉引导线
            labelLine: {
              show: true,
            },
            //饼图图形上的文本标签
            label: {
              show: true,
              formatter: "{d}% ", //{b}:{d}%
            },
            data: showData,
          },
        ],
        graphic: {
          type: "text", // 类型：文本
          left: "center",
          top: "middle",
          silent: true, // 不响应事件
          invisible: showData.length > 0, // 有数据就隐藏
          style: {
            fill: "#909399",
            fontWeight: "bold",
            text: this.$t("SecurityPosture.noData"),
            fontFamily: "Microsoft YaHei",
            fontSize: "24",
          },
        },
      };
      myChart.clear();
      option && myChart.setOption(option);
      let _this = this;
      let erd = elementResizeDetectorMaker();
      erd.listenTo(this.$refs.showCharts, () => {
        _this.$nextTick(() => {
          myChart.resize();
        });
      });
    },
    // 玫瑰图
    roses() {
      var columnarX = [];
      var columnarY = [];
      var showData = [];

      if (this.According === true) {
        if (this.eventFlag === "dangerType") {
          columnarX = this.metaEchartData.dangerType.name;
          columnarY = this.metaEchartData.dangerType.num;
        } else if (this.eventFlag === "mobileApps") {
          columnarX = this.metaEchartData.mobileApps.name;
          columnarY = this.metaEchartData.mobileApps.num;
        }
      }

      if (this.flag === true) {
        if (this.runTimeFlag === "useTime") {
          columnarX = this.metaEchartData.useTime.name;
          columnarY = this.metaEchartData.useTime.num;
        } else if (this.runTimeFlag === "runCount") {
          columnarX = this.metaEchartData.runCount.name;
          columnarY = this.metaEchartData.runCount.num;
        }
      }

      if (this.According === false && this.flag === false) {
        columnarX = this.metaEchartData.name;
        columnarY = this.metaEchartData.num;
      }

      for (var i = 0; i < columnarX.length; i++) {
        var columnar = {
          value: "",
          name: "",
        };
        var columnarNmae = "";

        if (columnarX[i] == "S_CHECK_LOCATION_FRAUD") {
          columnarNmae = "位置欺诈";
        } else if (columnarX[i] == "S_CHECK_DOMAIN") {
          columnarNmae = "域名欺诈";
        } else if (columnarX[i] == "S_CHECK_ABNORMAL_CA") {
          columnarNmae = "HTTPS劫持";
        } else if (columnarX[i] == "S_CHECK_INJECT_STATUS") {
          columnarNmae = "注入攻击";
        } else if (columnarX[i] == "S_CHECK_DEBUG_STATUS") {
          columnarNmae = "调试行为";
        } else if (columnarX[i] == "S_CHECK_MEMORY_CHANGE") {
          columnarNmae = "内存篡改";
        } else if (columnarX[i] == "S_CHECK_PLUGIN") {
          columnarNmae = "程序外挂";
        } else if (columnarX[i] == "S_CHECK_SIGNATURE") {
          columnarNmae = "应用破解";
        } else if (columnarX[i] == "S_CHECK_WIFI_PROXY") {
          columnarNmae = "HTTP代理";
        } else if (columnarX[i] == "S_CHECK_FRAME_ATTACK") {
          columnarNmae = "框架攻击";
        } else if (columnarX[i] == "S_CHECK_FREQUENCY_ACCOUNT") {
          columnarNmae = "高频更换账号";
        } else if (columnarX[i] == "S_CHECK_FREQUENCY_IP") {
          columnarNmae = "高频更换IP";
        } else if (columnarX[i] == "S_CHECK_FREQUENCY_LOCATION") {
          columnarNmae = "高频更换位置";
        } else if (columnarX[i] == "S_CHECK_FREQUENCY_RESTART") {
          columnarNmae = "高频启动应用";
        } else if (columnarX[i] == "S_CHECK_DANGER_APPS") {
          columnarNmae = "风险应用";
        } else if (columnarX[i] == "S_FRAME_ATTACH") {
          columnarNmae = "框架软件";
        } else if (columnarX[i] == "S_ADB_ENABLED") {
          columnarNmae = "USB调试";
        } else if (columnarX[i] == "S_CHECK_VM_STATUS") {
          columnarNmae = "模拟器";
        } else if (columnarX[i] == "S_ALLOW_MOCK_LOCATION") {
          columnarNmae = "允许模拟位置";
        } else if (columnarX[i] == "S_CHECK_SYS_USER_CA") {
          columnarNmae = "根证书异常";
        } else if (columnarX[i] == "S_CHECK_CUSTOM_ROM") {
          columnarNmae = "定制ROM";
        } else if (columnarX[i] == "S_CHECK_ROOT_STATUS") {
          columnarNmae = "ROOT/越狱";
        } else if (columnarX[i] == "S_CHECK_MULTI_APK") {
          columnarNmae = "应用多开";
        } else if (columnarX[i] == "S_CHECK_SCREEN_PASSWORD") {
          columnarNmae = "锁屏密码未开启";
        } else if (columnarX[i] == "S_MACOS_STATUS") {
          columnarNmae = "macOS";
        } else if (columnarX[i] == "S_WINDOWS_STATUS") {
          columnarNmae = "Windows";
        } else if (columnarX[i] == "S_VPN_STATUS") {
          columnarNmae = "VPN";
        } else if (columnarX[i] == "S_CLOUD_PHONE_STATUS") {
          columnarNmae = "云手机";
        } else if (columnarX[i] == "S_CHECK_SCREENSHOT_STATUS") {
          columnarNmae = "截屏阻断解除";
        } else if (columnarX[i] == "S_CHECK_DANGER_IP") {
          columnarNmae = "风险IP";
        }
        //设备占比
        else if (columnarX[i] == "activeNum") {
          columnarNmae = "活跃设备";
        } else if (columnarX[i] == "restNum") {
          columnarNmae = "其他设备";
        } else if (columnarX[i] == "newJoinNum") {
          columnarNmae = "新接入设备";
        } else if (columnarX[i] == "activeUserNum") {
          columnarNmae = "活跃用户";
        } else if (columnarX[i] == "restUserNum") {
          columnarNmae = "其他用户";
        } else {
          columnarNmae = columnarX[i];
        }
        //需要映射显示中文描述
        columnar.name = columnarNmae;
        columnar.value = columnarY[i];
        showData.push(columnar);
      }

      //如果等于应用威胁top5 截取五个
      if (
        this.dimensionValue == "appInfo" &&
        this.statisticalValue1 == "appDangerTopConf"
      ) {
        showData = showData ? showData.splice(0, 5) : "";
      }
      //如果等于应用活跃top5 截取五个
      if (
        this.dimensionValue == "appInfo" &&
        this.statisticalValue1 == "appActiveTopConf"
      ) {
        showData = showData ? showData.splice(0, 5) : "";
      }
      //如果等于用户使用应用top5 截取五个
      if (
        this.dimensionValue == "userInfo" &&
        this.statisticalValue1 == "useAppTopConf"
      ) {
        showData = showData ? showData.splice(0, 5) : "";
      }

      var myChart = echarts.init(this.$refs.showCharts);
      var option;

      option = {
        color: ["#0E6DE9", "#AC4ED3", "#E6AF08", "#1BCBB4", "#02545F"],
        label: {
          normal: {
            show: true,
          },
        },
        labelLine: {
          normal: {
            show: true,
          },
        },
        legend: {
          itemWidth: 12, //宽
          itemHeight: 12, //高
          // icon  =  circle（圆型）rect（矩形）roundRect（圆角矩形）triangle（三角形）diamond（菱形）pin（水滴）arrow（箭头）none（不显示图标）
          icon: "rect",
          top: "30%",
          // left: 10,
          orient: "vertical",
          right: 15,
          // top: 60, //y轴向下偏移
          bottom: 20,
          textStyle: {
            color: "#ffffff", //legend  的颜色
            fontSize: 11, //文字的字体大小
          },
          // 重写legend显示样式
          formatter: (name) => {
            if (
              this.dimensionValue == "appInfo" &&
              this.statisticalValue1 == "appActiveTopConf"
            ) {
              let data = option.series[0].data;
              let total = 0;
              let tarValue = 0;
              for (let i = 0, l = data.length; i < l; i++) {
                total += data[i].value;
                if (data[i].name == name) {
                  tarValue = data[i].value;
                }
              }
              if (this.active == "使用时长") {
                // return this.timeStamp(tarValue)
                var time = this.appTimeStampTop(tarValue);
                return name.slice(0, 4) + "..." + " " + time;
              } else {
                return name.slice(0, 4) + "..." + " " + tarValue + "次";
              }
            } else {
              // 获取legend显示内容
              let data = option.series[0].data;
              let total = 0;
              let tarValue = 0;
              for (let i = 0, l = data.length; i < l; i++) {
                total += data[i].value;
                if (data[i].name == name) {
                  tarValue = data[i].value;
                }
              }
              // let p = ((tarValue / total) * 100).toFixed(2);
              // 文字超出4字隐藏
              if (!name) return "";
              //超出几个字符隐藏，鼠标悬浮时显示
              if (name.length > 4) {
                name = name.slice(0, 4) + "...";
              }
              var nameTu = name.replace(/\n/, " ");

              return nameTu + " " + tarValue;
            }
          },
        },
        // tooltip: {
        //   trigger: 'item',
        //   formatter: '{b} : {c} ({d}%)'
        // },
        tooltip: {
          trigger: "item",
          // 重写legend显示样式
          formatter: (params) => {
            if (
              this.dimensionValue == "appInfo" &&
              this.statisticalValue1 == "appActiveTopConf"
            ) {
              if (this.active == "使用时长") {
                var time = this.appTimeStampTop(params.value);
                return params.name + "：" + time;
              } else {
                return params.name + "：" + params.value + "次";
              }
            } else {
              return params.name + " " + params.value;
            }
          },
          // formatter: '{b} : {c} ({d}%)' //{a} <br/>{b} : {c} ({d}%)
        },
        series: [
          {
            // name: "Radius Mode",
            type: "pie",
            radius: ["15", "45%"], //图的宽外围宽和内围宽
            center: ["45%", "65%"], //图的位置
            roseType: "radius",
            itemStyle: {
              borderRadius: 3,
            },
            label: {
              show: false,
            },
            emphasis: {
              label: {
                show: true,
              },
            },
            data: showData,
          },
        ],
        graphic: {
          type: "text", // 类型：文本
          left: "center",
          top: "middle",
          silent: true, // 不响应事件
          invisible: showData.length > 0, // 有数据就隐藏
          style: {
            fill: "#909399",
            fontWeight: "bold",
            text: this.$t("SecurityPosture.noData"),
            fontFamily: "Microsoft YaHei",
            fontSize: "24",
          },
        },
      };
      myChart.clear();
      option && myChart.setOption(option);
      let _this = this;
      let erd = elementResizeDetectorMaker();
      erd.listenTo(this.$refs.showCharts, () => {
        _this.$nextTick(() => {
          myChart.resize();
        });
      });
    },
    // 双柱状图
    twoTopCharts() {
      var buildOffline = this.dataProcessing(
        3,
        null,
        this.metaEchartData.offLine.name,
        this.metaEchartData.offLine.num
      );
      var buildOnLine = this.dataProcessing(
        3,
        null,
        this.metaEchartData.onLine.name,
        this.metaEchartData.onLine.num
      );

      var columnarY1 = buildOffline.get("finalNum");
      var columnarY2 = buildOnLine.get("finalNum");
      var columnarX = buildOnLine.get("finalDate");

      var myChart = echarts.init(this.$refs.showCharts);
      var option;

      option = {
        //  title: {
        //    text: 'Rainfall vs Evaporation',
        //     subtext: 'Fake Data'
        //   },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          //设置柱状图 legend的位置
          // orient: 'vertical',图例全部垂直向下
          top: "20%",
          // x:'right',      //可设定图例在左、右、居中
          // y:'center',     //可设定图例在上、下、居中
          // padding:[150,0,0,0],   //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          data: ["离线", "在线"],
          textStyle: {
            color: "#ffffff", //legend  的颜色
            fontSize: 11, //文字的字体大小
          },
        },
        grid: {
          //设置柱状图位置
          show: false,
          top: "35%", // 一下数值可为百分比也可为具体像素值
          // right:'5%',
          bottom: "12%",
          // left:'10%'
        },
        toolbox: {
          show: true,
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            // prettier-ignore
            data: columnarX,
            axisTick: {
              alignWithLabel: false,
              show: false,
            },
            axisLine: {
              //x轴线的颜色以及宽度
              show: true,
              lineStyle: {
                color: "#cccccc",
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              //x轴文字的配置
              show: true,
              textStyle: {
                color: "#cccccc",
              },
            },
            splitLine: {
              //分割线配置
              show: false,
              lineStyle: {
                color: "#fff",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            minInterval: 1,
            axisLabel: {
              //y轴文字的配置
              textStyle: {
                color: "#cccccc",
                margin: 15,
              },
              // formatter: '{value} %'//y轴的每一个刻度值后面加上‘%’号
            },
            axisTick: {
              //y轴刻度线
              show: false,
            },
            axisLine: {
              //y轴线的颜色以及宽度
              // show: false,
              lineStyle: {
                color: "#cccccc",
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              //分割线配置
              show: false,
              lineStyle: {
                color: "#fff",
              },
            },
            // 背景图虚线
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed", //shadow..dashed---
                color: "#16285D",
              },
            },
          },
        ],
        series: [
          {
            name: "离线",
            type: "bar",
            data: columnarY1,
            itemStyle: {
              //---图形形状
              color: "#3272FB",
              // barBorderRadius:[18,18,0,0],
            },
            // 隐藏显示箭头最大值和最小值
            // markPoint: {
            //   //显示最大值最小值在页面  画布上
            //   data: [
            //     //  { type: 'max', name: 'Max' },
            //     //  { type: 'min', name: 'Min' }
            //   ],
            // },
            // markLine: {
            //   data: [{ type: "average", name: "Avg" }],
            // },
          },
          {
            name: "在线",
            type: "bar",
            data: columnarY2,
            itemStyle: {
              //---图形形状
              color: "#01B4D2",
              // barBorderRadius:[18,18,0,0],
            },
            // 隐藏显示箭头最大值和最小值
            // markPoint: {
            //   // 显示最大值和最小值在页面  画布上
            //      data: [
            //         { name: 'Max', value: 182.2, xAxis: 7, yAxis: 183 },
            //       { name: 'Min', value: 2.3, xAxis: 11, yAxis: 3 }
            //      ]
            // },
            // markLine: {
            //   data: [{ type: "average", name: "Avg" }],
            // },
          },
        ],
      };
      myChart.clear();
      option && myChart.setOption(option);
      let _this = this;
      let erd = elementResizeDetectorMaker();
      erd.listenTo(this.$refs.showCharts, () => {
        _this.$nextTick(() => {
          myChart.resize();
        });
      });
    },
    // 词云图
    wordCloud() {
      var showData = [];
      var columnarX = [];
      var columnarY = [];

      if (this.metaEchartData) {
        columnarX = this.metaEchartData.name;
        columnarY = this.metaEchartData.num;
        for (var i = 0; i < columnarX.length; i++) {
          var columnar = {
            value: "",
            name: "",
          };
          //需要映射显示中文描述
          columnar.name = columnarX[i];
          columnar.value = columnarY[i];
          showData.push(columnar);
        }
        //如果等于 敏感词 截取7个
        if (
          this.dimensionValue == "userInfo" &&
          this.statisticalValue1 == "sensitiveWordsConf"
        ) {
          showData = showData ? showData.splice(0, 20) : "";
        }
      } else {
        showData = [];
        columnarX = [];
        columnarY = [];
      }
      // var columnarX = this.metaEchartData.name
      // var columnarY = this.metaEchartData.num

      // for (var i = 0; i < columnarX.length; i++) {
      //   var columnar = {
      //     value: '',
      //     name: ''
      //   }
      //   //需要映射显示中文描述
      //   columnar.name = columnarX[i]
      //   columnar.value = columnarY[i]
      //   showData.push(columnar)
      // }
      // //如果等于 敏感词 截取7个
      // if (
      //   this.dimensionValue == 'userInfo' &&
      //   this.statisticalValue1 == 'sensitiveWordsConf'
      // ) {
      //   showData = showData ? showData.splice(0, 20) : ''
      // }

      var myChart = echarts.init(this.$refs.showCharts);
      var option;
      // let myChart = echarts.init(document.querySelector("#cloudEl"));
      option = {
        series: [
          {
            type: "wordCloud",
            //用来调整词之间的距离
            gridSize: 10,
            //用来调整字的大小范围
            // Text size range which the value in data will be mapped to.
            // Default to have minimum 12px and maximum 60px size.
            sizeRange: [14, 28], //画布范围[14, 60]value 最小的渲染为 12px ，最大的渲染为 60px ，中间的值按比例计算相应的数值
            // Text rotation range and step in degree. Text will be rotated randomly in range [-90,                                                                             90] by rotationStep 45
            //用来调整词的旋转方向，，[0,0]--代表着没有角度，也就是词为水平方向，需要设置角度参考注释内容
            rotationRange: [-45, 0, 45, 90], // 词云中文字的角度这个值越小，词云里出现的角度种类就越多。以上面参数为例，可能旋转的角度就是 -90 -45 0 45 90 。
            drawOutOfBound: false, //是否允许词云在边界外渲染，直接使用默认参数 false 就可以，否则容易造成词重叠
            // rotationRange:[0,0],
            rotationStep: 45,
            //随机生成字体颜色
            // maskImage: maskImage,
            textStyle: {
              normal: {
                color: function () {
                  var colors = [
                    "#fda67e",
                    "#81cacc",
                    "#cca8ba",
                    "#88cc81",
                    "#82a0c5",
                    "#fddb7e",
                    "#735ba1",
                    "#bda29a",
                    "#6e7074",
                    "#546570",
                    "#c4ccd3",
                  ];
                  return colors[parseInt(Math.random() * 10)];
                },
                emphasis: {
                  shadowBlur: 10,
                  shadowColor: "333",
                },
              },
            },
            //位置相关设置
            // Folllowing left/top/width/height/right/bottom are used for positioning the word cloud
            // Default to be put in the center and has 75% x 80% size.
            left: "center",
            top: "20%", //center
            right: null,
            bottom: "10%", //center
            width: "70%",
            height: "80%",
            // height:"100%",
            gridSize: 8, //词云中每个词的间距。
            // textPadding:10,
            // autoSize:{
            //   enable:true,
            //   minSize:6
            // },
            //数据
            data: showData,
          },
        ],
        graphic: {
          type: "text", // 类型：文本
          left: "center",
          top: "middle",
          silent: true, // 不响应事件
          invisible: showData.length > 0, // 有数据就隐藏
          style: {
            fill: "#909399",
            fontWeight: "bold",
            text: this.$t("SecurityPosture.noData"),
            fontFamily: "Microsoft YaHei",
            fontSize: "24",
          },
        },
      };
      myChart.clear();
      option && myChart.setOption(option);
      let _this = this;
      let erd = elementResizeDetectorMaker();
      erd.listenTo(this.$refs.showCharts, () => {
        _this.$nextTick(() => {
          myChart.resize();
        });
      });
    },
    // 双折线图
    twoFigure() {
      var buildOffline = this.dataProcessing(
        3,
        null,
        this.metaEchartData.offLine.name,
        this.metaEchartData.offLine.num
      );
      var buildOnLine = this.dataProcessing(
        3,
        null,
        this.metaEchartData.onLine.name,
        this.metaEchartData.onLine.num
      );

      var columnarY1 = buildOffline.get("finalNum");
      var columnarY2 = buildOnLine.get("finalNum");
      var columnarX = buildOnLine.get("finalDate");
      var myChart = echarts.init(this.$refs.showCharts);
      var option;

      option = {
        tooltip: {
          trigger: "axis",
        },
        color: ["#1D8F84", "#3D8BC8"], //关键加上这句话，legend的颜⾊和折线的⾃定义颜⾊就⼀致了
        grid: {
          //设置折线图位置
          show: false,
          top: "35%", // 一下数值可为百分比也可为具体像素值
          // right:'5%',
          bottom: "12%",
          // left:'10%'
        },
        legend: {
          //设置折线图 legend的位置
          // orient: 'vertical',图例全部垂直向下
          top: "20%",
          // x:'right',      //可设定图例在左、右、居中
          // y:'center',     //可设定图例在上、下、居中
          // padding:[150,0,0,0],   //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          data: ["离线", "在线"],
          textStyle: {
            color: "#ffffff", //legend  的颜色
            fontSize: 11, //文字的字体大小
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: columnarX,
          axisTick: {
            alignWithLabel: false,
            show: false,
          },
          axisLine: {
            //x轴线的颜色以及宽度
            show: true,
            lineStyle: {
              color: "#cccccc",
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            //x轴文字的配置
            show: true,
            textStyle: {
              color: "#cccccc",
            },
          },
          splitLine: {
            //分割线配置
            show: false,
            lineStyle: {
              color: "#fff",
            },
          },
        },
        yAxis: {
          type: "value",
          minInterval: 1,
          axisTick: {
            //y轴刻度线
            show: false,
          },
          axisLabel: {
            //y轴文字的配置
            textStyle: {
              color: "#cccccc",
              margin: 15,
            },
            // formatter: '{value} %'//y轴的每一个刻度值后面加上‘%’号
          },
          axisLine: {
            //y轴线的颜色以及宽度
            // show: false,
            lineStyle: {
              color: "#cccccc",
              width: 1,
              type: "solid",
            },
          },
          splitLine: {
            //分割线配置
            show: false,
            lineStyle: {
              color: "#fff",
            },
          },
          // 背景图虚线
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed", //shadow..dashed---
              color: "#16285D",
            },
          },
        },
        series: [
          {
            name: "离线",
            type: "line",
            // stack: "Total",
            data: columnarY1,
            type: "line",
            areaStyle: {},
          },
          {
            name: "在线",
            type: "line",
            // stack: "Total",
            data: columnarY2,
            type: "line",
            areaStyle: {},
          },
        ],
      };
      myChart.clear();
      option && myChart.setOption(option);
      let _this = this;
      let erd = elementResizeDetectorMaker();
      erd.listenTo(this.$refs.showCharts, () => {
        _this.$nextTick(() => {
          myChart.resize();
        });
      });
    },
    // 条形图
    barChart() {
      var columnarX = [];
      var columnarY = [];
      var columnarChangeY = [];
      var columnarChangeX = [];
      if (this.metaEchartData) {
        //如果等于敏感词 截取7个
        if (
          this.dimensionValue == "userInfo" &&
          this.statisticalValue1 == "sensitiveWordsConf"
        ) {
          columnarY = this.metaEchartData.num
            ? this.metaEchartData.num.slice(0, 7)
            : "";
          columnarX = this.metaEchartData.name
            ? this.metaEchartData.name.slice(0, 7)
            : "";
          var newData = [];
          columnarX.forEach((item, index) => {
            newData.push(item.replace(/\n/, " "));
          });
          columnarChangeY = [...columnarY];
          columnarChangeX = [...newData];
        }
        //如果等于用户网络访问黑名单 截取五个
        else if (
          this.dimensionValue == "userInfo" &&
          this.statisticalValue1 == "noNetworkAccessConf"
        ) {
          columnarY = this.metaEchartData.num
            ? this.metaEchartData.num.slice(0, 5)
            : [];
          columnarX = this.metaEchartData.name
            ? this.metaEchartData.name.slice(0, 5)
            : [];
          columnarChangeY = [...columnarY];
          columnarChangeX = [...columnarX];
        } else {
          columnarY = this.metaEchartData.num ? this.metaEchartData.num : [];
          columnarX = this.metaEchartData.name ? this.metaEchartData.name : [];
          columnarChangeY = [...columnarY];
          columnarChangeX = [...columnarX];
        }
      } else {
        columnarY = [];
        columnarX = [];
        columnarChangeY = [];
        columnarChangeX = [];
      }

      var myChart = echarts.init(this.$refs.showCharts);
      var option;

      option = {
        //  title: {
        //    text: 'World Population'
        //  },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        //  legend: {},
        grid: {
          left: "8%",
          right: "12%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          show: columnarChangeY.length > 0,
          // boundaryGap: [0, 0.01],

          axisLine: {
            //x轴线的颜色以及宽度
            show: true,
            lineStyle: {
              color: "#cccccc",
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            //x轴文字的配置
            show: true,
            textStyle: {
              color: "#cccccc",
            },
          },
          splitLine: {
            //分割线配置
            show: false,
            lineStyle: {
              color: "#fff",
            },
          },
        },
        yAxis: {
          type: "category",
          show: columnarChangeX.length > 0,
          data: columnarChangeX,
          axisLabel: {
            //y轴文字的配置
            // textStyle: {
            //   color: "#747C99",
            //   margin: 15,
            // },
            // formatter: '{value} %'//y轴的每一个刻度值后面加上‘%’号
            show: false, // 不显示坐标轴上的文字
          },
          axisTick: {
            //y轴刻度线
            show: false,
          },
          axisLine: {
            //y轴线的颜色以及宽度
            // show: false,
            // lineStyle: {
            //   color: "#16285D",
            //   width: 1,
            //   type: "solid",
            // },
            show: false, // 不显示坐标轴线
          },
          splitLine: {
            //分割线配置
            show: false,
            lineStyle: {
              color: "#fff",
            },
          },
          // 背景图虚线
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed", //shadow..dashed---
              color: "#16285D",
            },
          },
        },
        series: [
          {
            type: "bar",
            barWidth: 15,
            data: columnarChangeY,
            label: {
              show: true,
              position: "inside",
              formatter: "{b}",
            },
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              //---图形形状
              color: "#00c4f5",
              // barBorderRadius:[18,18,0,0],
            },
          },
        ],
        graphic: {
          type: "text", // 类型：文本
          left: "center",
          top: "middle",
          silent: true, // 不响应事件
          invisible: columnarChangeY.length > 0, // 有数据就隐藏
          style: {
            fill: "#909399",
            fontWeight: "bold",
            text: this.$t("SecurityPosture.noData"),
            fontFamily: "Microsoft YaHei",
            fontSize: "24",
          },
        },
      };
      myChart.clear();
      option && myChart.setOption(option);
      let _this = this;
      let erd = elementResizeDetectorMaker();
      erd.listenTo(this.$refs.showCharts, () => {
        _this.$nextTick(() => {
          myChart.resize();
        });
      });
    },
    // 弹框点击关闭事件
    handleClose() {
      this.statusVisible = false; // 卡片设置弹框关闭
    },
    //维度选择
    weiDu(val) {
      this.firstSelect = val;
      if (val === "appInfo") {
        this.statistical = this.appInfo_statistical;
        this.statisticalValue1 = this.statistical[0].value;
        this.secondSelect = this.statisticalValue1;
        //获取第三级联动加载
        this.returnThreeData(val, this.statisticalValue1);
      } else if (val === "deviceInfo") {
        this.statistical = this.deviceInfo_statistical;
        this.statisticalValue1 = this.statistical[0].value;
        this.secondSelect = this.statisticalValue1;
        //获取第三级联动加载
        this.returnThreeData(val, this.statisticalValue1);
      } else if (val === "userInfo") {
        this.statistical = this.userInfo_statistical;
        this.statisticalValue1 = this.statistical[0].value;
        this.secondSelect = this.statisticalValue1;
        //获取第三级联动加载
        this.returnThreeData(val, this.statisticalValue1);
      } else if (val === "gatewayInfo") {
        this.statistical = this.gatewayInfo_statistical;
        this.statisticalValue1 = this.statistical[0].value;
        this.secondSelect = this.statisticalValue1;
        //获取第三级联动加载
        this.returnThreeData(val, this.statisticalValue1);
      }
    },
    //第二级下拉选
    content(val) {
      this.secondSelect = val;
      if (this.firstSelect == "") {
        this.firstSelect = this.rightCharts_2.showConf;
      }
      this.dimensionValue = this.firstSelect;
      //具体维度下显示内容
      var metaData = this.rightCharts_2[this.firstSelect][val];
      this.returnThreeData(this.firstSelect, val);
    },
    //三级图标选择下拉选
    tuBiao(val) {
      this.threeSelect = val;
    },
    //返回三级联动内容
    returnThreeData(firstSelectData, secondSelectData) {
      //具体维度下显示内容
      var metaData = this.rightCharts_2[firstSelectData][secondSelectData];

      //接纳图表下拉选内容
      var chartList = [];
      //默认图形配置
      var defaultChart = "";

      for (const key in metaData) {
        var listMetaData = {
          value: "",
          label: "",
        };
        listMetaData.value = key;
        listMetaData.label = this.conversion(key);
        chartList.push(listMetaData);

        //根据实际情况显示默认图形
        if (key == "topChart") {
          defaultChart = key;
        }
      }

      if (defaultChart == "") {
        defaultChart = chartList[0].value;
      }

      this.chartsTu = chartList;
      this.threeSelect = defaultChart;
      this.chartsValue = defaultChart;
    },
    //确定的关闭
    determine() {
      //当用户不做任何操作进行保存时数据来源
      if (this.firstSelect == "") {
        this.firstSelect = this.dimensionValue;
      }
      if (this.secondSelect == "") {
        this.secondSelect = this.statisticalValue1;
      }
      if (this.threeSelect == "") {
        this.threeSelect = this.chartsValue;
      }
      this.rightCharts_2.showConf = this.firstSelect;
      var leftCharts = JSON.stringify(this.rightCharts_2);
      var data = leftCharts.replace("true", "false");
      this.rightCharts_2 = JSON.parse(data);
      this.rightCharts_2[this.firstSelect].contentConf = this.secondSelect;
      this.rightCharts_2[this.firstSelect][this.secondSelect][
        this.threeSelect
      ] = true;
      var titleName = "";

      for (var i = 0; i < this.statistical.length; i++) {
        if (this.statistical[i].value === this.secondSelect) {
          titleName = this.statistical[i].label;
          break;
        }
      }
      this.According = false;
      this.flag = false;
      this.appInstallFlag = false;
      this.sysVersionFlag = false;
      this.selectFlag = false;
      this.businessFlag = false;
      this.businesSelect = false;
      this.gatewaySelect = false;
      if (
        this.firstSelect === "appInfo" &&
        this.secondSelect === "appDangerTopConf"
      ) {
        this.According = true; ////切换默认不展示（移动应用）
      } else if (
        this.firstSelect === "appInfo" &&
        this.statisticalValue1 === "appActiveTopConf"
      ) {
        this.flag = true; //切换默认不展示（使用时长）
      } else if (
        this.firstSelect === "appInfo" &&
        this.statisticalValue1 === "appInstallTopConf"
      ) {
        this.appInstallFlag = true; //切换默认不展示（安装top默认ios）
        this.selectFlag = true;
      } else if (
        this.firstSelect === "deviceInfo" &&
        this.statisticalValue1 === "systemVersionConf"
      ) {
        this.sysVersionFlag = true; //切换默认不展示（系统版本ios）
      } else if (
        this.firstSelect === "gatewayInfo" &&
        this.statisticalValue1 === "accessResultConf"
      ) {
        this.businessFlag = true;
        this.businesSelect = true;
      } else if (
        this.firstSelect === "gatewayInfo" &&
        this.statisticalValue1 === "gatewayThroughputConf"
      ) {
        this.gatewaySelect = true;
      }

      //获取数据
      var showGroupData = this.echartData[this.firstSelect];

      this.metaEchartData =
        showGroupData[
          this.rightCharts_2[this.firstSelect].contentConf.split("Conf")[0]
        ];
      if (this.statisticalValue1 === "accessResultConf" && this.businessFlag) {
        this.valueBusiness =
          this.metaEchartData.name[0] == "all"
            ? "全部业务"
            : this.valueBusiness;
      }
      //刷图  rings()
      // this.showEchartsByData(this.threeSelect, '')
      // console.log(this.value, 'this.value')
      this.showEchartsByData(this.threeSelect, top);
      this.rightCharts_2.titleName = titleName;

      var returnData = {
        saveData: this.rightCharts_2,
      };
      this.$emit("rightCharts2ReturnData", returnData);
      this.statusVisible = false; //卡片设置弹框关闭
    },
    // 设置卡片弹框
    setUp() {
      this.statusVisible = true;
    },

    // 补全数据的    ，      ，  补全时间，  补0
    dataProcessing(timeType, DateParam, gainDate, gainNum) {
      //起始时间
      var startDay;
      //终止时间
      var endDay;
      //补全数据
      var newData;
      //补全时间
      var allDate;
      //返回数据
      var buildData = new Map();

      if (0 === timeType) {
        allDate = [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
        ];
        newData = [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0,
        ];
      } else if (1 === timeType) {
        //近7天
        let dayBy7 = 7 - 1;
        startDay = moment().subtract(dayBy7, "days").format("YYYY-MM-DD");
        endDay = moment().subtract(0, "days").format("YYYY-MM-DD");
        newData = [0, 0, 0, 0, 0, 0, 0];
        //补全全部日期
        allDate = this.getDiffDate(startDay, endDay);
      } else if (2 === timeType) {
        //近30天
        let dayBy7 = 30 - 1;
        startDay = moment().subtract(dayBy7, "days").format("YYYY-MM-DD");
        endDay = moment().subtract(0, "days").format("YYYY-MM-DD");
        newData = [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0,
        ];
        //补全全部日期
        allDate = this.getDiffDate(startDay, endDay);
      } else if (3 === timeType) {
        //近90天
        let dayBy7 = 90 - 1;
        startDay = moment().subtract(dayBy7, "days").format("YYYY-MM-DD");
        endDay = moment().subtract(0, "days").format("YYYY-MM-DD");
        newData = [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ];
        //补全全部日期
        allDate = this.getDiffDate(startDay, endDay);
      } else {
        buildData.set("finalDate", null);
        buildData.set("finalNum", null);
        return buildData;
      }

      //通过返回日期和补全日期差集给顶数量为0
      for (var i = 0; i < allDate.length; i++) {
        for (var j = 0; j < gainDate.length; j++) {
          //获取日期等于定义日期
          if (allDate[i] === gainDate[j]) {
            newData[i] = gainNum[j];
          }
        }
      }
      buildData.set("finalDate", allDate);
      buildData.set("finalNum", newData);

      return buildData;
    },
    //获取两日期之间日期列表函数
    getDiffDate(stime, etime) {
      //初始化日期列表，数组
      var diffdate = new Array();
      var i = 0;
      //开始日期小于等于结束日期,并循环
      while (stime <= etime) {
        diffdate[i] = stime;

        //获取开始日期时间戳
        var stime_ts = new Date(stime).getTime();

        //增加一天时间戳后的日期
        var next_date = stime_ts + 24 * 60 * 60 * 1000;

        //拼接年月日，这里的月份会返回（0-11），所以要+1
        var next_dates_y = new Date(next_date).getFullYear() + "-";
        var next_dates_m =
          new Date(next_date).getMonth() + 1 < 10
            ? "0" + (new Date(next_date).getMonth() + 1) + "-"
            : new Date(next_date).getMonth() + 1 + "-";
        var next_dates_d =
          new Date(next_date).getDate() < 10
            ? "0" + new Date(next_date).getDate()
            : new Date(next_date).getDate();

        stime = next_dates_y + next_dates_m + next_dates_d;

        //增加数组key
        i++;
      }

      return diffdate;
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1920px) and (max-width: 2040px) {
  // 切换定位--使用时长、启动次数
  .location {
    position: absolute;
    left: 33%;
    top: 20%;
    cursor: pointer;
    z-index: 2;
    color: aliceblue;
    display: flex;
  }

  // 切换定位--移动应用、威胁类型
  .location2 {
    position: absolute;
    left: 33%;
    top: 20%;
    cursor: pointer;
    z-index: 2;
    color: aliceblue;
    display: flex;
  }
}

@media screen and (min-width: 1680px) and (max-width: 1919px) {
  // 切换定位--使用时长、启动次数
  .location {
    position: absolute;
    left: 30%;
    top: 20%;
    cursor: pointer;
    z-index: 2;
    color: aliceblue;
    display: flex;
  }

  // 切换定位--移动应用、威胁类型
  .location2 {
    position: absolute;
    left: 30%;
    top: 20%;
    cursor: pointer;
    z-index: 2;
    color: aliceblue;
    display: flex;
  }
}

@media screen and (min-width: 2048px) and (max-width: 2560px) {
  // 切换定位--使用时长、启动次数
  .location {
    position: absolute;
    left: 36%;
    top: 17%;
    cursor: pointer;
    z-index: 2;
    color: aliceblue;
    display: flex;
  }

  // 切换定位--移动应用、威胁类型
  .location2 {
    position: absolute;
    left: 36%;
    top: 17%;
    cursor: pointer;
    z-index: 2;
    color: aliceblue;
    display: flex;
  }
}

// 齿轮设置
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

// 样式哦切换
.active {
  // background: #fd7522;
  border-bottom: 3px solid #048aff !important;
  color: skyblue;
}

.theDefault {
  // background: #fd7522;
  border-bottom: 3px solid #048aff !important;
  color: skyblue;
}

#cloudEl {
  float: left;
  padding-top: 20px;
  width: 100%;
  // height: 300px;
  // margin-top: 10px;
  // border: 1px solid #E4E4E4;
  // background: #ffffff;
  // border-radius: 6px;
}

::v-deep.appInstallBox .el-input__inner {
  -webkit-appearance: none;
  border-radius: 0px;
  border: 1px solid #1735a4;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: 12px;
  height: 35px;
  line-height: 35px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  background-color: #04134b !important;
  color: #dedfe9;
}

// 设置下拉框的背景颜色及边框属性；
/deep/ .appInstallBox .el-select-dropdown {
  width: 100px !important;
  // 若不将下拉框的背景颜色设置为：transparent，那么做不出来半透明的效果；
  // 因为其最终的显示为：下拉框有一个背景颜色且下拉框的字体有一个背景颜色，重叠后的效果展示；
  background-color: #04134b !important;
  border: 1px solid #1735a4 !important;
  border-top: none !important;
  border-radius: 0px !important;
  position: absolute !important;
  top: 23px !important;
  left: 0px !important;
  color: #297ec4 !important;
}

// 将下拉框上的小箭头取消；(看着像一个箭头，其实是两个重叠在一起的箭头)
/deep/.appInstallBox .el-popper .popper__arrow,
/deep/.el-popper .popper__arrow::after {
  display: none !important;
}

// 设置鼠标悬停在下拉框列表的悬停色；
/deep/.appInstallBox .el-select-dropdown__item:hover {
  background-color: #0e257b !important;
}

/deep/.appInstallBox .el-select-dropdown__item.hover {
  background-color: #0e257b !important;
}

/deep/.appInstallBox .el-input--suffix {
  background-color: #0e257b !important;
}

//下拉框选中
/deep/.appInstallBox .el-select-dropdown__item.selected {
  color: white;
  font-weight: none;
}
</style>
