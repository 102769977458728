<template>
  <div style="width: 100%; height: 100%">
    <div class="rduis" style="width: 100%; height: 100%; position: relative">
      <svg
        class="icon"
        aria-hidden="true"
        @click="setUp"
        style="
          z-index: 9;
          position: absolute;
          left: 90%;
          top: 5%;
          cursor: pointer;
          z-index: 2;
        "
      >
        <use xlink:href="#icon-shezhi-"></use>
      </svg>
      <div id="ring1" ref="ring1" style="width: 100%; height: 100%"></div>
    </div>
    <!--卡片配置弹框 -->
    <Eldialog
      :title="$t('SecurityPosture.bounced.title')"
      :width="'460px'"
      :btnTitle="$t('SecurityPosture.bounced.btnTitle')"
      :flagbtn="true"
      :cancelBtn="true"
      :dialogVisible="statusVisible"
      @handleClose="handleClose"
      :determineBtn="true"
      :num="'170px'"
      @determine="determine"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        style="margin-top: 30px"
      >
        <el-form-item :label="$t('SecurityPosture.bounced.StatisticalDimension')">
          <el-select
            v-model="dimensionValue"
            @change="weiDu(dimensionValue)"
            :placeholder="$t('SecurityPosture.bounced.placeholder')"
          >
            <el-option
              v-for="item in dimension"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('SecurityPosture.bounced.StatisticalContent')">
          <el-select
            v-model="statisticalValue"
            @change="content(statisticalValue)"
            :placeholder="$t('SecurityPosture.bounced.placeholder')"
          >
            <el-option
              v-for="item in statistical"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </Eldialog>
  </div>
</template>

 <script>
import * as echarts from "echarts"; //echarts图表引用
import "../../../assets/iconfont/iconfont.js"; //小齿轮设置的icon
import elementResizeDetectorMaker from "element-resize-detector"; //获取div宽高
import Eldialog from "@/components/elDialog"; //详情弹框
export default {
  props: {
    pageContent: {
      type: Object,
      default: () => {},
    },
    cardDataSum: {
      type: Object,
      default: () => {},
    },
  },
  // 组件
  components: {
    Eldialog,
  },
  data() {
    return {
      //第一维度选择
      firstSelect: "",
      //第二维度选择
      secondSelect: "",
      card_4: {}, //--查询--卡片数据（父组件接收到的  回显数据）
      card_Data: {}, //卡片 --接口数据（父组件接收到的  接口数据）
      // ------------------------------------------------------
      statusVisible: false, //默认设置卡片不展示
      oneCartValue: 11, //圆环卡片 value（测试数据）
      oneCartName: "", //圆环卡片 name（测试数据）
      form: {}, //form 表单
      dimension: [
        {
          //统计维度
          value: "appInfo",
          label: this.$t('SecurityPosture.ConfigCardLeftOne.dimension.appInfo'),
        },
        {
          value: "deviceInfo",
          label:  this.$t('SecurityPosture.ConfigCardLeftOne.dimension.deviceInfo'),
        },
        {
          value: "userInfo",
          label:  this.$t('SecurityPosture.ConfigCardLeftOne.dimension.userInfo'),
        },
        {
          value: "gatewayInfo",
          label:  this.$t('SecurityPosture.ConfigCardLeftOne.dimension.gatewayInfo'),
        }
      ],
      dimensionValue: "", //下拉框当前展示的维度
      statistical: [], //内容--列表
      statisticalValue: "", //下拉框当前展示的内容
      //应用维度下拉选
      appInfo_statistical: [
        {
          //统计内容
          value: "appRunCountConf",
          label: this.$t('SecurityPosture.ConfigCardLeftOne.appInfo_statistical.appRunCountConf'),
        },
        {
          value: "appDangerCountConf",
          label: this.$t('SecurityPosture.ConfigCardLeftOne.appInfo_statistical.appDangerCountConf'),
        },
        {
          value: "appEventCountConf",
          label: this.$t('SecurityPosture.ConfigCardLeftOne.appInfo_statistical.appEventCountConf'),
        },
        {
          value: "sdkBehaviorCountConf",
          label: this.$t('SecurityPosture.ConfigCardLeftOne.appInfo_statistical.sdkBehaviorCountConf'),
        },
      ],
      //设备维度下拉选
      deviceInfo_statistical: [
        {
          value: "deviceSumCountConf",
          label: this.$t('SecurityPosture.ConfigCardLeftOne.deviceInfo_statistical.deviceSumCountConf'),
        },
        {
          value: "offLineCountConf",
          label: this.$t('SecurityPosture.ConfigCardLeftOne.deviceInfo_statistical.offLineCountConf'),
        },
        {
          value: "onLineCountConf",
          label: this.$t('SecurityPosture.ConfigCardLeftOne.deviceInfo_statistical.onLineCountConf'),
        },
        {
          value: "violationsSumCountConf",
          label: this.$t('SecurityPosture.ConfigCardLeftOne.deviceInfo_statistical.violationsSumCountConf'),
        },
        {
          value: "lostCountConf",
          label: this.$t('SecurityPosture.ConfigCardLeftOne.deviceInfo_statistical.lostCountConf'),
        },
        {
          value: "scrapCountConf",
          label: this.$t('SecurityPosture.ConfigCardLeftOne.deviceInfo_statistical.scrapCountConf'),
        },
        {
          value: "onLineRateConf",
          label: this.$t('SecurityPosture.ConfigCardLeftOne.deviceInfo_statistical.onLineRateConf'),
        },
      ],
      //用户维度下拉选
      userInfo_statistical: [
        {
          value: "addUserCountConf",
          label: this.$t('SecurityPosture.ConfigCardLeftOne.userInfo_statistical.addUserCountConf'),
        },
        {
          value: "normalUserCountConf",
          label: this.$t('SecurityPosture.ConfigCardLeftOne.userInfo_statistical.normalUserCountConf'),
        },
        {
          value: "stopUsingUserCountConf",
          label: this.$t('SecurityPosture.ConfigCardLeftOne.userInfo_statistical.stopUsingUserCountConf'),
        },
        {
          value: "lockUserCountConf",
          label: this.$t('SecurityPosture.ConfigCardLeftOne.userInfo_statistical.lockUserCountConf'),
        },
        {
          value: "userSumCountConf",
          label: this.$t('SecurityPosture.ConfigCardLeftOne.userInfo_statistical.userSumCountConf'),
        },
      ],
       //网关维度下拉选
       gatewayInfo_statistical: [
        {
          value: "deviceConnectCountConf",
          label: this.$t('SecurityPosture.ConfigCardLeftOne.gatewayInfo_statistical.deviceConnectCountConf'),
        },
        {
          value: "accessAppCountConf",
          label: this.$t('SecurityPosture.ConfigCardLeftOne.gatewayInfo_statistical.accessAppCountConf'),
        },
        {
          value: "userConnectCountConf",
          label: this.$t('SecurityPosture.ConfigCardLeftOne.gatewayInfo_statistical.userConnectCountConf'),
        }
        
      ],
      value1: "",
    };
  },
   watch: {
    pageContent: {
      handler: function (newVal, oldVal) { 
        // if (newVal) {
        this.pageContent = newVal;
        this.card_4 = this.pageContent;
        // 维度
        var showConfStr = this.card_4.showConf;
        //具体维度下显示内容
        var metaData = this.card_4[showConfStr];
        this.dimensionValue = showConfStr;

        for (const key in metaData) {
          if (metaData[key] == true) {
            //下拉选选中value。用来显示lable
            this.statisticalValue = key;
            if (showConfStr === "appInfo") {
              this.statistical = this.appInfo_statistical;
            }
            if (showConfStr === "deviceInfo") {
              this.statistical = this.deviceInfo_statistical;
            }
            if (showConfStr === "userInfo") {
              this.statistical = this.userInfo_statistical;
            }
            if (showConfStr === "gatewayInfo") {
              this.statistical = this.gatewayInfo_statistical;
            }
            // 循环当前父组件接收的数据  匹配相对应的中文（拿数据）
            this.statistical.forEach((item, index) => {
              if (this.statisticalValue === item.value) {
                this.oneCartName = item.label; 
              }
            });
            break;
          }
        }
        //获取数据
    var metaData = this.cardDataSum[showConfStr];

    this.oneCartValue = metaData[this.statisticalValue.split("Conf")[0]];
        this.rings1();
      },
      deep: true,
      // immediate: true,
    },
  },
  created() {
    this.card_4 = this.pageContent;
    // 维度
    var showConfStr = this.card_4.showConf;
    //具体维度下显示内容
    var metaData = this.card_4[showConfStr];
    this.dimensionValue = showConfStr;
    for (const key in metaData) {
      if (metaData[key] == true) {
        //下拉选选中value。用来显示lable
        this.statisticalValue = key;
        if (showConfStr === "appInfo") {
          this.statistical = this.appInfo_statistical;
        }
        if (showConfStr === "deviceInfo") {
          this.statistical = this.deviceInfo_statistical;
        }
        if (showConfStr === "userInfo") {
          this.statistical = this.userInfo_statistical;
        }
        if (showConfStr === "gatewayInfo") {
          this.statistical = this.gatewayInfo_statistical;
        }
        // 循环当前父组件接收的数据  匹配相对应的中文（拿数据）
        this.statistical.forEach((item, index) => {
          if (this.statisticalValue === item.value) {
            this.oneCartName = item.label;
          }
        });
        break;
      }
    }
    //获取数据
    var metaData = this.cardDataSum[showConfStr];
    this.oneCartValue = metaData[this.statisticalValue.split("Conf")[0]];
  },
  mounted() {
    this.rings1();
  },
  methods: {
    rings1() {
      var chartDom = document.getElementById("ring1");
      var myChart = echarts.init(this.$refs.ring1);
      var option;

      option = {
        tooltip: {
          trigger: "item",
        },
        //设置中间内容距离div的距离，也就是内边距
        // grid: { // 让图表占满容器
        //   top: "0px",
        //   left: "0px",
        //   right: "0px",
        //   bottom: "0px"
        // }
        color: ["#006cb3"],
        series: [
          {
            // name: "Access From",
            type: "pie",
            radius: ["70%", "80%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "center",
              normal: {
                show: false,
                formatter: (params) => {
                  if (params.data.name == "设备在线率") {
                    return params.value + "%" + "\n" + params.name;
                  } else {
                    return params.value + "\n" + params.name;
                  }
                },
                position: "center",
                lineHeight: 25,
                color: "#FFFFFF",//圆圈内容的显示颜色
                fontSize: 17,
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "17",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: this.oneCartValue.length>9?this.oneCartValue.slice(0,9):this.oneCartValue,
                name: this.oneCartName,
                label: {
                  normal: {
                    show: true,
                  },
                },
              },
            ],
          },
        ],
      };
      option && myChart.setOption(option);
      // window.addEventListener("resize",function({
      //   myChart.resize()
      // }))
      //  随外层div的大小变化自适应
      let _this = this;
      let erd = elementResizeDetectorMaker();
      erd.listenTo(this.$refs.ring1, () => {
        _this.$nextTick(() => {
          myChart.resize();
        });
      });

      //全局设置Echrts - 根据窗口的大小变动图表
      // 单个图可用  多个  只对最后一个有效
      // window.onresize = function () {
      //   myChart.resize();
      // }
    },

    // 设置卡片弹框
    setUp() {
      this.firstSelect = this.dimensionValue;
      this.secondSelect = this.statisticalValue;
      this.statusVisible = true;
    },

    //一级下拉选维度选择
    weiDu(val) {
      this.firstSelect = val;
      if (val === "appInfo") {
        this.statistical = this.appInfo_statistical;
        this.statisticalValue = this.statistical[0].value;
        this.secondSelect = this.statistical[0].value;
      } else if (val === "deviceInfo") {
        this.statistical = this.deviceInfo_statistical;
        this.statisticalValue = this.statistical[0].value;
        this.secondSelect = this.statistical[0].value;
      } else if (val === "userInfo") {
        this.statistical = this.userInfo_statistical;
        this.statisticalValue = this.statistical[0].value;
        this.secondSelect = this.statistical[0].value;
      } else if (val === "gatewayInfo") {
        this.statistical = this.gatewayInfo_statistical;
        this.statisticalValue = this.statistical[0].value;
        this.secondSelect = this.statistical[0].value;
      }
    },

    //二级下拉选统计内容
    content(val) {
      this.secondSelect = val;
    },

    // 弹框点击关闭事件
    handleClose() {
      this.statusVisible = false; // 卡片设置弹框关闭
    },
    //确定的关闭
    determine() {
      this.card_4.showConf = this.firstSelect;
      var card1Str = JSON.stringify(this.card_4);
      var data = card1Str.replace("true", "false");
      this.card_4 = JSON.parse(data);
      this.card_4[this.firstSelect][this.secondSelect] = true;
      for (var i = 0; i < this.statistical.length; i++) {
        if (this.statistical[i].value === this.secondSelect) {
          this.oneCartName = this.statistical[i].label;
          break;
        }
      }
      //获取数据
      var metaData = this.cardDataSum[this.firstSelect];
      this.oneCartValue = metaData[this.secondSelect.split("Conf")[0]];
      this.rings1();
      var returnData = {
        titleName: this.oneCartName,
        saveData: this.card_4,
      };
      this.$emit("card4ReturnData", returnData);
      this.statusVisible = false; //卡片设置弹框关闭
    },
  },
};
</script>

<style lang="scss" scoped>
// 齿轮设置
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>  